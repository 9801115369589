<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de Sucursales
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar sucursal" aria-label="Recipient's username" aria-describedby="button-addon2">
              </div>
            </div>
            <div class="col-9 text-end">
              <button @click="openModal" class="btn btn-dark align-content-end" type="submit">Nueva Sucursal</button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Nombre</th>
                <th>Dirección</th>
                <th>Ciudad</th>
                <th>Estado</th>
                <th>Teléfono</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="sucursal in sucursalPerName" :key="sucursal.id">
                <td class="text-sm font-weight-normal" :class="sucursal.isActive?'':'text-danger'">
                  <div class="ms-3">{{ sucursal.name }}</div>
                </td>
                <td class="text-sm font-weight-normal" :class="sucursal.isActive?'':'text-danger'">
                  <div class="ms-3">{{ sucursal.address }}</div>
                </td>
                <td class="text-sm font-weight-normal" :class="sucursal.isActive?'':'text-danger'">
                  <div class="ms-3">{{ sucursal.ciudad ? sucursal.ciudad.name : "" }}</div>
                </td>
                <td class="text-sm font-weight-normal" :class="sucursal.isActive?'':'text-danger'">
                  <div class="ms-3">{{ sucursal.ciudad ? sucursal.ciudad.estado.name : "" }}</div>
                </td>
                <td class="text-sm font-weight-normal" :class="sucursal.isActive?'':'text-danger'">
                  <div class="ms-3">{{ sucursal.phone }}</div>
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button v-if="sucursal.isActive" @click="DesactiveSucursal(sucursal.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Desactivar Sucursal" data-container="body"
                            data-animation="true" class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">block</i></button>
                    <button v-else @click="ActiveSucursal(sucursal.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Activar Sucursal" data-container="body" data-animation="true"
                            class="btn btn-warning p-1 ms-1">
                      <i class="material-icons opacity-10">check_circle</i></button>
                    <button @click="openUpdate(sucursal)"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Editar Sucursal" data-container="body" data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                    <button @click="openSucursal(sucursal.name, sucursal.address, sucursal.phone, sucursal.contactPhone,
                              sucursal.postalCod, sucursal.openHour,sucursal.closeHour, sucursal.ciudad?sucursal.ciudad.id:0)"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Detalles de la Sucursal" data-container="body" data-animation="true"
                            class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">visibility</i></button>
                    <button @click="DeleteSucursal(sucursal.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Eliminar Sucursal" data-container="body" data-animation="true"
                            class="btn btn-danger p-1 ms-1">
                      <i class="material-icons opacity-10">delete</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nueva Sucursal<i @click="closeModal"
                                                                                        class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="form-label">Nombre</label>
            <input v-model="name" class="form-control border-bottom border-dark p-2 mb-3" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6 col-sm-12">
            <label class="form-label">Estado</label>
            <select class="form-select mb-3 p-2" v-model="estado">
              <option v-for="item in allEstados" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
            <label class="form-label">Dirección</label>
            <vue-google-autocomplete
              ref="address"
              id="map"
              classname="form-control border-bottom border-dark p-2 mb-3"
              placeholder="Escriba la dirección"
              v-on:placechanged="getAddressData"
              country="mx"
              types="address"
            ></vue-google-autocomplete>
            <label class="form-label">Teléfono</label>
            <input v-model="phone" class="form-control border-bottom border-dark p-2 mb-3" type="text">
            <label class="form-label">Hora de Apertura</label>
            <input v-model="openHour" class="form-control border-bottom border-dark p-2" type="time">
          </div>
          <div class="col-lg-6 col-sm-12">
            <label class="form-label">Ciudad</label>
            <select class="form-select mb-3 p-2" v-model="ciudad" v-bind:disabled="estado === 0">
              <option v-for="item in ciudadPerNamee" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
            <label class="form-label">Código Postal</label>
            <input v-model="postaCod" class="form-control border-bottom border-dark p-2 mb-3" type="text">
            <label class="form-label">Teléfono de contacto (Opcional)</label>
            <input v-model="contactPhone" class="form-control border-bottom border-dark p-2 mb-3" type="text">
            <label class="form-label">Hora de Cierre</label>
            <input v-model="closeHour" class="form-control border-bottom border-dark p-2" type="time">
          </div>
          <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar todos los campos</p></div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-start">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 text-end">
            <button @click="addSucursal" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-pencil-square me-2"></i>Editar Sucursal<i @click="closeUpdate"
                                                                                           class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="form-label">Nombre</label>
            <input v-model="name" class="form-control border-bottom border-dark p-2 mb-3" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6 col-sm-12">
            <label class="form-label">Estado</label>
            <select class="form-select mb-3 p-2" v-model="estado">
              <option v-for="item in allEstados" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
            <label class="form-label">Dirección</label>
            <input
              v-model="address"
              class="form-control border-bottom border-dark p-2 mb-3"
              placeholder="Escriba la dirección">
            <label class="form-label">Teléfono</label>
            <input v-model="phone" class="form-control border-bottom border-dark p-2 mb-3" type="text">
            <label class="form-label">Hora de Apertura</label>
            <input v-model="openHour" class="form-control border-bottom border-dark p-2" type="time">
          </div>
          <div class="col-lg-6 col-sm-12">
            <label class="form-label">Ciudad</label>
            <select class="form-select mb-3 p-2" v-model="ciudad" v-bind:disabled="estado === 0">
              <option v-for="item in ciudadPerNamee" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
            <label class="form-label">Código Postal</label>
            <input v-model="postaCod" class="form-control border-bottom border-dark p-2 mb-3" type="text">
            <label class="form-label">Teléfono de contacto (Opcional)</label>
            <input v-model="contactPhone" class="form-control border-bottom border-dark p-2 mb-3" type="text">
            <label class="form-label">Hora de Cierre</label>
            <input v-model="closeHour" class="form-control border-bottom border-dark p-2" type="time">
          </div>
          <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar todos los campos</p></div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="updateSucursal" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showSucursal" :class="['modal', { 'show': showSucursal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="h-100">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles de la Sucursal<i @click="closeSucursal"
                                                                                            class="material-icons-round opacity-10 modal-icon">close</i>
            </h4>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Nombre:</strong> &nbsp;
                    {{ this.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Teléfono:</strong> &nbsp; {{ this.phone }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Teléfono de Contacto:</strong> &nbsp; {{ this.contactPhone }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Dirección:</strong> &nbsp;
                    {{ this.address }}
                  </li>
                </ul>
              </div>
              <div class="col-lg-6 col-sm-12">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Código Postal:</strong> &nbsp;
                    {{ this.postaCod }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Hora de Apertura:</strong> &nbsp; {{ this.openHour }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Hora de Cierre:</strong> &nbsp; {{ this.closeHour }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row text-end">
              <div class="col-8"></div>
              <div class="col-4">
                <button @click="closeSucursal" class="mt-3 btn btn-dark text-end">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { mapState } from "vuex";

const DESACTIVE_SUCURSAL = gql`
mutation($id:ID!){
  desactiveSucursal(id: $id) {
        success
    }
}
`;
const BUSCAR_SUCURSAL = gql`
          query SucursalPerName($name:String!) {
              sucursalPerName(name: $name) {
                  id
                  name
                  phone
                  contactPhone
                  address
                  postalCod
                  openHour
                  closeHour
                  isActive
                  ciudad{
                  estado{
                  name
                  id
                  }
                  id
                  name
                  }
              }
          }
          `;
const ACTIVE_SUCURSAL = gql`
mutation ActiveSucursal($id:ID!) {
    activeSucursal(id: $id) {
        success
    }
}
`;
const INSERT_SUCURSAL = gql`
  mutation AddSucursal($address:String!, $closeHour:Time!, $name:String!, $ciudad:ID!,
  $openHour:Time!, $phone:String!, $postaCod:String!, $contactPhone:String!) {
    addSucursal(
        address: $address,
        closeHour: $closeHour,
        name: $name,
        openHour: $openHour,
        phone: $phone,
        postalCod: $postaCod,
        contactPhone: $contactPhone,
        ciudad: $ciudad
    ) {
        success
        errors
    }
}
  `;
const UPDATE_SUCURSAL = gql`
  mutation UpdateSucursal($address:String!, $closeHour:Time!, $ciudad:ID!, $id:ID!, $name:String!, $openHour:Time!, $phone:String!, $postalCod:String!, $contactPhone:String!) {
    updateSucursal(
        address: $address
        closeHour: $closeHour
        id: $id
        name: $name
        openHour: $openHour
        phone: $phone
        postalCod: $postalCod
        contactPhone: $contactPhone,
        ciudad: $ciudad
    ) {
        success
        errors
    }
}
  `;
const DELETE_SUCURSAL = gql`
mutation DeleteSucursal($id:ID!) {
    deleteSucursal(id: $id) {
        success
    }
}
`;
const ESTADOS = gql`
          {
            allEstados{
              id
              name
              abreviatura
              isActive
            }
          }
          `;
const CIUDADPERESTADO = gql`
query($id:ID!){
  ciudadPerNamee(id:$id){
    id
    name
    estado{
      name
      id
    }
    isActive
  }
}`;
export default {
  name: "List_Sucursal",
  components: { MaterialButton, VueGoogleAutocomplete },
  data() {
    return {
      misearch: "",
      sucursalPerName: [],
      allGroups: [],
      showModal: false,
      showSucursal: false,
      UpdateModal: false,
      address: "",
      ciudad: 0,
      estado: 0,
      ciudadPerNamee: [],
      closeHour: "",
      openHour: "",
      phone: "",
      postaCod: "",
      contactPhone: "",
      name: "",
      sucursalId: 0,
      error: false,
      allEstados: []
    };
  },
  apollo: {
    sucursalPerName: {
      query: BUSCAR_SUCURSAL,
      variables() {
        return {
          name: this.misearch
        };
      },
      fetchPolicy: "cache-and-network"
    },
    allEstados: {
      query: ESTADOS,
      fetchPolicy: "cache-and-network"
    },
    ciudadPerNamee: {
      query: CIUDADPERESTADO,
      variables() {
        return {
          id: this.estado
        };
      },
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async addSucursal() {
      if (this.address === "" || this.name === "" || this.closeHour === "" ||
        this.openHour === "" || this.phone === "" || this.postaCod === "" || this.ciudad === 0) {
        this.error = true;
        return false;
      }
      this.$apollo.mutate({
        mutation: INSERT_SUCURSAL,
        variables: {
          name: this.name,
          address: this.address.route + ", " + this.address.administrative_area_level_1,
          closeHour: this.closeHour,
          openHour: this.openHour,
          phone: this.phone,
          postaCod: this.postaCod,
          contactPhone: this.contactPhone,
          ciudad: this.ciudad
        },
        refetchQueries: [{ query: BUSCAR_SUCURSAL, variables: { name: this.misearch } }]
      }).then(response => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        this.closeModal();
        Toast.fire({
          icon: "success",
          title: "Sucursal creada"
        });
        this.showModal = false;
        this.error = false;
        this.address = "";
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "error",
          title: "Error al crear la Sucursal"
        });
        console.error("La mutación falló:", error);
      });
    },
    async updateSucursal() {
      await this.$apollo
        .mutate({
          mutation: UPDATE_SUCURSAL,
          variables: {
            name: this.name,
            phone: this.phone,
            contactPhone: this.contactPhone,
            openHour: this.openHour,
            closeHour: this.closeHour,
            postalCod: this.postaCod,
            address: this.address,
            id: this.sucursalId,
            ciudad: this.ciudad
          },
          refetchQueries: [{ query: BUSCAR_SUCURSAL, variables: { name: this.misearch } }]
        }).then(response => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          this.closeUpdate();
          Toast.fire({
            icon: "success",
            title: "Sucursal actualizada"
          });
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al actualizar la Sucursal"
          });
          console.error("La mutación falló:", error);
        });
    },
    async DeleteSucursal(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE_SUCURSAL,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR_SUCURSAL, variables: { name: this.misearch } }]
          });
          if (data.deleteSucursal.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Sucursal eliminada"
            });
          }
        }
      });
    },
    async DesactiveSucursal(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE_SUCURSAL,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR_SUCURSAL, variables: { name: this.misearch } }]
      });
      if (data.desactiveSucursal.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "warning",
          title: "Sucursal desactivada"
        });
      }
    },
    async ActiveSucursal(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE_SUCURSAL,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR_SUCURSAL, variables: { name: this.misearch } }]
      });
      if (data.activeSucursal.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Sucursal activada"
        });
      }
    },
    openModal() {
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    openSucursal(name, address, phone, contactPhone, postalCod, openHour, closeHour, ciudad) {
      this.closeHour = closeHour;
      this.openHour = openHour;
      this.phone = phone;
      this.postaCod = postalCod;
      this.contactPhone = contactPhone;
      this.name = name;
      this.address = address;
      this.ciudad = ciudad ? ciudad.name : 0;
      this.estado = ciudad ? ciudad.estado ? ciudad.estado.name : "" : "";
      this.showSucursal = true;
      document.body.classList.add("modal-open");
    },
    openUpdate(sucursal) {
      this.closeHour = sucursal.closeHour;
      this.openHour = sucursal.openHour;
      this.phone = sucursal.phone;
      this.postaCod = sucursal.postalCod;
      this.contactPhone = sucursal.contactPhone;
      this.name = sucursal.name;
      this.address = sucursal.address;
      this.sucursalId = sucursal.id;
      this.ciudad = sucursal.ciudad ? sucursal.ciudad.id : 0;
      this.estado = sucursal.ciudad ? sucursal.ciudad.estado ? sucursal.ciudad.estado.id : 0 : 0;
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeSucursal() {
      this.closeHour = "";
      this.openHour = "";
      this.phone = "";
      this.postaCod = "";
      this.contactPhone = "";
      this.name = "";
      this.address = "";
      this.ciudad = 0;
      this.estado = 0;
      this.showSucursal = false;
      document.body.classList.remove("modal-open");
    },
    closeUpdate() {
      this.closeHour = "";
      this.openHour = "";
      this.phone = "";
      this.postaCod = "";
      this.contactPhone = "";
      this.name = "";
      this.address = "";
      this.ciudad = 0;
      this.estado = 0;
      this.sucursalId = 0;
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    closeModal() {
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    getAddressData: function(addressData, placeResultData, id) {
      this.address = addressData;
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

@media (max-width: 550px) {
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    width: 90%;
  }
}
</style>
