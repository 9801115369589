<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card" v-if="!loading">
          <div v-if="notifications.length > 0" class="accordion" id="accordionExample">
            <div class="accordion-item border border-1 border-opacity-25"
                 style="padding-left: 1rem"
                 v-for="notification in notifications"
                 :key="notification.id">
              <h6 class="accordion-header" :id="`headingOne${notification.id}`">
                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        :data-bs-target="`#collapseOne${notification.id}`"
                        aria-expanded="true"
                        :aria-controls="`collapseOne${notification.id}`"
                        @click="readNotificationAction(notification)"
                >
                  <i class="material-icons cursor-pointer"
                     v-if="!notification.readClient"
                     style="font-size:32px; color: #1b64ce">
                    notification_important
                  </i>
                  <i class="material-icons cursor-pointer"
                     v-else
                     style="font-size:32px;">
                    circle_notifications
                  </i>
                  <strong>{{ notification.title }}</strong>
                </button>
              </h6>
              <div :id="`collapseOne${notification.id}`"
                   class="accordion-collapse collapse"
                   :aria-labelledby="`headingOne${notification.id}`"
                   data-bs-parent="#accordionExample"
                   style="padding-right: 1rem; padding-bottom: 0.5rem;"
              >
                <div class="accordion-body border border-1 border-opacity-10 border-radius-md"
                     style="padding-left: 0.4rem; padding-right: 0.4rem;">
                  <a class="cursor-pointer"
                    @click="goToReservation(notification.reservation)">{{ notification.message
                    }}</a>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="d-flex flex-column align-items-center">
            <EmptyNotifications size="330" />
          </div>
          <div class="d-flex" v-if="hasNextPage">
            <button class="btn btn-info class-btn-more" @click="loadNotifications">Ver m&aacute;s</button>
          </div>
        </div>
        <LoadingDatas v-else />
      </div>
    </div>
  </div>
</template>

<script>

import { getNotifications, readMessage } from "@/actions";
import { decodeBase64 } from "@/util";
import EmptyNotifications from "@/components/EmptyNotifications.vue";
import LoadingDatas from "@/components/LoadingDatas.vue";

export default {
  name: "Notification",
  components: { EmptyNotifications, LoadingDatas },
  data() {
    return {
      socket: new WebSocket(`${process.env.VUE_APP_URL_WS_NOTIFICATIONS}?user=${this.$store.state.userid}`),
      notifications: [],
      loading: true,
      search: "",
      hasNextPage: false,
      endCursor: ""
    };
  },
  mounted() {
    this.socket.onmessage = (data) => {
      const newNotification = JSON.parse(data.data.replaceAll("'", "\""));
      if (!this.notifications.find(e => e.id == newNotification.id)) {
        this.notifications = [
          {
            ...newNotification,
            readClient: false
          },
          ...this.notifications
        ];
      }
    };

    this.socket.onopen = (evt) => {
      console.log(evt);
    };

    this.loadNotifications();
  },
  methods: {
    loadNotifications() {
      this.loading = true;
      getNotifications(this.search, this.endCursor).then((resp) => {
        this.hasNextPage = resp.data.data.searchFirebaseNotifications.pageInfo.hasNextPage;
        this.endCursor = resp.data.data.searchFirebaseNotifications.pageInfo.endCursor;
        this.notifications = this.notifications.concat(resp.data.data.searchFirebaseNotifications.edges.map(value => {
          value.node.id = decodeBase64(value.node.id);
          return value.node;
        }));
      }).catch(err => console.log(err)).finally(() => {
        this.loading = false;
      });
    },
    readNotificationAction(item) {
      if (item.readClient == false) {
        readMessage(item).then(() => {
          this.notifications.forEach(e => {
            if (e.id == item.id) {
              e.readClient = true;
            }
          });
          this.$store.state.notifications = this.$store.state.notifications.filter(e => e.id != item.id);
        }).catch(err => console.log(err));
      }
    },
    goToReservation(item) {
      if (item !== null) {
        this.$store.state.reserva = item;
        this.$store.state.reserva_notification = true;
        if (item.origen === "Administración") {
          this.$router.push({ name: "Reservas desde la Administración" });
        } else {
          this.$router.push({ name: "Reservas desde el Portal Web" });
        }
      }
    }
  }
};
</script>

<style scoped>
.class-btn-more {
  margin: 1rem auto;
}
</style>