<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-2">
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <div class="card">
                <div class="card-header">
                  <div class="row border-bottom border-dark">
                    <div class="col-lg-6 col-lx-6 col-md-12"><h4 class="mb-0">
                      <i class="fa fa-pencil-square me-2"></i>Editar Vehículo
                    </h4></div>
                    <div class="col-lg-6 col-lx-6 col-md-12">
                      <div
                        class="border-radius-lg"
                      >
                        <div class="multisteps-form__progress">
                          <button
                            class="multisteps-form__progress-btn text-dark"
                            type="button"
                            title="Address"
                            :class="activeStep >= 0 ? activeClass : ''"
                            @click="activeStep = 0"
                          >
                            1
                          </button>
                          <button
                            class="multisteps-form__progress-btn text-dark"
                            type="button"
                            title="Profile"
                            :class="activeStep >= 1 ? activeClass : ''"
                            @click="activeStep = 1"
                          >
                            2
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form class="multisteps-form__form">
                    <!--single form panel-->
                    <primera_parte :class="activeStep === 0 ? activeClass : ''" />
                    <!--single form panel-->
                    <segunda_parte :class="activeStep === 1 ? activeClass : ''" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import primera_parte from "./components/first_edit_car.vue";
import segunda_parte from "./components/end_edit_car.vue";
import Swal from "sweetalert2";
import gql from "graphql-tag";

const UPDATE = gql`
mutation($noeco:String!, $categoria:ID!, $id:ID!, $anno:String!,
  $titular:ID!, $aseguradora:ID!, $color:String!, $noserie:String!, $placa:String!, $kilometraje:Int!,
  $capacidad:String!, $transmision:String!, $estado:ID!, $vin:String!, $sucursal:ID!){
  updateVehiculo(noEco:$noeco, categoriaId:$categoria, titularId:$titular, aseguradoraId:$aseguradora,
  color:$color, noSerie:$noserie, placa:$placa,cantTanque:$capacidad, transmision:$transmision, kilometraje:$kilometraje,
   estado:$estado, vin:$vin, sucursal:$sucursal, id:$id, anno:$anno){
    success
    errors
  }
}
`;
export default {
  name: "new_car",
  components: {
    primera_parte,
    segunda_parte
  },
  data() {
    return {
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      no_eco: "",
      version: 0,
      categoria: 0,
      anno: "",
      color: "",
      no_serie: "",
      placa: "",
      titular: 0,
      aseguradora: 0,
      transmision: 0,
      vin: 0,
      estado: 0,
      capacidad: 0,
      sucursal: 0,
      minimo: "",
      carid: 0,
      marca: 0,
      modelo: 0
    };
  },
  methods: {
    nextStep(no_eco, color, anno, categoria, no_serie, placa, capacidad) {
      if (no_eco === "" || anno === "" || categoria === 0 || color === "" || no_serie === "" || placa === "" || capacidad === 0) {
        this.$swal({
          text: "Introduzca todos los datos correctamente",
          icon: "error",
          customClass: {
            confirmButton: "bg-warning"
          }
        });
        return false;
      }
      this.categoria = categoria;
      this.color = color;
      this.no_serie = no_serie;
      this.placa = placa;
      this.no_eco = no_eco;
      this.capacidad = capacidad;
      this.anno = anno;
      this.activeStep = 1;
    },
    prevStep() {
      this.activeStep = 0;
    },
    async Update(aseguradora, estado, titular, transmision, vin, sucursal, kilometraje) {
      if (estado === 0 || aseguradora === 0 || titular === 0 || transmision === "" || vin === "" || sucursal === 0) {
        this.$swal({
          text: "Introduzca todos los datos correctamente",
          icon: "error",
          customClass: {
            confirmButton: "bg-warning"
          }
        });
        return false;
      }
      await this.$apollo
        .mutate({
          mutation: UPDATE,
          variables: {
            noeco: this.no_eco,
            categoria: parseInt(this.categoria),
            color: this.color,
            noserie: this.no_serie,
            placa: this.placa,
            titular: parseInt(titular),
            aseguradora: parseInt(aseguradora),
            transmision: transmision,
            vin: vin,
            estado: parseInt(estado),
            capacidad: this.capacidad,
            sucursal: parseInt(sucursal),
            anno: this.anno,
            id: this.$store.state.carid,
            kilometraje: kilometraje
          }
        }).then(response => {
          if (response.data.updateVehiculo.errors) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });

            Toast.fire({
              icon: "error",
              title: response.data.updateVehiculo.errors
            });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Vehículo actualizado"
            });
            this.$router.push("/vehiculos/listado");
          }
        });
    }
  }
};
</script>
