<template>
  <div
    class="page-header align-items-start min-height-300 m-3 border-radius-xl banner"
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
  </div>
  <div class="container mb-4">
    <div class="row mt-lg-n12 mt-md-n12 mt-n12 justify-content-center">
      <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
        <div class="card mt-8">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div style="background-color: #ff6900"
                 class="shadow-success border-radius-lg py-3 pe-1 text-center py-4"
            >
              <div>
                <img src="@/assets/img/logologinadmin.png" class="img-fluid" style="width: 50%" alt="bannerimage" />
              </div>
              <p class="mb-1 text-sm text-white">
                Introduzca su correo y contrase&ntilde;a para iniciar sesi&oacute;n
              </p>
            </div>
          </div>
          <div class="card-body">
            <div class="mb-4">
              <input v-model="email"
                     class="form-control"
                     id="email"
                     type="email"
                     placeholder="Correo"
                     name="email"
              />
            </div>
            <div class="mb-3">
              <input
                v-model="password"
                class="form-control"
                id="password"
                type="password"
                placeholder="Contraseña"
                name="password"
              />
            </div>
            <!--              <material-switch id="rememberMe" name="Remember Me" checked-->
            <!--                >Remember me</material-switch-->
            <!--              >-->
            <div class="text-center">
              <material-button
                class="my-4 mb-2"
                variant="gradient"
                color="dark"
                full-width
                @click="login"
              >Entrar
              </material-button
              >
            </div>
          </div>
          <div class="card-footer">
            <div class="px-1 pt-0 text-center card-footer px-lg-2 mt-3">
              <p class="mx-auto mb-4 text-sm">
                Ha olvidado su contrase&ntilde;a?<br>
                <router-link
                  :to="{ name: 'Restablecer Contraseña' }"
                  class="text-warning text-gradient font-weight-bold"
                >Recuperar Contrase&ntilde;a
                </router-link
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialButton from "@/components/MaterialButton.vue";

const body = document.getElementsByTagName("body")[0];
import gql from "graphql-tag";
import Swal from "sweetalert2";
import $cookie from "core-js/internals/task";
import { mapActions, mapMutations } from "vuex";
import { reloadHeader } from "@/actions";

const login = gql`
mutation Login($email:String! $password:String!) {
    login(email: $email password: $password) {
        success
        token
        refreshToken
        error
        info {
            id
            username
            firstName
            lastName
            email
            isActive
            isDeleted
            phone
            groups {
                id
                name
                permission {
                    id
                    name
                    description
                }
            }
            sucursal {
                id
                name
                phone
                contactPhone
                address
                postalCod
                openHour
                closeHour
                isActive
            }
            ciudad {
                id
                name
                isActive
            }
        }
    }
}
`;
const PERMISOS = gql`
query PermissionsPerGroup($id:Int!) {
    permissionsPerGroup(id: $id) {
        id
        name
        description
    }
}`;
export default {
  name: "SigninCover",
  components: {
    MaterialInput,
    MaterialSwitch,
    MaterialButton
  },
  data() {
    return {
      email: "",
      password: ""
    };
  },
  methods: {
    ...mapMutations(["SetPermisos"]),
    async login() {
      this.$store.state.Cargando = true;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: login,
          variables: {
            email: this.email,
            password: this.password
          }
        });
        if (data.login.success) {
          if (data.login.info.isActive && !data.login.info.isDeleted) {
            this.SetPermisos(data.login.info.groups ? data.login.info.groups.permission : null);
            this.$store.state.jwt = data.login.token ? data.login.token : null;
            sessionStorage.setItem("token", data.login.token ? data.login.token : "");
            $cookies.set("token", data.login.token ? data.login.token : null, 60 * 60 * 8);
            this.$store.state.userid = data.login.info ? data.login.info.id : null;
            this.$store.state.userSucursal = data.login.info.sucursal ? data.login.info.sucursal.id : null;
            this.$store.state.username = data.login.info ? data.login.info.username : null;
            this.$store.state.email = data.login.info ? data.login.info.email : null;
            this.$store.state.firstName = data.login.info ? data.login.info.firstName : null;
            this.$store.state.lastName = data.login.info ? data.login.info.lastName : null;
            this.$store.state.rol = data.login.info.groups ? data.login.info.groups.name : null;
            reloadHeader();
            await this.$store.dispatch("doLogin");
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Bienvenido " + data.login.info.email
            });
            this.$store.state.Cargando = false;
            this.$router.push("/dashboard");
          } else {
            this.$store.state.Cargando = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Error en las credenciales"
            });
          }
        } else {
          this.$store.state.Cargando = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error en las credenciales"
          });
        }
      } catch (error) {
        this.$store.state.Cargando = false;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Error en las credenciales"
        });
      }
    }
  },
  created() {
    this.$store.state.isAuth = false;
  }
};
</script>
<style scoped>
.banner {
  position: relative;
  background-image: url(@/assets/img/banner.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  padding: 100px 0 100px;
}
</style>
