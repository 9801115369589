<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="row mt-1">
        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 mb-2">
          <label class="form-label">Sucursal</label>
          <select v-model="sucursal" class="form-select">
            <option selected disabled value="0">Seleccione una Sucursal</option>
            <option v-for="item in allSucursales" :value="item.id" v-show="item.isActive">{{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 mb-2">
          <label class="form-label">Titular</label>
          <select v-model="titular" class="form-select">
            <option selected disabled value="0">Seleccione un Titular</option>
            <option v-for="titular in allTitulares" :value="titular.id" v-show="titular.isActive">{{ titular.name }}
            </option>
          </select>
        </div>
        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 mb-2">
          <label class="form-label">Transmisión</label>
          <select class="form-select" v-model="transmision">
            <option value="" selected disabled>Seleccione un tipo de transmisión</option>
            <option value="Manual">Manual</option>
            <option value="Automática">Automática</option>
          </select>
        </div>
        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 mb-2">
          <label class="form-label">Aseguradora</label>
          <select v-model="aseguradora" class="form-select">
            <option selected disabled value="0">Seleccione una Aseguradora</option>
            <option v-for="item in allAseguradoras" :value="item.id" v-show="item.isActive">{{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 mb-2">
          <label class="form-label">Estado Emplacado</label>
          <select v-model="estado" class="form-select">
            <option selected disabled value="0">Seleccione un Estado</option>
            <option v-for="item in allEstados" :value="item.id" v-show="item.isActive">{{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 mb-2">
          <label class="form-label">Kilometraje</label>
          <input v-model="kilometraje" class="form-control" type="number">
        </div>
      </div>
      <div v-show="this.$parent.error" class="text-danger mt-3 text-center p-2"
           style="background-color: rgba(255,19,28,0.08)">
        <div class="d-flex align-center justify-content-center"><i
          class="material-icons-round opacity-10 me-2">error</i>
          <p class="text-dark">Debe llenar todos los campos</p></div>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="secondary"
          variant="gradient"
          class="js-btn-prev"
          @click="this.$parent.prevStep"
        >Atrás
        </material-button
        >
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="this.$parent.Guardar(aseguradora, estado, titular, transmision, vin, sucursal, kilometraje)"
        >Guardar
        </material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import gql from "graphql-tag";

const ALLSUCURSALES = gql`
query AllSucursales {
    allSucursales {
        id
        name
        isActive
    }
}
`;
const ALLTITULARES = gql`
query AllTitulares {
    allTitulares {
        id
        name
        isActive
    }
}
`;
const ALLASEGURADORAS = gql`
query AllAseguradoras {
    allAseguradoras {
        id
        name
        isActive
    }
}
`;
const ALLESTADOS = gql`
query AllEstados {
    allEstados {
        id
        name
        isActive
    }
}
`;
export default {
  name: "Profile",
  components: {
    MaterialButton,
    MaterialTextarea
  },
  data() {
    return {
      vin: "",
      transmision: "",
      minimo: "",
      estado: 0,
      aseguradora: 0,
      titular: 0,
      sucursal: 0,
      allTitulares: [],
      allEstados: [],
      allSucursales: [],
      allAseguradoras: [],
      kilometraje:0
    };
  },
  apollo: {
    allTitulares: {
      query: ALLTITULARES,
      fetchPolicy: "cache-and-network"
    },
    allEstados: {
      query: ALLESTADOS,
      fetchPolicy: "cache-and-network"
    },
    allSucursales: {
      query: ALLSUCURSALES,
      fetchPolicy: "cache-and-network"
    },
    allAseguradoras: {
      query: ALLASEGURADORAS,
      fetchPolicy: "cache-and-network"
    }
  }
};
</script>
