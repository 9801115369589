<template>
  <div class="container-fluid">
    <div class=" row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Imágenes del banner del portal
            </h5>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar slider" aria-label="Recipient's username"
                       aria-describedby="button-addon2" autocomplete="new-password">
              </div>
            </div>
            <div class="col-9 text-end">

            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped" style="font-size: 0.875rem !important;">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Nombre</th>
                <th>Imagen</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in slidersPerName" :key="item.id" class="text-center align-middle">
                <td class="text-wrap">{{ item.name ? item.name : "" }}</td>
                <td class="text-wrap">
                  <div class="img-container">
                    <img class="img-slider" :alt="item.name" :src="this.$store.state.store_url+item.photo">
                  </div>
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button v-if="item.isActive" @click="desactive(item.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Desactivar slider" data-container="body"
                            data-animation="true"
                            class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">block</i></button>
                    <button v-else @click="active(item.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Activar slider" data-container="body" data-animation="true"
                            class="btn btn-warning p-1 ms-1">
                      <i class="material-icons opacity-10">check_circle</i></button>
                    <button @click="openUpdate(item)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Actualizar slider" data-container="body"
                            data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
<!--                    <button @click="Delete(item.id)" data-bs-toggle="tooltip" data-bs-placement="top"-->
<!--                            title="Eliminar Aseguradora" data-container="body" data-animation="true"-->
<!--                            class="btn btn-danger p-1 ms-1">-->
<!--                      <i class="material-icons opacity-10">delete</i></button>-->
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-comment-alt me-2"></i>Cambiar imagen del slider {{ name }}
            <i @click="closeModal" class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <label class="form-label">Nombre</label>
          <input type="text" class="form-control mb-3" v-model="name">
          <label class="form-label">Seleccione una Imagen</label>
          <input type="file" class="form-control mb-3" @change="onImagenSeleccionada">
          <img class="img-fluid" :src="preview" style="height: 250px">
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cerrar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="guardarImagen" class="btn btn-dark" type="submit">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import moment from "moment";
import { mapState } from "vuex";
import Swal from "sweetalert2";
import axios from "axios";
import { useBase64 } from "@vueuse/core";

const BUSCAR = gql`
          query SlidersPerName($name:String!) {
    slidersPerName(name: $name) {
        id
        photo
        name
        isActive
    }
}`;
const UPDATE = gql`
mutation UpdateSlider($id:ID!, $image:Upload!, $name:String!) {
    updateSlider(id: $id, image: $image, name: $name) {
        success
        error
    }
}`;
const CREATE = gql`
mutation CreateSlider($image:Upload!, $name:String!) {
    createSlider(image: $image, name: $name) {
        success
        error
    }
}`;
const ACTIVE = gql`
mutation ActiveSlider($id:ID!) {
    activeSlider(id: $id) {
        success
        error
    }
}`;
const DESACTIVE = gql`
mutation DesactiveSlider($id:ID!) {
    desactiveSlider(id: $id) {
        success
        error
    }
}`;
const DELETE = gql`
mutation DeleteSlider($id:ID!) {
    deleteSlider(id: $id) {
        success
        error
    }
}`;
export default {
  name: "Sliders",
  components: { MaterialButton },
  data() {
    return {
      misearch: "",
      item: null,
      slidersPerName: [],
      showModal: false,
      photo: "",
      name: "",
      id: 0,
      preview: null,
      imagen64: ""
    };
  },
  apollo: {
    slidersPerName: {
      query: BUSCAR,
      variables() {
        return {
          name: this.misearch
        };
      },
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async desactive(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      if (data.desactiveSlider.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "warning",
          title: "Slider desactivado"
        });
      }
    },
    async active(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      if (data.activeSlider.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Slider activado"
        });
      }
    },
    openUpdate(item) {
      this.name = item.name;
      this.id = item.id;
      if (item.photo) {
        this.preview = this.$store.state.store_url + item.photo;
      }
      this.showModal = true;
    },
    closeModal() {
      this.name = "";
      this.id = 0;
      this.photo = null;
      this.preview = null;
      this.showModal = false;
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    onImagenSeleccionada(event) {
      this.photo = event.target.files[0];
      const file = event.target.files[0];
      const { base64 } = useBase64(file);
      this.preview = URL.createObjectURL(file);
    },
    guardarImagen() {
      let data = new FormData();
      data.append("operations", `{"query": "mutation ($file: Upload, $id: ID) {\\n  updateSlider(id: $id, file: $file) {\\n    error  }\\n}","variables": {"id": ${this.id},"file": null},"operationName": null}`);
      data.append("map", "{\"1\": [\"variables.file\"]}");
      data.append("1", this.photo, this.photo.name);
      return axios.post(`${this.$store.state.url}graphql/`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(resp => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Imagen guardada"
        });
        this.$apollo.mutate({
          mutation: BUSCAR,
          variables: {
            name: this.misearch
          }
        }).then(response => {
        });
        this.closeModal();
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Error al subir la imagen"
        });
        this.closeModal();
      });
    },
    async Delete(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
          });
          if (data.deleteSlider.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Slider eliminado"
            });
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('@/assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 60%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.img-container {

  overflow: hidden;
}

.img-slider {
  width: 500px;
  height: 150px;
  object-fit: cover;
}

@media (max-width: 550px) {
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    width: 90%;
  }
}
</style>
