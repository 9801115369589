<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de Estados
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar Estado" aria-label="Recipient's username" aria-describedby="button-addon2">
              </div>
            </div>
            <div class="col-9 text-end">
              <button @click="openModal" class="btn btn-dark align-content-end" type="submit">Nuevo Estado</button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Estado</th>
                <th>Abreviatura</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="state in estadoPerName" :key="state.id">
                <td :class="state.isActive?'':'text-danger'">{{ state.name }}</td>
                <td :class="state.isActive?'':'text-danger'">{{ state.abreviatura?state.abreviatura:"" }}</td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button v-if="state.isActive" @click="DesactiveState(state.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Desactivar Estado" data-container="body"
                            data-animation="true" class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">block</i></button>
                    <button v-else @click="ActiveState(state.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Activar Estado" data-container="body" data-animation="true"
                            class="btn btn-warning p-1 ms-1">
                      <i class="material-icons opacity-10">check_circle</i></button>
                    <button @click="openUpdate(state.name, state.id, state.abreviatura)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Actualizar Estado" data-container="body" data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                    <button @click="DeleteState(state.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Eliminar Estado" data-container="body" data-animation="true"
                            class="btn btn-danger p-1 ms-1">
                      <i class="material-icons opacity-10">delete</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nuevo Estado<i @click="closeModal"
                                                                                      class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Nombre</label>
            <input type="text" class="form-control mb-3 border-dark border-bottom" v-model="address">
            <label class="form-label">Abreviatura</label>
            <input type="text" class="form-control mb-3 border-dark border-bottom" v-model="abreviatura">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe escribir un Estado</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="addState" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-pencil-square me-2"></i>Editar Estado<i @click="closeUpdate"
                                                                                         class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Nombre</label>
            <input type="text" class="form-control mb-3 border-dark border-bottom" v-model="address">
            <label class="form-label">Abreviatura</label>
            <input type="text" class="form-control mb-3 border-dark border-bottom" v-model="abreviatura">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe escribir un Estado</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="updateState" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { mapState } from "vuex";

const DESACTIVE_STATE = gql`
mutation($id:ID!){
  desactiveState(id: $id) {
        success
    }
}
`;
const BUSCAR_STATE = gql`
          query EstadoPerName($name:String!) {
              estadoPerName(name: $name) {
                  id
                  name
                  abreviatura
                  isActive
              }
          }
          `;
const ACTIVE_STATE = gql`
mutation ActiveState($id:ID!) {
    activeState(id: $id) {
        success
    }
}
`;
const INSERT_STATE = gql`
  mutation AddState($name:String!, $abreviatura:String!) {
    addState(name: $name, abreviatura: $abreviatura) {
        success
        errors
    }
}
  `;
const UPDATE_STATE = gql`
  mutation UpdateState($id:ID!, $name:String!, $abreviatura:String!) {
    updateState(id: $id, name: $name, abreviatura:$abreviatura) {
        success
        errors
    }
}
  `;
const DELETE_STATE = gql`
mutation DeleteState($id:ID!) {
    deleteState(id: $id) {
        success
    }
}
`;
export default {
  name: "List_Estado",
  components: { MaterialButton, VueGoogleAutocomplete },
  data() {
    return {
      misearch: "",
      address: "",
      estadoPerName: [],
      showModal: false,
      estadoid: 0,
      abreviatura: "",
      UpdateModal: false,
      error: false
    };
  },
  apollo: {
    estadoPerName: {
      query: BUSCAR_STATE,
      variables() {
        return {
          name: this.misearch
        };
      },
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async addState() {
      if (this.address === "" || this.abreviatura === "") {
        this.error = true;
        return false;
      }
      this.$apollo.mutate({
        mutation: INSERT_STATE,
        variables: {
          name: this.address,
          abreviatura: this.abreviatura
        },
        refetchQueries: [{ query: BUSCAR_STATE, variables: { name: this.misearch } }]
      }).then(response => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Estado creado"
        });
        this.closeModal();
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "error",
          title: "Error al crear el Estado"
        });
        console.error("La mutación falló:", error);
      });
    },
    async updateState() {
      await this.$apollo
        .mutate({
          mutation: UPDATE_STATE,
          variables: {
            name: this.address,
            id: this.estadoid,
            abreviatura:this.abreviatura
          },
          refetchQueries: [{ query: BUSCAR_STATE, variables: { name: this.misearch } }]
        }).then(response => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Estado actualizado"
          });
          this.closeUpdate();
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al actualizar el Estado"
          });
          console.error("La mutación falló:", error);
        });
    },
    async DeleteState(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE_STATE,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR_STATE, variables: { name: this.misearch } }]
          });
          if (data.deleteState.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Estado eliminado"
            });
          }
        }
      });
    },
    async DesactiveState(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE_STATE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR_STATE, variables: { name: this.misearch } }]
      });
      if (data.desactiveState.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "warning",
          title: "Estado desactivado"
        });
      }
    },
    async ActiveState(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE_STATE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR_STATE, variables: { name: this.misearch } }]
      });
      if (data.activeState.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Estado activado"
        });
      }
    },
    openModal() {
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    closeModal() {
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    openUpdate(name, id, abreviatura) {
      this.address = name;
      this.estadoid = id;
      this.abreviatura = abreviatura;
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.address = "";
      this.estadoid = 0;
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
