import { InMemoryCache, ApolloClient } from "@apollo/client";
import { createApolloProvider } from "@vue/apollo-option";

const cache = new InMemoryCache();
const token = $cookies.get('token')
const apolloClient = new ApolloClient({
  cache,
  uri: process.env.VUE_APP_URL_SERVICES_GRAPHQL,
  //uri: "https://issyservices.qa.idooproject.com/graphql/",
  // uri: "http://localhost:3000/graphql/",
  // uri: "https://issyrental-ws.idooprod.com/graphql/",
  // uri:"https://becupon-ws.qa.idooproject.com/graphql/",
  headers: {
    Authorization: 'JWT '+token,
  },
});

export const provider = createApolloProvider({
  defaultClient: apolloClient,
});
