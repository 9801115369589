<template>
  <div class="container-fluid">
    <div class=" row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Preguntas y Respuestas
            </h5>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar Pregunta o Respuesta" aria-label="Recipient's username"
                       aria-describedby="button-addon2" autocomplete="new-password">
              </div>
            </div>
            <div class="col-9 text-end">
              <button @click="openModal" class="btn btn-dark align-content-end" type="submit">Nueva FAQ</button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Pregunta</th>
                <th>Respuesta</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="faq in faqPerName" :key="faq.id" class="text-center align-middle">
                <td :class="faq.isActive?'':'text-danger'" class="text-wrap">{{ faq.pregunta }}</td>
                <td :class="faq.isActive?'':'text-danger'" class="text-wrap">{{ faq.respuesta }}</td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button v-if="faq.isActive" @click="desactiveFAQ(faq.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Desactivar FAQ" data-container="body" data-animation="true"
                            class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">block</i></button>
                    <button v-else @click="activeFAQ(faq.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Activar FAQ" data-container="body" data-animation="true"
                            class="btn btn-warning p-1 ms-1">
                      <i class="material-icons opacity-10">check_circle</i></button>
                    <button @click="openUpdate(faq.pregunta,faq.respuesta, faq.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Actualizar FAQ" data-container="body" data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                    <button @click="DeleteFAQ(faq.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Eliminar FAQ" data-container="body" data-animation="true"
                            class="btn btn-danger p-1 ms-1">
                      <i class="material-icons opacity-10">delete</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nueva FAQ<i @click="closeModal"
                                                                                   class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Pregunta</label>
            <textarea class="form-control" v-model="pregunta"></textarea>
            <label class="form-label">Respuesta</label>
            <textarea class="form-control" v-model="respuesta"></textarea>
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe llenar los campos</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="addFAQ" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-pencil-square me-2"></i>Editar FAQ<i @click="closeUpdate"
                                                                                      class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Pregunta</label>
            <textarea class="form-control" v-model="pregunta"></textarea>
            <label class="form-label">Respuesta</label>
            <textarea class="form-control" v-model="respuesta"></textarea>
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe llenar todos los campos</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="updateFAQ" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { mapState } from "vuex";

const DESACTIVE = gql`
mutation($id:ID!){
  desactiveFaq(id: $id) {
        success
    }
}
`;
const BUSCAR = gql`
          query FAQPerName($name:String!) {
              faqPerName(name: $name) {
                  id
                  pregunta
                  respuesta
                  isActive
              }
          }
          `;
const ACTIVE = gql`
mutation ActiveFAQ($id:ID!) {
    activeFaq(id: $id) {
        success
    }
}
`;
const INSERT = gql`
  mutation AddFAQ($pregunta:String!, $respuesta:String!) {
    createFaq(pregunta: $pregunta, respuesta:$respuesta) {
        success
    }
}
  `;
const UPDATE = gql`
  mutation UpdateFAQ($id:ID!, $pregunta:String!, $respuesta:String!) {
    updateFaq(id: $id, pregunta: $pregunta, respuesta:$respuesta) {
        success
    }
}
  `;
const DELETE = gql`
mutation DeleteFAQ($id:ID!) {
    deleteFaq(id: $id) {
        success
    }
}
`;
export default {
  name: "List_Ciudad",
  components: { MaterialButton, VueGoogleAutocomplete },
  data() {
    return {
      misearch: "",
      pregunta: "",
      respuesta: "",
      faqid: 0,
      faqPerName: [],
      showModal: false,
      UpdateModal: false,
      error: false
    };
  },
  apollo: {
    faqPerName: {
      query: BUSCAR,
      variables() {
        return {
          name: this.misearch
        };
      },
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async addFAQ() {
      if (this.pregunta === "" || this.respuesta === "") {
        this.error = true;
        return false;
      }
      this.$apollo.mutate({
        mutation: INSERT,
        variables: {
          respuesta: this.respuesta,
          pregunta: this.pregunta
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      }).then(response => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "FAQ creada"
        });
        this.closeModal();
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "error",
          title: "Error al crear la FAQ"
        });
        console.error("La mutación falló:", error);
      });
    },
    async updateFAQ() {
      await this.$apollo
        .mutate({
          mutation: UPDATE,
          variables: {
            pregunta: this.pregunta,
            respuesta: this.respuesta,
            id: this.faqid
          },
          refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
        }).then(response => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "FAQ actualizada"
          });
          this.closeUpdate();
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al actualizar la FAQ"
          });
          console.error("La mutación falló:", error);
        });
    },
    async DeleteFAQ(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
          });
          if (data.deleteFaq.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "FAQ eliminada"
            });
          }
        }
      });
    },
    async desactiveFAQ(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      if (data.desactiveFaq.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "warning",
          title: "FAQ desactivada"
        });
      }
    },
    async activeFAQ(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      if (data.activeFaq.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "FAQ activada"
        });
      }
    },
    openUpdate(pregunta, respuesta, id) {
      this.pregunta = pregunta;
      this.respuesta = respuesta;
      this.faqid = id;
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.pregunta = "";
      this.respuesta = "";
      this.faqid = 0;
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    openModal() {
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    closeModal() {
      this.pregunta = "";
      this.respuesta = "";
      this.faqid = 0;
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}
@media (max-width: 550px) {
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    width: 90%;
  }
}
</style>
