import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import 'maz-ui/styles';
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import MaterialDashboard from "./material-dashboard";
import * as apolloProvider from "./apollo.provider";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "./assets/css/style.css";
import 'vue-skeletor/dist/vue-skeletor.css';
import { Skeletor } from 'vue-skeletor';
const appInstance = createApp(App);
appInstance.component(Skeletor.name, Skeletor);
appInstance.use(apolloProvider.provider);
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueSweetalert2);
appInstance.use(MaterialDashboard);
appInstance.mount("#app");

if (sessionStorage.getItem("store")) {
    store.replaceState(Object.assign({}, store.state, JSON.parse(sessionStorage.getItem("store"))))
}

window.addEventListener("beforeunload",()=>{
    sessionStorage.setItem("store",JSON.stringify(store.state))
});

const route = router.options.history.location;
