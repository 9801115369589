<template>
  <div class="container-fluid">
    <div class=" row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              IVA
            </h5>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>IVA</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in iva" :key="item.id" class="text-center align-middle">
                <td class="text-wrap">{{ item.iva }} %</td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button @click="openUpdate(item.iva, item.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Actualizar IVA" data-container="body" data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-pencil-square me-2"></i>Editar IVA<i @click="closeUpdate"
                                                                                      class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">IVA</label>
            <input class="form-control" v-model="ivainput" type="number" min="0.01">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe escribir un IVA</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="updateIVA" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";

const BUSCAR = gql`
          {
              iva{
                  id
                  iva
              }
          }
          `;
const UPDATE = gql`
  mutation UpdateFAQ($id:ID!, $iva:Decimal!) {
    iva(id: $id, iva: $iva) {
        success
    }
}
  `;
export default {
  name: "List_Ciudad",
  components: { MaterialButton, VueGoogleAutocomplete },
  data() {
    return {
      ivaid: 0,
      ivainput:0,
      iva: [],
      UpdateModal: false,
      error: false
    };
  },
  apollo: {
    iva: {
      query: BUSCAR,
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async updateIVA() {
      await this.$apollo
        .mutate({
          mutation: UPDATE,
          variables: {
            iva: this.ivainput,
            id: this.ivaid
          },
          refetchQueries: [{ query: BUSCAR }]
        }).then(response => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "IVA actualizada"
          });
          this.closeUpdate();
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al actualizar el IVA"
          });
          console.error("La mutación falló:", error);
        });
    },
    openUpdate(ivatabla, id) {
      this.ivainput = ivatabla;
      this.ivaid = id;
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.ivainput = 0;
      this.ivaid = 0;
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
