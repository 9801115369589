<template>
  <div ref="contrato_plantilla" class="container contrato">

    <div class="row mb-3">
      <div class="col-2">
        <img src="@/assets/img/issyrentallogo.png"
             style="width: 150px; height: 75px">
      </div>
      <div class="col-8 text-center">
        <p style="font-weight: bold; font-size: 16px; line-height: 25px;">Transporte de Calidad y Confort S, de R.L.
          de C.V.</p>
        <p style="font-weight: bold; font-size: 16px; margin-bottom: 30px">R.F.C. TCC 160706-BL7</p>
        <p>Correo electrónico: info@issyrental.com</p>
        <p>Teléfono: (664) 524-2099 Cel.: (664) 361 7721</p>
        <p>Calle 19 #303-A Fracc. Las Californias, Tijuana, B.C., C.P. 22404</p>
      </div>
      <div class="col-2 text-center">
        <p>Contrato de Arrendamiento</p>
        <p>(RENTAL AGREMENT)</p>
        <p>APENDICE “A”</p>
        <p>CONTRATO</p>
        <p style="font-weight: bold;">AE992</p>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-4"><p class="bold">NOMBRE (NAME)</p></div>
      <div class="col-2"><p class="bold">EDAD (AGE)</p></div>
      <div class="col-2"><p class="bold">CARRO No. (CAR No.)</p></div>
      <div class="col-2"><p class="bold">MARCA (MAKE)</p></div>
      <div class="col-2"><p class="bold">MODELO (MODEL)</p></div>
    </div>
    <div class="row mb-2">
      <div class="col-4"><p>{{ cliente_name }}</p></div>
      <div class="col-2"><p>28</p></div>
      <div class="col-2"><p>125462</p></div>
      <div class="col-2"><p>Ford</p></div>
      <div class="col-2"><p>Mustang</p></div>
    </div>
    <div class="row mb-1">
      <div class="col-6"><p class="bold">DIRECCIÓN LOCAL (LOCAL ADDRESS)</p></div>
      <div class="col-2"><p class="bold">PLACAS (PLATES)</p></div>
      <div class="col-2"><p class="bold">COLOR (COLOR)</p></div>
      <div class="col-2"><p class="bold">KM PERMITIDOS</p></div>
    </div>
    <div class="row mb-2">
      <div class="col-6"><p>Calle sadasdasd</p></div>
      <div class="col-2"><p>B456456</p></div>
      <div class="col-2"><p>Rojo</p></div>
      <div class="col-2"><p>Ilimitado</p></div>
    </div>
    <div class="row mb-1">
      <div class="col-6"><p></p></div>
      <div class="col-2"><p class="bold">FECHA SALIDA<br> (DATE OUT)</p></div>
      <div class="col-2"><p class="bold">TIEMPO SALIDA<br> (TIME OUT)/HR</p></div>
      <div class="col-2"><p class="bold">PRECIO KM EXTRA</p></div>
    </div>
    <div class="row mb-2">
      <div class="col-6"><p></p></div>
      <div class="col-2"><p>12/12/2024</p></div>
      <div class="col-2"><p>15:00</p></div>
      <div class="col-2"><p>$100.00</p></div>
    </div>
    <div class="row mb-1">
      <div class="col-6"><p class="bold d-flex align-items-end">TARJETA DE CREDITO No. (CREDIT CARD No.)</p></div>
      <div class="col-2"><p class="bold">FECHA ENTRADA<br> (DATE IN)</p></div>
      <div class="col-2"><p class="bold">TIEMPO ENTRADA<br> (TIME IN)/HR</p></div>
      <div class="col-2"><p class="bold">PRECIO DE HORA EXTRA</p></div>
    </div>
    <div class="row mb-2">
      <div class="col-6"><p>25652145665845566</p></div>
      <div class="col-2"><p>18/12/2024</p></div>
      <div class="col-2"><p>16:00</p></div>
      <div class="col-2"><p>$50.00</p></div>
    </div>
    <div class="row mb-2">
      <div class="col-6">
        <div class="row mb-1">
          <div class="col-6"><p class="bold">VENCE (EXPIRE)</p></div>
          <div class="col-6"><p class="bold">AUTORIZACION<br>(AUTHORIZATION)</p></div>
        </div>
        <div class="row mb-2">
          <div class="col-6"><p>12/12/2024</p></div>
          <div class="col-6"><p>2564</p></div>
        </div>
        <div class="row mb-1">
          <div class="col-6"><p class="bold">LICENCIA (LICENCE)</p></div>
          <div class="col-3"><p class="bold">VENCE<br>(EXPIRE)</p></div>
          <div class="col-3"><p class="bold">ESTADO<br>(STATE)</p></div>
        </div>
        <div class="row mb-2">
          <div class="col-6"><p>C54666464646</p></div>
          <div class="col-3"><p>16/12/2024</p></div>
          <div class="col-3"><p>Baja California</p></div>
        </div>
        <div class="row mb-1">
          <div class="col-6"><p class="bold">TELÉFONO DE CONTACTO<br>
            (CONTACT PHONE)</p></div>
          <div class="col-6"><p class="bold">TELÉFONO DE EMERGENCIA<br> (EMERGENCY PHONE)</p></div>
        </div>
        <div class="row mb-2">
          <div class="col-6"><p>+66 558 69852</p></div>
          <div class="col-6"><p>+66 546 89641</p></div>
        </div>
        <div class="row mb-1">
          <div class="col-9"><p class="bold">OTRO CONDUCTOR ADICIONAL (ADITIONAL DRIVER)</p></div>
          <div class="col-3"><p class="bold">EDAD (AGE)</p></div>
        </div>
        <div class="row mb-2">
          <div class="col-9"><p>Jose Rodriguez</p></div>
          <div class="col-3"><p>30</p></div>
        </div>
        <div class="row mb-1">
          <div class="col-6"><p class="bold">LICENCIA (LICENCE)</p></div>
          <div class="col-3"><p class="bold">VENCE<br>(EXPIRE)</p></div>
          <div class="col-3"><p class="bold">ESTADO<br>(STATE)</p></div>
        </div>
        <div class="row mb-2">
          <div class="col-6"><p>C54666464646</p></div>
          <div class="col-3"><p>16/12/2024</p></div>
          <div class="col-3"><p>Baja California</p></div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <p class="text-center bold mb-2">TIPO DE SEGURO CONTRATADO: TOTAL</p>
            <p>► Cobertura contra daños al vehículo con 0% de deducible</p>
            <p>► Cobertura contra robo total con 0% de deducible</p>
            <p>► Todos los impuestos y tasas</p>
            <p>► Cancelación sin costo 5 días antes de tu renta</p>
            <p>► Conductores adicionales</p>
            <p>► Sin cargo por edad mínima</p>
            <p>► Asistencia en el camino</p>
            <p>► Seguro médico para pasajeros</p>
            <p>► Extensión de daños a terceros hasta $5,000,000 mxn</p>
            <p>► Kilometraje ilimitado</p>
          </div>
        </div>
      </div>
      <div class="col-6">
        <p class="bold mb-4 mt-1">TARIFAS</p>
        <p class="mb-2"><span class="bold">TARIFA DIARIA:</span> $ 0.00 MXN</p>
        <p class="mb-2"><span class="bold">EXTRAS:</span> $ 0.00 MXN</p>
        <p class="mb-2"><span class="bold">DIAS CONTRATADOS:</span> 0</p>
        <p class="mb-2"><span class="bold">SEGURO CONTRATADO:</span> TOTAL</p>
        <p class="mb-2"><span class="bold">TARIFA DE SEGURO RC USA:</span> $ 0.00 MXN </p>
        <p class="mb-2"><span class="bold">DIAS CONTRATADOS DE RC USA:</span> 0</p>
        <p class="mb-2"><span class="bold">TOTAL DE RENTA PAGADO:</span> $ 0.00 MXN</p>
        <p class="mb-2"><span class="bold">DEPOSITO EN EFECTIVO:</span> $ 0.00 MXN</p>
        <p class="mb-4"><span class="bold">CHECK IN CON TARJETA:</span> $ 0.00 MXN</p>
        <div class="row mb-2">
          <div class="col-3"></div>
          <div class="col-6 border-top border-dark">
            <p>ACEPTO TÉRMINOS Y CONDICIONES</p>
          </div>
          <div class="col-3"></div>
        </div>
        <p class="text-center bold mb-2">RECONOCIMIENTO DE RESPONSABILIDAD Y AUTORIZACION EN EL CASO DE PAGO POR
          TARJETA.</p>
        <p class="text-justify mb-3" style="line-height: 15px">POR MEDIO DE LA PRESENTE AUTORIZO A <span
          class="bold">TRANSPORTES DE CALIDAD Y CONFORT S. DEL R.L.</span> DE
          C.V. PARA QUE DENTRO DE LAS CONDICIONES QUE ESTABLECE ESTE CONTRATO CARGUEN A MI TARJETA DE CRÉDITO CON
          No. _______________________________________________________________ CON FECHA DE VENCIMIENTO:
          ____________________ LAS CANTIDADES POR CONCEPTO DE RENTA DIARIA Y/O DAÑOS AL VEHÍCULO SEAN
          ORIGINADOS</p>
        <div class="row mb-2">
          <div class="col-3"></div>
          <div class="col-6 border-top border-dark">
            <p>ACEPTO Y AUTORIZO LOS CARGOS</p>
          </div>
          <div class="col-3"></div>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <p class="text-center bold mb-3">PAGARÉ</p>
        <p class="text-justify">por este pagare me(nos) obligo(amos) a pagar incondicionalmente a
          __________________________ en cualquier parte que me requiera la cantidad de $_________________,
          (______________________________________) en la ciudad de ___________________________ a
          _________________________ de 20____.</p>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-3 border-top border-dark">
        <p class="text-center">ACEPTO TÉRMINOS Y CONDICIONES</p>
      </div>
      <div class="col-6">
      </div>
      <div class="col-3 border-top border-dark">
        <p class="text-center">ACEPTO TÉRMINOS Y CONDICIONES</p>
      </div>
    </div>

    <div class="row cuerpo">
      <div class="col-4">
        <div class="d-flex justify-content-center align-items-center mb-2">
          <img src="@/assets/img/issyrentallogo.png"
               style="width: 150px; height: 75px">
        </div>
        <p class="text-center bold mb-1">R.F.C. TCC160706BL7</p>
        <p class="text-center bold mb-1">Calle 19 #303 A Fracc. Las Californias Tijuana BC CP 22404 Tel: 664 524
          2099</p>
        <p class="text-center bold mb-3">Expediente PROFECO PFC.B.E 7/007713-2017</p>
        <p class="text-justify">Contrato de arredramiento de vehículos que celebran por una parte el proveedor
          TRANSPORTES DE CALIDAD Y CONFORT S DE RL DE CV representado en este acto por el C. y en contraparte el
          consumidor, mismos que por
          medio del presente manifiestan su voluntad para obligarse de acuerdo al siguiente glosario, así como a
          las declaraciones y cláusulas que a continuación se describen:</p>
        <p>GLOSARIO</p>
        <p class="text-justify">a. Consumidor: Persona física que obtiene en
          arrendamiento el uso y goce temporal del vehículo objeto de este contrato quien para efectos de este
          contrato recibirá el nombre de: arrendatario.
        </p>
        <p class="text-justify">
          b. Proveedor: Persona física o moral que ofrece en
          arrendamiento, el uso y goce temporal de bienes muebles a cambio de una contraprestación cierta y
          determinada, quien para efectos de este contrato recibirá el nombre de: arrendador.

        </p>
        <p class="text-justify">
          c. Vehículo: Aquel bien mueble que es objeto material de este contrato, mismo que se encuentra descrito
          en el anexo de este contrato.
        </p>
        <p>DECLARACIONES</p>
        <p>PRIMERA. DECLARA EL ARRENDADOR:</p>
        <p class="text-justify">► Ser una persona mexicana, constituida conforme la legislación nacional aplicable,
          según consta en la escritura pública número 59,242, del cuatro de Julio del año dos mil diez y seis,
          exhibida ante la fe del Lic. Enrique Gallaga Esparza, Notario Publico numero quince, en la ciudad de
          Tijuana en el Estado de Baja California, e inscrita en el registro público del comercio de Tijuana Baja
          California bajo el número 201600046763 de fecha 29/09/2016.</p>
        <p class="text-justify">► Que acredita con su representante legal para todos los efectos de este contrato, a
          la persona física determinada en el preludio del presente, quien valida su personalidad jurídica
          mediante el
          testimonio notarial número 59242 de fecha 04 de Julio de 2016, exhibida ante la fe del(a) Lic. Enrique
          Gallaga Esparza, notario público número 15, en el estado de Baja California.
        </p>
        <p class="text-justify">► Que su registro Federal de Contribuyentes corresponde al número: TCC160706BL7.</p>
        <p class="text-justify">► Que pone a disposición del consumidor como línea de contacto para cualquier asunto
          relacionado con este contrato de número telefónico Tel... (664) 524 2099 en los siguientes horarios de
          atención: 8:00 AM a 20:00 PM y la dirección de correo electrónico siguiente: ventas@issyrental.com</p>
        <p class="text-justify">► Que cuenta con los recursos humanos, financieros y materiales para llevar a cabo
          las
          obligaciones emanadas de este acto jurídico.</p>
        <p class="text-justify">► Que dentro de sus actividades se encuentra la de otorgar en arrendamiento
          vehículos.</p>
        <p class="text-justify">► Que cuenta con las licencias y permisos requeridos por la ley para prestar el
          servicio correspondiente,</p>
        <p class="text-justify">► Que informó al arrendatario los alcances y efectos jurídicos del presente
          contrato.</p>
        <p>SEGUNDA. DECLARA EL ARRENDATARIO:</p>
        <p class="text-justify">a. Llamarse según lo notado en el preludio de este contrato, así mismo como contar
          con la capacidad legal para cumplir con las obligaciones contenidas en este instrumento contractual.</p>
        <p class="text-justify">b. Que cuenta con la capacidad legal, en términos de las leyes aplicables, para
          obligarse bajo los términos y condicionescontenidos en este contrato. </p>
        <p class="text-justify">c. Que en su deseo contratar el arrendamiento objeto de este en los términos y
          condiciones que se establecen en este documento.</p>
        <p class="text-justify">d. Que sus generales corresponden a las anotadas en el anexo del contrato</p>
      </div>
      <div class="col-4">
        <p>CLAUSULAS</p>
        <p class="text-justify">PRIMERA. Consentimiento. Por medio del presente contrato, el arrendador se obliga a
          conceder el uso y goce temporal del vehículo, por lo que el arrendatario deberá pagar un precio cierto y
          determinado. </p>
        <p class="text-justify">SEGUNDA. Objeto. El objeto material de este contrato es el vehículo que se encuentra
          descrito en el anexo de este contrato. Por lo que las características, condiciones, refacciones y
          documentos generales del vehículo arrendado se encuentran detalladas en el documento mencionado. </p>
        <p class="text-justify">TERCERA. Condiciones del vehículo arrendado. El arrendatario recibe de conformidad
          el vehículo arrendado, el cual se encuentra en óptimas condiciones mecánicas y de carrocería, las cuales
          se mencionan en el inventario respectivo. Acordando las partes que el vehículo se entregue con el
          kilometraje sellado, el uso y goce del vehículo se destinará exclusivamente al fin pactado con los
          contratantes. En caso de que el uso y goce esté destinado únicamente al transporte del arrendatario y
          sus acompañantes deberá señalarse por escrito para la debida información de las partes. Asimismo, el
          arrendatario recibe, exceptuando los vicios ocultos, el vehículo a su entera satisfacción por lo que
          obliga a pagar al arrendador a la terminación del contrato, a precios del mercado, el o los faltantes de
          accesorios y partes del vehículo arrendado al momento de la entrega del mismo. </p>
        <p class="text-justify">CUARTA. Lugar de entrega y recepción del vehículo. El arrendador deberá entregar el
          vehículo arrendado en el lugar previamente acordado entre las partes, respetando en la entrega el mismo
          día y hora señalada en el anexo del presente contrato. El arrendatario, al término de la vigencia del
          presente documento, deberá entregar el vehículo, en las mismas condiciones en que lo recibió exceptuando
          el desgaste por el uso, en el día y las horas señaladas para tal efecto obligándose a entregar el
          vehículo al arrendador en el lugar determinado por las partes para tal efecto. </p>
        <p class="text-justify">QUINTA. Plazo del arrendamiento. La vigencia de este contrato será la señalada en el
          anexo del presente documento, la cual no podrá ser prorrogada sino con el pleno consentimiento de ambas
          partes expresando en un nuevo contrato de arrendamiento. </p>
        <p class="text-justify">
          SEXTA. Precio del arrendamiento. El arrendamiento por el uso y goce temporal del vehículo arrendado,
          deberá pagar una cantidad cierta y determinada en moneda nacional sin perjuicio que las partes puedan
          acordar el pago en moneda extranjera conforme a las leyes aplicables la cual se encuentra enunciada en
          el anexo de este contrato.
        </p>
        <p class="text-justify">SÉPTIMA. Modalidades de pago. El arrendatario podrá pagar la renta del vehículo al
          contado en el domicilio del arrendador, con la tarjeta bancaria, transferencia electrónica o cualquier
          otra forma de pago que acuerden las partes. El precio total del arrendamiento se calculará tomando en
          cuenta el costo por renta diario o por kilometraje, de acuerdo a lo solicitado por el consumidor. La
          renta empezará a computarse desde el momento en que el consumidor se encuentre en plena disposición del
          vehículo arrendado y hasta la fecha en que lo reciba el arrendador a su entera satisfacción. En caso de
          que la arrendataria hubiere contratado el arrendamiento del vehículo por kilometraje recorridos, estos
          determinarán por la lectura del kilometraje, registrado en el dispositivo instalado de fábrica en el
          vehículo (odómetro), estipulando las partes que sí, durante el término del arrendamiento, sobreviene
          algún desperfecto o la rotura de los protectores de dicho sistema, por culpa o negligencia del
          arrendatario, la renta se calculará tomando en cuenta la tarifa de renta por día que se establece en el
          anexo de este contrato, durante el tiempo en que el vehículo esté en posesión del arrendamiento. </p>
        <p class="text-justify">OCTAVA. Depósito en garantía. El arrendatario se obliga a entregar al arrendador la
          cantidad señalada en el anexo de este contrato como el depósito en garantía del cumplimiento de la
          obligación principal del pago. En consecuencia, el arrendador deberá expedir un recibo por dicha
          cantidad en que conste: el nombre o razón social de la misma, fecha e importe del depósito, nombre y
          firma de la persona que lo recibe. Este recibo servirá de comprobante de canje para que al término del
          contrato el arrendador entregue la cantidad depositada dentro de las 24 horas siguientes a la recepción
          del vehículo de conformidad, en caso contrario dicho depósito se aplicará a solventar los saldos su los
          hubiere o a pagar las reposiciones de faltantes y la reparación de desperfectos, cuando hayan sido
          debidamente acreditados por el arrendador, en la inteligencia que la arrendadora podrá exigir, judicial
          o extrajudicialmente el pago de una cantidad adicional si el depósito fuere insuficiente para cubrir la
          reposición y la reparación de saldos. </p>

      </div>
      <div class="col-4">
        <p class="text-justify">NOVENA. Devolución del vehículo. El arrendatario se obliga a devolver al término de
          la vigencia del presente contrato el vehículo arrendado en las mismas condiciones en que lo recibió
          exceptuando el desgaste proveniente del uso normal del vehículo durante al arrendamiento. Las partes
          acuerdan en que la entrega del vehículo arrendado se lleve a cabo en fecha, lugar y hora determinados en
          el anexo de este contrato.</p>
        <p class="text-justify">En caso de que el vehículo no sea entregado en los términos señalados, la
          arrendataria podrá entregarlo posteriormente, previo acuerdo de las partes, pagando por este retraso el
          importe de la renta conforme a la tarifa acordada por el tiempo que tarda en entregar el vehículo en la
          fecha y hora determinados; si el retraso en la entrega del vehículo corresponde a la hora, el
          arrendatario sólo estará obligado a pagar la parte proporcional del incumplimiento. </p>
        <p class="text-justify">DÉCIMA. Prohibición al vehículo arrendado de salir de la república. Sin el previo
          consentimiento por escrito del arrendador, el vehículo arrendado no podrá salir de los límites de la
          República Mexicana: en caso de incumplimiento a lo anterior señalado, el arrendador podrá dar por
          rescindido este contrato, recuperando el vehículo en las condiciones y estados en que se localiza,
          siendo responsable la arrendataria del pago de la pena convencional correspondiente, más de los gastos
          de recuperación del vehículo debidamente comprobados por el arrendador. </p>
        <p class="text-justify">DÉCIMA PRIMERA. Derechos y obligaciones de las partes. Los contratantes se reconocen
          como derechos exigibles, el cumplimiento de todas las disposiciones del presente contrato, normando su
          consentimiento por la observancia de las siguientes obligaciones: En el cumplimiento del presente
          contrato el arrendador se obliga a: </p>
        <p class="text-justify">a. Entregar el vehículo arrendado en óptimas condiciones de uso, considerando el
          combustible necesario para tal efecto; el día, hora y lugar acordado por las partes. </p>
        <p class="text-justify">b. Recibir el vehículo sin ninguna condicionante de pago por el límite de gasolina,
          en la inteligencia que el vehículo por ninguna circunstancia deberá ser entregado por el arrendatario
          con un nivel menor a un cuarto de tanque. </p>
        <p class="text-justify">c. Al recibir el vehículo arrendado, señalando al arrendatario de ser el caso que el
          vehículo lo recibe en su entera satisfacción; de lo contrario deberá manifestar en el acto de recepción
          los motivos de su proceder. </p>
        <p class="text-justify">d. Devolver al arrendatario en el tiempo estipulado para tal efecto la cantidad
          otorgada en depósito en garantía. </p>
        <p class="text-justify">Para los efectos de este contrato, son obligaciones del arrendatario: </p>
        <p class="text-justify">a. Pagar al arrendador la renta convenida del vehículo arrendado de manera puntual,
          sin requerimiento de pago y en las condiciones establecidas en el presente contrato.</p>
        <p class="text-justify">b. Conducir en todo momento el vehículo arrendado bajo el amparo de la licencia
          respectiva, otorgada por las autoridades competentes; respetando los Reglamentos y Leyes de Tránsito en
          el ámbito Federal, Local o Municipal. </p>
        <p class="text-justify">c. No manejar el vehículo en estado de ebriedad o bajo la influencia de drogas. </p>
        <p class="text-justify">d. No hacer uso del vehículo en forma lucrativa, ni subarrendar. </p>
        <p class="text-justify">e. No utilizar el vehículo arrendado para arrastrar remolques y no sobrecargarlo
          debiéndose usar conforme a su resistencia y capacidad normal. </p>
        <p class="text-justify">f. Conservar el vehículo en el estado que lo recibió exceptuando el desgaste normal
          de uso. </p>
        <p class="text-justify">g. No conducir en el interior del vehículo materias explosivas o inflamables, drogas
          o estupefacientes. </p>
        <p class="text-justify">h. Pagar el importe de las sanciones que le fueran impuestas por violación a los
          Reglamentos de Transito, aun después de concluida la vigencia del contrato, si la infracción se origino
          durante el tiempo en que estuvo el vehículo arrendado a disposición del arrendatario. </p>
        <p class="text-justify">i. No utilizar el vehículo de manera diferente a lo pactado. </p>
        <p class="text-justify">j. No subarrendar a terceros el vehículo objeto del presente contrato sin previo
          consentimiento del arrendador.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contrato"
};
</script>

<style scoped>

</style>
