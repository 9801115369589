<template>
  <div>
    <table class="" id="miTabla" style="table-layout: fixed; width: 100%">
      <thead>
      <tr class="text-center">
        <th class="text-sm">No. Contrato y Sucursal</th>
        <th class="text-sm">Agente</th>
        <th class="text-sm">Fecha de salida y Fecha de regreso</th>
        <th class="text-sm">Cliente</th>
        <th class="text-sm">Categoría</th>
        <th class="text-sm">Auto</th>
        <th class="text-sm">Acciones</th>
      </tr>
      </thead>
      <tbody>
      <tr class="text-center align-middle" v-for="item in contratosWithExtras" :key="item.id">
        <td class="text-sm" style="word-break: break-word;">{{ item.numContrato }} - {{ item.sucursal.name }}
        </td>
        <td class="text-sm" style="word-break: break-word;">
          {{ item.agente.firstName }} {{ item.agente.lastName }}
        </td>
        <td class="text-sm" style="word-break: break-word;">{{ formatearFecha(item.fechaSalida) }} -
          {{ formatearFecha(item.fechaRegreso) }}
        </td>
        <td class="text-sm" style="word-break: break-word;">
          {{ item.cliente.nombre }} {{ item.cliente.apellidos }}
        </td>
        <td class="text-sm" style="word-break: break-word;">
          {{ item.vehiculo.categoria.name }}
        </td>
        <td class="text-sm" style="word-break: break-word;">
          {{ item.vehiculo.version.modelo.marca.name }} - {{ item.vehiculo.version.modelo.name }}
          {{ item.vehiculo.noEcon }}
        </td>
        <td>
          <div class="btn-group pt-3" role="group">
            <button @click="openCar(item)" data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Detalles del contrato" data-container="body" data-animation="true"
                    class="btn btn-success p-1 ms-1">
              <i class="material-icons opacity-10">visibility</i></button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="divModal Detalles">
    <div v-if="showCar" :class="['modal', { 'show': showCar }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="h-100">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles del contrato<i @click="closeCar"
                                                                                          class="material-icons-round opacity-10 modal-icon">close</i>
            </h4>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <h6 class="text-bold">Información del cliente</h6>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Nombre y apellidos:</strong>
                <p>{{ item.cliente.nombre }} {{ item.cliente.apellidos }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Correo:</strong>
                <p>{{ item.cliente.email }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Teléfono:</strong>
                <p>{{ item.cliente.telefono }}</p>
              </div>
              <h6 class="text-bold">Condiciones de renta</h6>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>No. Folio:</strong>
                <p>{{ item.numContrato }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Fecha de Contrato:</strong>
                <p>{{ formatearFecha(item.fecha) }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Estado del contrato:</strong>
                <p>{{ item.estado }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Sucursal:</strong>
                <p>{{ item.sucursal.name }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Canal:</strong>
                <p>{{ item.canal === "portal" ? "Portal" : "Administración" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Agente:</strong>
                <p>{{ item.agente.firstName }} {{ item.agente.lastName }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Fecha de salida:</strong>
                <p>{{ formatearFecha(item.fechaSalida) }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Fecha de regreso:</strong>
                <p>{{ formatearFecha(item.fechaRegreso) }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Días reservados:</strong>
                <p>{{ item.dias }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Crédito otorgado:</strong>
                <p>{{ item.credito ? "Si" : "No" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-8">
                <strong>Servicios extras:</strong>
                <p v-for="item in servicios_ex" :key="item.id">{{ item.servicio.name }}</p>
              </div>
              <h6 class="text-bold">Información del auto</h6>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Categoría:</strong>
                <p>{{ item.vehiculo.categoria.name }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Marca:</strong>
                <p>{{ item.vehiculo.version.modelo.marca.name }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Modelo:</strong>
                <p>{{ item.vehiculo.version.modelo.name }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Color:</strong>
                <p>{{ item.vehiculo.color }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Placa:</strong>
                <p>{{ item.vehiculo.placa }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Capacidad del tanque:</strong>
                <p>{{ item.vehiculo.capacidadTanke }} lts</p>
              </div>
              <h6 class="text-bold">Tarifas</h6>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Tarifa diaria:</strong>
                <p>$ {{ numeroFormateado(item.tarifaDiaria) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Tarifa de horas extras</strong>
                <p>$ {{ numeroFormateado(item.precioHoraExtra) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Servicios extras:</strong>
                <p>$ {{ numeroFormateado(item.tarifaExtra) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Total:</strong>
                <p>$ {{ numeroFormateado(item.total) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Método de pago:</strong>
                <p>{{ item.tipoPago === "tarjeta" ? "Tarjeta" : "Efectivo" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Check in:</strong>
                <p>$ {{ numeroFormateado(item.checkin) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>No. tarjeta:</strong>
                <p>{{ item.tarjeta ? item.tarjeta.numero : "" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>No. autorización:</strong>
                <p>{{ item.tarjeta ? item.tarjeta.autorizacion : "" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Fecha de vencimiento:</strong>
                <p>{{ item.tarjeta ? formatearFecha(item.tarjeta.vencimiento) : "" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong v-if="item.tipoPago==='efectivo'">Pagado en efectivo:</strong>
                <strong v-else>Pagado en tarjeta:</strong>
                <p>$ {{ numeroFormateado(item.depositoEfectivo) }} MXN</p>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button @click="closeCar" class="mt-3 btn btn-dark text-end">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import gql from "graphql-tag";
import moment from "moment/moment";

const BUSCAR = gql`
query ContratosWithExtras($name:String!) {
    contratosWithExtras(name: $name) {
         id
        estado
        tipoCobertura
        seguroRc
        revisado
        tipoPago
        fecha
        precioHoraExtra
        numContrato
        fechaSalida
        fechaRegreso
        tarifaDiaria
        tarifaExtra
        dias
        tanque
        total
        depositoEfectivo
        checkin
        credito
        comentarios
        canal
        agente {
            id
            username
            firstName
            lastName
            email
        }
        cliente {
            id
            nombre
            apellidos
            direccion
            telefonoEmergencia
            telefono
            email
            edad
            licencia {
                numero
                vencimiento
                estado
            }
        }
        vehiculo {
            id
            placa
            noEcon
            color
            capacidadTanke
            version{
              name
              modelo{
                name
                marca{
                  name
                }
              }
            }
            categoria {
                id
                name
                tradicionalAlta
                totalAlta
                tradicionalBaja
                totalBaja
            }
        }
        sucursal {
            id
            name
        }
        conductor {
            id
            nombre
            apellidos
            edad
            licencia {
                id
                numero
                vencimiento
                permanente
                estado
            }
            cliente {
                id
                nombre
                apellidos
                telefono
                email
                edad
                isActive
                direccion
                postal
                telefonoEmergencia
            }
        }
        tarjeta {
            id
            numero
            vencimiento
            autorizacion
        }
    }
}
`;
export default {
  name: "Contratos_tanque_lleno",
  data() {
    return {
      contratosWithExtras: [],
      item: null,
      showCar: false
    };
  },
  props: {
    search: {
      type: String,
      required: true
    }
  },
  apollo: {
    contratosWithExtras: {
      query: BUSCAR,
      variables() {
        return {
          name: this.search
        };
      },
      fetchPolicy: "cache-and-network"
    }
  },
  watch: {
    contratosWithExtras() {
      this.$store.state.Cargando = false;
    }
  },
  methods: {
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("DD/MM/YYYY HH:mm");
    },
    async openCar(item) {
      this.item = item;
      this.showCar = true;
      document.body.classList.add("modal-open");
    },
    closeCar() {
      this.showCar = false;
      this.item = null;
      document.body.classList.remove("modal-open");
    },
    numeroFormateado(numero) {
      const num = parseFloat(numero);
      return num.toLocaleString("en-US", { minimumFractionDigits: 2 });
    },
  }
};

</script>
<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.input-icon:focus {
  padding-left: 38px;
}

.input-icon:hover {
  padding-left: 38px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  margin-top: 250px;
  border-radius: 15px;
  width: 80%;
}

.modal-content-contrato {
  background-color: white;
  padding: 20px;
  margin-top: 2500px;
  border-radius: 15px;
  width: 90%;
}

.modal-content1 {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 30%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

@media (max-width: 990px) {
  .modal-content {
    margin-top: 500px;
    width: 90%;
  }
}

@media (max-width: 766px) {
  .modal-content {
    margin-top: 1200px;
    width: 90%;
  }
}

@media (max-width: 550px) {
  .modal-content {
    background-color: white;
    padding: 20px;
    margin-top: 1250px;
    border-radius: 15px;
    width: 90%;
  }
}
</style>
