<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de Roles
            </h5>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar Rol" aria-label="Recipient's username" aria-describedby="button-addon2">
              </div>
            </div>
            <div class="col-9 text-end">
              <button @click="openModal" class="btn btn-dark align-content-end" type="submit">Nuevo Rol</button>
            </div>
          </div>
          <div class="table-responsive">
            <table id="order-list" class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Rol</th>
                <th>Permisos</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in groupPerName" :key="item.id" class="text-center align-middle">
                <td>
                  {{ item.name }}
                </td>
                <td>
                  <p class="m-0" v-for="(perm, index) in item.permission" :key="index">{{ perm.description }}</p>
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button class="btn btn-info p-1 ms-1">
                      <i @click="openUpdate(item.name, item.id)" class="material-icons opacity-10">edit</i>
                    </button>
                    <button @click="deleteGroup(item.id)" class="btn btn-danger p-1 ms-1">
                      <i class="material-icons opacity-10">delete</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="divModal">
      <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
        <div class="modal-content">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nuevo Rol<i @click="closeModal"
                                                                                     class="material-icons-round opacity-10 modal-icon">close</i>
            </h4>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <label class="form-label">Nombre</label>
              <input v-model="name" class="form-control border-bottom border-dark mb-3 p-2"
                     placeholder="Escriba el nombre del Rol" type="text">
            </div>
          </div>
          <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar el nombre</p></div>
          </div>
          <div class="row">
            <div class="col-6 d-flex justify-content-start">
              <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <button @click="addRol" class="btn btn-dark" type="button">Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="divModal">
      <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
        <div class="modal-content">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-pencil-square me-2"></i>Editar Rol<i @click="closeUpdate"
                                                                                        class="material-icons-round opacity-10 modal-icon">close</i>
            </h4>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <label class="form-label">Nombre</label>
              <input v-model="name" class="form-control border-bottom border-dark p-2 mb-3" type="text">
            </div>
          </div>
          <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar el nombre</p></div>
          </div>
          <div class="row">
            <div class="col-6 d-flex justify-content-start">
              <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <button @click="updateGroup" class="btn btn-dark" type="button">Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";

const ADD_ROL = gql`
mutation($name:String!){
  createGroup(name:$name){
    success
    group{
      name
    }
  }
}
`;
const DELETE_ROL = gql`
mutation($id:ID!){
  deleteGroup(id:$id){
    success
    error
  }
}
`;
const UPDATE_ROL = gql`
mutation($id:ID!, $name:String!){
  updateGroup(id: $id, name:$name){
    success
  }
}
`;
const BUSCAR_ROL = gql`
query($name:String!){
  groupPerName(name:$name){
    id
    name
    permission{
      id
      name
      description
    }
  }
}
`;
export default {
  name: "RolesPermissions",
  components: { MaterialButton },
  data() {
    return {
      misearch: "",
      groupPerName: [],
      showModal: false,
      error: false,
      UpdateModal: false,
      name: "",
      rolid: 0
    };
  },
  apollo: {
    groupPerName: {
      query: BUSCAR_ROL,
      variables() {
        return {
          name: this.misearch
        };
      },
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async addRol() {
      await this.$apollo
        .mutate({
          mutation: ADD_ROL,
          variables: {
            name: this.name
          },
          refetchQueries: [{ query: BUSCAR_ROL, variables: { name: this.misearch } }]
        }).then(response => {
          if (response.data.createGroup.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Rol creado"
            });
            this.closeModal();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.createGroup.error
            });
            this.closeModal();
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al crear el Rol"
          });
          this.closeModal();
          console.error("La mutación falló:", error);
        });
    },
    async deleteGroup(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE_ROL,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR_ROL, variables: { name: this.misearch } }]
          });
          if (data.deleteGroup.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });

            Toast.fire({
              icon: "success",
              title: "Rol eliminado"
            });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });

            Toast.fire({
              icon: "error",
              title: "Existen usuarios con este rol"
            });
          }
        }
      });
    },
    async updateGroup() {
      await this.$apollo.mutate({
        mutation: UPDATE_ROL,
        variables: {
          id: this.rolid,
          name: this.name
        },
        refetchQueries: [{ query: BUSCAR_ROL, variables: { name: this.misearch } }]
      }).then(response => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "success",
          title: "Rol Actualizado"
        });
        this.closeUpdate();
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "error",
          title: "Error al Actualizar el Rol"
        });
        this.closeUpdate();
      });
    },
    openModal() {
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    openUpdate(name, id) {
      this.name = name;
      this.rolid = id;
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.name = "";
      this.rolid = 0;
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    closeModal() {
      this.name = "";
      this.rolid = 0;
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
