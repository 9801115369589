import { createRouter, createWebHashHistory } from "vue-router";
import Default from "../views/Plantilla/Default.vue";
import Sales from "../views/dashboards/Sales.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Projects from "../views/pages/profile/Projects.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import Pricing from "../views/pages/Pricing.vue";
import RTL from "../views/pages/Rtl.vue";
import Notifications from "../views/pages/Notifications.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import List_Users from "../views/Seguridad/List_Users.vue";
import Calendar from "../views/applications/Calendar.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/products/EditProduct.vue";
import ProductPage from "../views/ecommerce/products/ProductPage.vue";
import OrderDetails from "../views/ecommerce/orders/OrderDetails";
import OrderList from "../views/ecommerce/orders/OrderList";
import NewUser from "../views/pages/users/NewUser.vue";
import Settings from "../views/Seguridad/Settings.vue";
import Billing from "../views/pages/account/Billing.vue";
import Invoice from "../views/pages/account/Invoice.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../components/Basic.vue";
import Login from "../views/Seguridad/Login.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetCover from "../views/Seguridad/ResetPassword.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import layout from "@/views/Plantilla/layout.vue";
import RolesPermissions from "@/views/Seguridad/List_Roles.vue";
import Permisos from "@/views/Seguridad/List_Permisos.vue";
import RolesPermisos from "@/views/Seguridad/List_RolesPermisos.vue";
import List_Sucursales from "@/views/Sucursal/List_Sucursales.vue";
import List_Ciudad from "@/views/Sucursal/List_Ciudad.vue";
import List_Estado from "@/views/Sucursal/List_Estado.vue";
import List_Marca from "@/views/Vehiculos/List_Marca.vue";
import List_Modelo from "@/views/Vehiculos/List_Modelo.vue";
import List_Version from "@/views/Vehiculos/List_Version.vue";
import List_Aseguradora from "@/views/Vehiculos/List_Aseguradora.vue";
import List_Titular from "@/views/Vehiculos/List_Titular.vue";
import List_Categoria from "@/views/Vehiculos/List_Categoria.vue";
import List_Vehiculos from "@/views/Vehiculos/List_Vehiculos.vue";
import new_car from "@/views/Vehiculos/new_car.vue";
import edit_car from "@/views/Vehiculos/edit_car.vue";
import FAQ from "@/views/Portal/FAQ.vue";
import List_Clientes from "@/views/Clientes/List_Clientes.vue";
import List_TipoCliente from "@/views/Clientes/List_TipoCliente.vue";
import Disponibilidad from "@/views/Vehiculos/Disponibilidad.vue";
import Rentas_Portal from "@/views/Rentas_Portal.vue";
import IVA from "@/views/Portal/IVA.vue";
import Gasolina from "@/views/Portal/Gasolina.vue";
import Comentarios from "@/views/Portal/Comentarios.vue";
import List_Contratos from "@/views/Contratos/List_Contratos_Cancelados.vue";
import New_Contrato from "@/views/Contratos/New_Contrato.vue";
import List_Temporada from "@/views/Configuracion/List_Temporadas.vue";
import List_Servicios from "@/views/Configuracion/List_Servicios.vue";
import Update_Contrato from "@/views/Contratos/Update_Contrato.vue";
import Rentas_Admin from "@/views/Rentas_Admin.vue";
import Disponibles from "@/views/Disponibilidad/Disponibles.vue";
import Reservados from "@/views/Disponibilidad/Reservados.vue";
import Contratados from "@/views/Disponibilidad/Contratados.vue";
import List_Cobertura from "@/views/Vehiculos/List_Cobertura.vue";
import Cotizacion from "@/views/Cotizacion.vue";
import Mantenimiento_Activo from "@/views/Mantenimiento/Mantenimiento_Activo.vue";
import Historial from "@/views/Mantenimiento/Historial.vue";
import store from "@/store";
import Sliders from "@/views/Portal/Sliders.vue";
import Information from "@/views/Portal/Information.vue";
import List_EntregaAutos from "@/views/EntregaAutos/List_EntregaAutos.vue";
import List_Contratos_Abiertos from "@/views/Contratos/List_Contratos_Abiertos.vue";
import List_Contratos_Vencidos from "@/views/Contratos/List_Contratos_Vencidos.vue";
import List_Contratos_Cerrados from "@/views/Contratos/List_Contratos_Cerrados.vue";
import List_Cuentas_por_cobrar from "@/views/Contratos/List_Cuentas_por_cobrar.vue";
import Listado_mantenimiento from "@/views/Mantenimiento/Listado_mantenimiento.vue";
import Reportes from "@/views/Reportes.vue";
import Notification from "@/views/Notification/Notification.vue";
import List_Cortesias from "@/views/Cortesias/List_Cortesias.vue";
import List_Contratos_Cortesia from "@/views/Cortesias/List_Contratos_Cortesia.vue";
import Rentas_NoShow from "@/views/Rentas_NoShow.vue";
import Rentas_Cancel from "@/views/Rentas_Cancel.vue";

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/home",
    name: "home",
    component: layout
  },
  {
    path: "/administracion/sucursales",
    name: "Sucursales",
    component: List_Sucursales
  },
  {
    path: "/administracion/aseguradoras",
    name: "Aseguradoras",
    component: List_Aseguradora
  },
  {
    path: "/administracion/titulares",
    name: "Titulares",
    component: List_Titular
  },
  {
    path: "/vehiculos/listado",
    name: "Vehículos",
    component: List_Vehiculos
  },
  {
    path: "/mantenimiento/listado",
    name: "Listados de vehículos para mantenimiento",
    component: Listado_mantenimiento
  },
  {
    path: "/administracion/IVA",
    name: "IVA",
    component: IVA
  },
  {
    path: "/reportes",
    name: "Reportes",
    component: Reportes
  },
  {
    path: "/portal/comentarios",
    name: "Comentarios",
    component: Comentarios
  },
  {
    path: "/contratos/cancelados",
    name: "Contratos cancelados",
    component: List_Contratos
  },
  {
    path: "/cuentas",
    name: "Cuentas por cobrar",
    component: List_Cuentas_por_cobrar
  },
  {
    path: "/contratos/abiertos",
    name: "Contratos abiertos",
    component: List_Contratos_Abiertos
  },
  {
    path: "/contratos/vencidos",
    name: "Contratos vencidos",
    component: List_Contratos_Vencidos
  },
  {
    path: "/contratos/cerrados",
    name: "Contratos cerrados",
    component: List_Contratos_Cerrados
  },
  {
    path: "/configuracion/temporadas",
    name: "Temporadas",
    component: List_Temporada
  },
  {
    path: "/configuracion/servicios",
    name: "Servicios Extras",
    component: List_Servicios
  },
  {
    path: "/contratos/nuevo",
    name: "Nuevo Contrato",
    component: New_Contrato
  },
  {
    path: "/administracion/gasolina",
    name: "Precio de la Gasolina",
    component: Gasolina
  },
  {
    path: "/administracion/categorias",
    name: "Categorías",
    component: List_Categoria
  },
  {
    path: "/administracion/ciudades",
    name: "Ciudades",
    component: List_Ciudad
  },
  {
    path: "/portal/faq",
    name: "FAQ",
    component: FAQ
  },
  {
    path: "/portal/sliders",
    name: "Sliders",
    component: Sliders
  },
  {
    path: "/portal/informacion",
    name: "Información",
    component: Information
  },
  {
    path: "/administracion/marcas",
    name: "Marcas",
    component: List_Marca
  },
  {
    path: "/administracion/modelos",
    name: "Modelos",
    component: List_Modelo
  },
  {
    path: "/contratos/editar",
    name: "Editar contrato",
    component: Update_Contrato
  },
  {
    path: "/administracion/versiones",
    name: "Versiones",
    component: List_Version
  },
  {
    path: "/adminstracion/estados",
    name: "Estados",
    component: List_Estado
  },
  {
    path: "/dashboards/dashboard-default",
    name: "Default",
    component: Default
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Sales
  },
  {
    path: "/entrega_autos",
    name: "Entrega de autos",
    component: List_EntregaAutos
  },
  {
    path: "/pages/profile/overview",
    name: "Profile Overview",
    component: Overview
  },
  {
    path: "/seguridad/roles",
    name: "Roles",
    component: RolesPermissions
  },
  {
    path: "/vehiculos/nuevo",
    name: "Nuevo Vehículo",
    component: new_car
  },
  {
    path: "/vehiculos/editar",
    name: "Editar Vehículo",
    component: edit_car
  },
  {
    path: "/seguridad/permisos",
    name: "Permisos",
    component: Permisos
  },
  {
    path: "/applications/wizard",
    name: "Wizard",
    component: Wizard
  },
  {
    path: "/seguridad/usuarios",
    name: "Usuarios",
    component: List_Users
  },
  {
    path: "/seguridad/gestionar_permisos",
    name: "Gestion de Permisos de Roles",
    component: RolesPermisos
  },
  {
    path: "/applications/calendar",
    name: "Calendar",
    component: Calendar
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct
  },
  {
    path: "/ecommerce/products/edit-product",
    name: "Edit Product",
    component: EditProduct
  },
  {
    path: "/ecommerce/products/product-page",
    name: "Product Page",
    component: ProductPage
  },
  {
    path: "/ecommerce/Orders/order-details",
    name: "Order Details",
    component: OrderDetails
  },
  {
    path: "/ecommerce/Orders/order-list",
    name: "Order List",
    component: OrderList
  },
  {
    path: "/pages/users/new-user",
    name: "New User",
    component: NewUser
  },
  {
    path: "/account/settings",
    name: "Perfil de Usuario",
    component: Settings
  },
  {
    path: "/pages/account/billing",
    name: "Billing",
    component: Billing
  },
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice
  },
  {
    path: "/authentication/signin/basic",
    name: "Signin Basic",
    component: Basic
  },
  {
    path: "/authentication/signin/illustration",
    name: "Signin Illustration",
    component: Illustration
  },
  {
    path: "/seguridad/restablecer_password",
    name: "Restablecer Contraseña",
    component: ResetCover
  },
  {
    path: "/clientes/listado",
    name: "Clientes",
    component: List_Clientes
  },
  {
    path: "/clientes/tipos",
    name: "Tipos de Clientes",
    component: List_TipoCliente
  },
  {
    path: "/vehiculos/disponibles",
    name: "Disponibles",
    component: Disponibles
  },
  {
    path: "/vehiculos/reservados",
    name: "Reservados",
    component: Reservados
  },
  {
    path: "/vehiculos/contratados",
    name: "Contratados",
    component: Contratados
  },
  {
    path: "/transacciones/administracion",
    name: "Reservas desde la Administración",
    component: Rentas_Admin
  },
  {
    path: "/transacciones/portal",
    name: "Reservas desde el Portal Web",
    component: Rentas_Portal
  },
  {
    path: "/transacciones/no_show",
    name: "Reservas No Show",
    component: Rentas_NoShow
  },
  {
    path: "/transacciones/canceladas",
    name: "Reservas canceladas",
    component: Rentas_Cancel
  },
  {
    path: "/cotizaciones",
    name: "Cotizaciones",
    component: Cotizacion
  },
  {
    path: "/mantenimiento/activos",
    name: "Mantenimientos activos",
    component: Mantenimiento_Activo
  },
  {
    path: "/mantenimiento/historial",
    name: "Historial de mantenimientos",
    component: Historial
  },
  {
    path: "/vehiculos/coberturas",
    name: "Coberturas",
    component: List_Cobertura
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover
  },
  {
    path: "/notifications",
    name: "Notificaciones",
    component: Notification
  },
  {
    path: "/cortesias",
    name: "Cortesías",
    component: List_Cortesias
  },
  {
    path: "/cortesias/entregadas",
    name: "Cortesías entregadas",
    component: List_Contratos_Cortesia
  }
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active"
});
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    store.commit("doLogout");
  }
  next();
});
export default router;
