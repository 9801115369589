<template>

  <main class="mt-0 main-content">
    <div
      class="page-header align-items-start min-vh-50 m-3 border-radius-lg"
      style="
        background-image: url('https://images.unsplash.com/photo-1497996541515-6549b145cd90?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
    </div>
    <section>
      <div class="container mb-4">
        <div class="row mt-lg-n12 mt-md-n12 mt-n12 justify-content-center">
          <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
            <div class="card mt-8">
              <div
                class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
              >
                <div style="background-color: #ff6900"
                     class="shadow-warning border-radius-lg py-3 pe-1 text-center py-4"
                >
                  <h3 class="font-weight-bolder text-white">Restablecer Contraseña</h3>
                  <p class="mb-0 text-sm text-white">
                    Usted recibirá un correo en un máximo de 60 segundos
                  </p>
                </div>
              </div>
              <div class="py-4 card-body">
                <div class="mb-3">
                  <input
                    v-model="email"
                    class="form-control"
                    id="email"
                    type="email"
                    placeholder="Email"
                    name="email"
                  />
                </div>
                <div class="text-center">
                  <material-button
                    type="button"
                    class="mt-4"
                    variant="gradient"
                    color="dark"
                    full-width
                    @click="reset"
                  >Restablecer
                  </material-button
                  >
                </div>
              </div>
              <div class="card-footer">
                <div class="px-1 pt-0 text-center card-footer px-lg-2 mt-3">
                  <p class="mx-auto mb-4 text-sm">
                    <router-link
                      :to="{ name: 'Login' }"
                      class="text-warning text-gradient font-weight-bold"
                    >Regresar al Inicio de Sesión
                    </router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";

const body = document.getElementsByTagName("body")[0];
import gql from "graphql-tag";
import Swal from "sweetalert2";

const RESET_PASSWORD = gql`
mutation RecuperarPassword ($email:String!) {
                  recuperarPassword(email: $email) {
                      success
                      errors
                  }
              }`;
export default {
  name: "ResetCover",
  components: {
    MaterialInput,
    MaterialButton
  },
  data() {
    return {
      email: ""
    };
  },
  methods: {
    async reset() {
      await this.$apollo.mutate({
          mutation: RESET_PASSWORD,
          variables: {
            email: this.email
          }
        }).then(response=>{
          if (response.data.recuperarPassword.success){
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Contraseña enviada"
            });
          }
          else
          {
            this.$swal({
              text: response.data.recuperarPassword.errors,
              icon: "error",
              customClass: {
                confirmButton: "bg-warning"
              }
            });
          }
        }).catch(error=>{
          this.$swal({
            text: error,
            icon: "error",
            customClass: {
              confirmButton: "bg-warning"
            }
          });
        })
    }
  }
};
</script>
