<template>
  <li class="nav-item active">
    <router-link class="nav-link" :to="to" id="nav-item-menu" :style="activo?'background-color: rgba(199, 199, 199, 0.2)':''">
      <img :src="miniIcon" style="height: 24px; width: 24px">
      <span class="sidenav-normal me-3 ms-1 ps-1 text-white">
        {{ text }}
      </span>
    </router-link>
  </li>
</template>
<script>
export default {
  name: "SidenavItem",
  props: {
    to: {
      type: [Object, String],
      required: true
    },
    miniIcon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    activo: {
      type: Boolean,
      required: true,
      default: false
    }
  }
};
</script>
<style scoped>
.fondo {
  background-color: rgba(199, 199, 199, 0.2);
  color: #fff;
}
</style>