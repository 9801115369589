<template>
  <div class="mb-4 card">
    <div class="pb-0 card-header">
      <h6>Agentes con más dinero generado</h6>
    </div>
    <div class="px-0 pt-0 pb-2 card-body">
      <div class="p-0 table-responsive">
        <table class="table mb-0 align-items-center">
          <thead>
          <tr>
            <th
              class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Sucursal
            </th>
            <th

              class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Nombre y apellidos
            </th>
            <th

              class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Total generado
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(categoria, index) in agentesByDinero" :key="categoria.id" v-show="index<elementos">
            <td class="text-start text-dark text-xxs font-weight-bolder opacity-7">
              <div class="ms-3">{{ categoria.sucursal.name }}</div>
            </td>
            <td class="text-start text-dark text-xxs font-weight-bolder opacity-7">
              <div class="ms-3">{{ categoria.firstName }} {{categoria.lastName}}</div>
            </td>
            <td class="text-start text-dark text-xxs font-weight-bolder opacity-7">
              <div class="ms-3">$ {{ numeroFormateado(montos[categoria.id]) }} MXN</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import gql from "graphql-tag";

const MONTO = gql`
query MontoByAgent($id:ID!) {
    montoByAgent(id: $id)
}`;
const AGENTES = gql`
query AgentesByDinero {
    agentesByDinero {
        id
        firstName
        lastName
        email
         sucursal {
            id
            name
         }
    }
}`;
export default {
  name: "OrdersListCard",
  components: {
    MaterialAvatar,
    MaterialButton
  },
  data() {
    return {
      agentesByDinero: [],
      montos: [],
      elementos: 3
    };
  },
  apollo: {
    agentesByDinero: {
      query: AGENTES,
      fetchPolicy: "cache-and-network"
    }
  },
  watch: {
    agentesByDinero(value) {
      value.forEach(item => {
        this.$apollo.query({
          query: MONTO,
          variables: {
            id: item.id
          }
        }).then(response => {
          this.montos[item.id] = response.data.montoByAgent;
        });
      });
    }
  },
  methods: {
    numeroFormateado(numero) {
      const num = parseFloat(numero);
      return num.toLocaleString("en-US", { minimumFractionDigits: 2 });
    },
  }
};
</script>

<style scoped>
.img-categoria {
  height: 60px;
  width: 90px;
  object-fit: cover;
}
</style>
