<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de Versiones
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control border-bottom input-icon"
                       placeholder="Buscar versión" aria-label="Recipient's username" aria-describedby="button-addon2">
              </div>
            </div>
            <div class="col-9 text-end">
              <button @click="openModal" class="btn btn-dark align-content-end" type="submit">Nueva Versión</button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Versión</th>
                <th>Modelo</th>
                <th>Marca</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="version in versionPerName" :key="version.id">
                <td class="text-sm font-weight-normal" :class="version.isActive?'':'text-danger'">
                  {{ version.name }}
                </td>
                <td class="text-sm font-weight-normal" :class="version.isActive?'':'text-danger'">
                  {{ version.modelo ? version.modelo.name : "Sin Modelo" }}
                </td>
                <td class="text-sm font-weight-normal" :class="version.isActive?'':'text-danger'">
                  {{ version.modelo ? version.modelo.marca.name : "Sin Marca" }}
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button v-if="version.isActive" @click="DesactiveVersion(version.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Desactivar Version" data-container="body"
                            data-animation="true" class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">block</i></button>
                    <button v-else @click="ActiveVersion(version.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Activar Version" data-container="body" data-animation="true"
                            class="btn btn-warning p-1 ms-1">
                      <i class="material-icons opacity-10">check_circle</i></button>
                    <button @click="openUpdate(version.name, version.modelo?version.modelo.marca.id:0,version.modelo?version.modelo.id:0, version.id)"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Editar Version" data-container="body" data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                    <button @click="DeleteVersion(version.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Eliminar Version" data-container="body" data-animation="true"
                            class="btn btn-danger p-1 ms-1">
                      <i class="material-icons opacity-10">delete</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nueva Versión<i @click="closeModal"
                                                                                       class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Nombre</label>
            <input v-model="name" class="form-control border-bottom border-dark mb-3 p-2" type="text">
            <label class="form-label">Marca</label>
            <select v-model="marcaid" class="form-select mb-3 p-2">
              <option selected disabled value="0">Seleccione una Marca</option>
              <option v-for="marca in allMarcas" :value="marca.id" v-show="marca.isActive">{{ marca.name }}
              </option>
            </select>
            <label class="form-label">Modelo</label>
            <select v-model="modelo" class="form-select p-2" v-bind:disabled="marcaid === 0">
              <option selected disabled value="0">Seleccione un Modelo</option>
              <option v-for="modelo in modeloPerMarca" :value="modelo.id" v-show="modelo.isActive">{{ modelo.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
          <div class="d-flex align-center justify-content-center"><i
            class="material-icons-round opacity-10 me-2">error</i>
            <p class="text-dark">Debe llenar todos los campos</p></div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="addVersion" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-pencil-square me-2"></i>Editar Versión<i @click="closeUpdate"
                                                                                         class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Nombre</label>
            <input v-model="name" class="form-control border-bottom border-dark mb-3 p-2" type="text">
            <label class="form-label">Marca</label>
            <select v-model="marcaid" class="form-select mb-3 p-2">
              <option selected disabled value="0">Seleccione una Marca</option>
              <option v-for="marca in allMarcas" :value="marca.id" v-show="marca.isActive">{{ marca.name }}
              </option>
            </select>
            <label class="form-label">Modelo</label>
            <select v-model="modelo" class="form-select p-2" v-bind:disabled="marcaid === 0">
              <option selected disabled value="0">Seleccione un Modelo</option>
              <option v-for="model in modeloPerMarca" :value="model.id" v-show="model.isActive">{{ model.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
          <div class="d-flex align-center justify-content-center"><i
            class="material-icons-round opacity-10 me-2">error</i>
            <p class="text-dark">Debe llenar todos los campos</p></div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="updateVersion" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { mapState } from "vuex";

const ALL_MARCAS = gql`
query AllMarcas {
    allMarcas {
        id
        name
        isActive
    }
}
`;
const MODELO_MARCA = gql`
query($id:ID!){
  modeloPerMarca(id:$id){
    id
    marca{
      id
      name
      isActive
    }
    name
    isActive
  }
}
`;
const BUSCAR_VERSION = gql`
          query VersionPerName($name: String!) {
    versionPerName(name: $name) {
        id
        name
        isActive
        modelo{
        id
        name
        isActive
        marca {
            id
            name
            isActive
        }
        }
    }
}
          `;
const DESACTIVE_VERSION = gql`
mutation($id:ID!){
  desactiveVersion(id: $id) {
        success
    }
}
`;
const ACTIVE_VERSION = gql`
mutation ActiveVersion($id:ID!) {
    activeVersion(id: $id) {
        success
    }
}
`;
const INSERT_VERSION = gql`
mutation CreateVersion($id:ID!, $name:String!) {
    createVersion(idModelo: $id, name: $name, ) {
        success
    }
}
  `;
const UPDATE_VERSION = gql`
  mutation UpdateVersion($id:ID!, $name:String!, $id_modelo:ID!) {
    updateVersion(idModelo: $id_modelo, idVersion: $id, name: $name) {
        success
    }
}
  `;
const DELETE_VERSION = gql`
mutation DeleteVersion($id:ID!) {
    deleteVersion(id: $id) {
        success
    }
}
`;
export default {
  name: "List_Version",
  components: { MaterialButton, VueGoogleAutocomplete },
  data() {
    return {
      misearch: "",
      versionPerName: [],
      allMarcas: [],
      showModal: false,
      UpdateModal: false,
      modelo: 0,
      name: "",
      versionid: 0,
      error: false,
      marcaid: 0,
      modeloPerMarca: []
    };
  },
  apollo: {
    versionPerName: {
      query: BUSCAR_VERSION,
      variables() {
        return {
          name: this.misearch
        };
      },
      fetchPolicy: "cache-and-network"
    },
    modeloPerMarca: {
      query: MODELO_MARCA,
      variables() {
        return {
          id: this.marcaid
        };
      },
      fetchPolicy: "cache-and-network"
    },
    allMarcas: {
      query: ALL_MARCAS,
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async addVersion() {
      if (this.modelo === 0 || this.name === "") {
        this.error = true;
        return false;
      }
      this.$apollo.mutate({
        mutation: INSERT_VERSION,
        variables: {
          name: this.name,
          id: parseInt(this.modelo)
        },
        refetchQueries: [{ query: BUSCAR_VERSION, variables: { name: this.misearch } }]
      }).then(response => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Versión creada"
        });
        this.closeModal();
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        this.closeModal();
        Toast.fire({
          icon: "error",
          title: "Error al crear la Versión"
        });
        console.error("La mutación falló:", error);
      });
    },
    async updateVersion() {
      await this.$apollo
        .mutate({
          mutation: UPDATE_VERSION,
          variables: {
            name: this.name,
            id_modelo: this.modelo,
            id: this.versionid
          },
          refetchQueries: [{ query: BUSCAR_VERSION, variables: { name: this.misearch } }]
        }).then(response => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Versión actualizada"
          });
          this.closeUpdate();
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al actualizar la Versión"
          });
          console.error("La mutación falló:", error);
        });
    },
    async DeleteVersion(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE_VERSION,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR_VERSION, variables: { name: this.misearch } }]
          });
          if (data.deleteVersion.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Versión eliminada"
            });
          }
        }
      });
    },
    async DesactiveVersion(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE_VERSION,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR_VERSION, variables: { name: this.misearch } }]
      });
      if (data.desactiveVersion.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "warning",
          title: "Versión desactivada"
        });
      }
    },
    async ActiveVersion(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE_VERSION,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR_VERSION, variables: { name: this.misearch } }]
      });
      if (data.activeVersion.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Versión activada"
        });
      }
    },
    openModal() {
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    openUpdate(name, marcaid,modeloid, id) {
      this.name = name;
      this.modelo = modeloid;
      this.marcaid=marcaid
      this.versionid = id;
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.name = "";
      this.versionid = 0;
      this.modelo = 0;
      this.marcaid = 0;
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    closeModal() {
      this.name = "";
      this.versionid = 0;
      this.modelo = 0;
      this.marcaid = 0;
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
