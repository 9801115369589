<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-12 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
          >
            ©
            {{ new Date().getFullYear() }}-
            <a
              href="#"
              class="font-weight-bold"
              target="_blank"
              >ISSY RENTAL</a
            >
            Todos los derechos reservados. Desarrollado por <a class="fw-bold" href="https://www.idoogroup.com/" target="_blank">IdooGroup</a>
          </div>
        </div>
        <div class="col-lg-6">

        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
