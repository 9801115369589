<template>
  <div class="row">
    <SimpleCard
      title="Total de contratos"
      :count="contratosTotalCant"
    />
    <SimpleCard
      title="Contratos abiertos"
      :count="contratosAbiertosCant"
    />
    <SimpleCard
      title="Contratos vencidos"
      :count="contratosVencidosCant"
    />
    <SimpleCard
      title="Contratos cerrados"
      :count="contratosCerradosCant"
    />
  </div>
  <div class="mt-4 row">
    <div class="col-lg-4 col-sm-6">
      <channels-chart-card />
    </div>
    <div class="mt-4 col-lg-8 col-sm-6 mt-sm-0">
      <revenue-chart-card />
    </div>
  </div>
</template>

<script>

import SimpleCard from "@/components/Informes/card_agente/SimpleCard.vue";
import HorizontalBarChart from "@/examples/Charts/HorizontalBarChart.vue";
import ChannelsChartCard from "@/components/Informes/card_admin/ChannelsChartCard.vue";
import RevenueChartCard from "@/components/Informes/card_admin/RevenueChartCard.vue";
import OrdersListCard from "@/examples/Cards/OrdersListCard.vue";
import gql from "graphql-tag";

const CONTRATOS_ABIERTOS = gql`
query ContratosAbiertosCant($sucursal:ID!) {
    contratosAbiertosCant(sucursal: $sucursal)
}`;
const CONTRATOS_VENCIDOS = gql`
query ContratosVencidosCant($sucursal:ID!) {
    contratosVencidosCant(sucursal: $sucursal)
}`;
const CONTRATOS_CERRADOS = gql`
query ContratosCerradosCant($sucursal:ID!) {
    contratosCerradosCant(sucursal: $sucursal)
}`;
const CONTRATOS_TOTAL = gql`
query ContratosTotalCant($sucursal:ID!) {
    contratosTotalCant(sucursal: $sucursal)
}`;
export default {
  components: { OrdersListCard, RevenueChartCard, ChannelsChartCard, HorizontalBarChart, SimpleCard },
  data() {
    return {
      contratosAbiertosCant: 0,
      contratosVencidosCant: 0,
      contratosCerradosCant: 0,
      contratosTotalCant: 0
    };
  },
  watch: {
    contratosAbiertosCant(val) {
      console.log(val);
    }
  },
  created() {
    this.$apollo.query({
      query: CONTRATOS_VENCIDOS,
      variables: {
        sucursal: this.$store.state.userSucursal
      }
    }).then(response => {
      this.contratosVencidosCant = response.data.contratosVencidosCant;
    });
    this.$apollo.query({
      query: CONTRATOS_ABIERTOS,
      variables: {
        sucursal: this.$store.state.userSucursal
      }
    }).then(response => {
      this.contratosAbiertosCant = response.data.contratosAbiertosCant;
    });
    this.$apollo.query({
      query: CONTRATOS_CERRADOS,
      variables: {
        sucursal: this.$store.state.userSucursal
      }
    }).then(response => {
      this.contratosCerradosCant = response.data.contratosCerradosCant;
    });
    this.$apollo.query({
      query: CONTRATOS_TOTAL,
      variables: {
        sucursal: this.$store.state.userSucursal
      }
    }).then(response => {
      this.contratosTotalCant = response.data.contratosTotalCant;
    });
    this.$store.state.Cargando = false;
  }
};
</script>

<style scoped>

</style>
