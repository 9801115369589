<template>
  <div class="container-fluid">
    <div class=" row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Información de contacto y logo del Portal Web
            </h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 mb-4">
                <label class="form-label">Número de teléfono</label>
                <div class="d-flex justify-content-between align-items-center">
                  <MazPhoneNumberInput
                    v-model="telefono"
                    v-model:country-code="cod_telefono"
                    show-code-on-list
                    :preferred-countries="['MX', 'CU', 'US']"
                    no-radius
                    :disabled="phone_disabled"
                    noBorder="false"
                    :translations="{
                      countrySelector: {
                        placeholder: 'Código de país',
                        error: 'Seleccione un país',
                        searchPlaceholder: 'Buscar un país',
                      },
                      phoneInput: {
                        placeholder: 'Número de teléfono',
                        example: 'Ejemplo:',
                      },
                    }"
                  />
                  <div class="btn-group pt-3" role="group">
                    <button v-if="edit_phone" data-bs-toggle="tooltip" data-bs-placement="top" @click="guardarPhone"
                            title="Guardar número de teléfono" data-container="body" data-animation="true"
                            class="btn btn-info ms-1">
                      <i class="material-icons opacity-10">save</i></button>
                    <button v-else data-bs-toggle="tooltip" data-bs-placement="top" @click="Habilitar_Phone"
                            title="Editar número de teléfono" data-container="body" data-animation="true"
                            class="btn btn-info ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 mb-4">
                <label class="form-label">Número de Whatsapp</label>
                <div class="d-flex justify-content-between align-items-center">
                  <MazPhoneNumberInput
                    v-model="whatsapp"
                    v-model:country-code="cod_wasa"
                    show-code-on-list
                    :preferred-countries="['MX', 'CU', 'US']"
                    no-radius
                    :disabled="wasa_disabled"
                    noBorder="false"
                    :translations="{
                      countrySelector: {
                        placeholder: 'Código de país',
                        error: 'Seleccione un país',
                        searchPlaceholder: 'Buscar un país',
                      },
                      phoneInput: {
                        placeholder: 'Número de whatsapp',
                        example: 'Ejemplo:',
                      },
                    }"
                  />
                  <div class="btn-group pt-3" role="group">
                    <button v-if="edit_wasa" data-bs-toggle="tooltip" data-bs-placement="top" @click="guardarWasa"
                            title="Guardar número de Whatsapp" data-container="body" data-animation="true"
                            class="btn btn-info ms-1">
                      <i class="material-icons opacity-10">save</i></button>
                    <button v-else data-bs-toggle="tooltip" data-bs-placement="top" @click="Habilitar_Wasa"
                            title="Editar número de Whatsapp" data-container="body" data-animation="true"
                            class="btn btn-info ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 mb-4">
                <label class="form-label">Logo</label>
                <div class="d-flex justify-content-between align-items-center">
                  <input class="form-control w-75" type="file" @change="onImagenSeleccionada" :disabled="logo_disabled">
                  <div class="btn-group pt-3" role="group">
                    <button v-if="edit_logo" data-bs-toggle="tooltip" data-bs-placement="top" @click="guardarImagen"
                            title="Guardar número de teléfono" data-container="body" data-animation="true"
                            class="btn btn-info ms-1">
                      <i class="material-icons opacity-10">save</i></button>
                    <button v-else data-bs-toggle="tooltip" data-bs-placement="top" @click="Habilitar_Logo"
                            title="Editar número de teléfono" data-container="body" data-animation="true"
                            class="btn btn-info ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 mb-4">
                <img class="img-fluid " :src="preview" style="height: 80px">
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import Swal from "sweetalert2";
import axios from "axios";
import { useBase64 } from "@vueuse/core";

const INFORMATION = gql`
query InformacionPortal {
    informacionPortal {
        id
        logo
        telefono
        whatsapp
        codTelefono
        codWhatsapp
    }
}`;
const UPDATE_TELEFONO = gql`
mutation UpdateTelefono($telefono:String!, $cod:String!) {
    updateTelefono(telefono: $telefono, cod: $cod) {
        error
    }
}`;
const UPDATE_WASA = gql`
mutation UpdateWhatsapp($cod:String!, $wasa:String!) {
    updateWhatsapp(cod: $cod, wasa: $wasa) {
        error
    }
}`;
export default {
  name: "Sliders",
  components: { MaterialButton, MazPhoneNumberInput },
  data() {
    return {
      edit_wasa: false,
      edit_phone: false,
      edit_logo: false,
      telefono: "",
      whatsapp: "",
      phone_disabled: true,
      wasa_disabled: true,
      logo_disabled: true,
      preview: null,
      informacionPortal: null,
      cod_telefono: "",
      cod_wasa: ""
    };
  },
  apollo: {
    informacionPortal: {
      query: INFORMATION,
      fetchPolicy: "cache-and-network"
    }
  },
  watch: {
    informacionPortal(value) {
      this.telefono = value.telefono;
      this.whatsapp = value.whatsapp;
      this.cod_telefono = value.codTelefono;
      this.cod_wasa = value.codWhatsapp;
      this.preview = this.urlImagen(value.logo);
    }
  },
  methods: {
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    onImagenSeleccionada(event) {
      this.photo = event.target.files[0];
      const file = event.target.files[0];
      const { base64 } = useBase64(file);
      this.preview = URL.createObjectURL(file);
    },
    guardarImagen() {
      let data = new FormData();
      data.append("operations", `{"query": "mutation ($file: Upload) {\\n  updateLogo(file: $file) {\\n    error  }\\n}","variables": {"file": null},"operationName": null}`);
      data.append("map", "{\"0\": [\"variables.file\"]}");
      data.append("0", this.photo, this.photo.name);
      return axios.post(`${this.$store.state.url}graphql/`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(resp => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Logo guardado"
        });
        this.$apollo.mutate({
          mutation: INFORMATION
        }).then(response => {
          this.informacionPortal = response.data.informacionPortal;
        });
        this.edit_logo = false;
        this.logo_disabled = true;
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Error al subir el logo"
        });
        this.$apollo.mutate({
          mutation: INFORMATION
        }).then(response => {
          this.informacionPortal = response.data.informacionPortal;
        });
        this.edit_logo = false;
        this.logo_disabled = true;
      });
    },
    guardarPhone() {
      this.$apollo.mutate({
        mutation: UPDATE_TELEFONO,
        variables: {
          telefono: this.telefono,
          cod: this.cod_telefono
        }
      }).then(response => {
        if (response.data.updateTelefono.error === false) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Número de teléfono guardado"
          });
          this.$apollo.mutate({
            mutation: INFORMATION
          }).then(response => {
            this.informacionPortal = response.data.informacionPortal;
          });
          this.edit_phone = false;
          this.phone_disabled = true;
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al actualizar el número de teléfono"
          });
          this.$apollo.mutate({
            mutation: INFORMATION
          }).then(response => {
            this.informacionPortal = response.data.informacionPortal;
          });
          this.edit_phone = false;
          this.phone_disabled = true;
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Error al actualizar el número de teléfono"
        });
        this.$apollo.mutate({
          mutation: INFORMATION
        }).then(response => {
          this.informacionPortal = response.data.informacionPortal;
        });
        this.edit_phone = false;
        this.phone_disabled = true;
      });
    },
    guardarWasa() {
      this.$apollo.mutate({
        mutation: UPDATE_WASA,
        variables: {
          wasa: this.telefono,
          cod: this.cod_telefono
        }
      }).then(response => {
        if (response.data.updateWhatsapp.error === false) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Número de whatsapp guardado"
          });
          this.$apollo.mutate({
            mutation: INFORMATION
          }).then(response => {
            this.informacionPortal = response.data.informacionPortal;
          });
          this.edit_phone = false;
          this.phone_disabled = true;
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al actualizar el número de whatsapp"
          });
          this.$apollo.mutate({
            mutation: INFORMATION
          }).then(response => {
            this.informacionPortal = response.data.informacionPortal;
          });
          this.edit_phone = false;
          this.phone_disabled = true;
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Error al actualizar el número de whatsapp"
        });
        this.$apollo.mutate({
          mutation: INFORMATION
        }).then(response => {
          this.informacionPortal = response.data.informacionPortal;
        });
        this.edit_phone = false;
        this.phone_disabled = true;
      });
    },
    urlImagen(imagen) {
      return this.$store.state.store_url + imagen;
    },
    Habilitar_Phone() {
      this.edit_phone = true;
      this.phone_disabled = false;
    },
    Habilitar_Wasa() {
      this.edit_wasa = true;
      this.wasa_disabled = false;
    },
    Habilitar_Logo() {
      this.edit_logo = true;
      this.logo_disabled = false;
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('@/assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 60%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.img-container {

  overflow: hidden;
}

.img-slider {
  width: 500px;
  height: 150px;
  object-fit: cover;
}

@media (max-width: 550px) {
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    width: 90%;
  }
}
</style>
