<template xmlns="http://www.w3.org/1999/html">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de cortesías
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar cortesía" aria-label="Recipient's username"
                       aria-describedby="button-addon2" autocomplete="new-password">
              </div>
            </div>
            <div class="col-9 text-end">
              <button @click="openModal" class="btn btn-dark align-content-end" type="submit">Nueva cortesía</button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Nombre</th>
                <th>Descuento</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="item in cortesias" :key="item.id">
                <td class="text-sm font-weight-normal">
                  <div class="ms-3">{{ item.name }}</div>
                </td>
                <td class="text-sm font-weight-normal">
                  <div class="ms-3">{{ item.descuento }} %</div>
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button
                      @click="openUpdate(item)"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Editar cortesía" data-container="body" data-animation="true"
                      class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                    <button v-if="item.isActive" @click="Desactive(item.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Desactivar cortesía" data-container="body"
                            data-animation="true" class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">block</i></button>
                    <button v-else @click="Active(item.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Activar cortesía" data-container="body" data-animation="true"
                            class="btn btn-warning p-1 ms-1">
                      <i class="material-icons opacity-10">check_circle</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal NuevoServicio">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nueva cortesía<i @click="closeModal"
                                                                                        class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <label class="form-label">Nombre de la cortesía</label>
            <input v-model="nombre" class="form-control mb-2"
                   type="text">
            <label class="form-label">Descuento (%)</label>
            <input v-model="descuento" class="form-control mb-2"
                   type="number">
          </div>
          <div v-show="error" class="text-danger mt-2 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar los campos</p></div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="addServicio" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal UpdateServicio">
    <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Editar cortesía<i @click="closeUpdate"
                                                                                         class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Nombre de la cortesía</label>
            <input v-model="nombre" class="form-control mb-2"
                   type="text">
            <label class="form-label">Descuento (%)</label>
            <input v-model="descuento" class="form-control mb-2"
                   type="number">
          </div>
          <div v-show="error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar los campos</p></div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="UpdateServicio" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import axios from "axios";
import { mapState } from "vuex";

const BUSCAR = gql`
          query Cortesias($name:String!) {
    cortesias(name: $name) {
        id
        isActive
        name
        descuento
    }
}`;
const INSERT = gql`
  mutation CreateCortesia($descuento:Decimal!, $name:String!) {
    createCortesia(descuento: $descuento, name: $name) {
        success
        error
    }
}`;
const UPDATE = gql`
  mutation UpdateCortesia($descuento:Decimal!, $id:ID!, $name:String!) {
    updateCortesia(descuento: $descuento, id: $id, name: $name) {
        success
        error
    }
}`;
const DESACTIVE = gql`
mutation($id:ID!){
  desactiveCortesia(id: $id) {
        success
    }
}
`;
const ACTIVE = gql`
mutation ActiveCortesia($id:ID!) {
    activeCortesia(id: $id) {
        success
    }
}
`;
export default {
  name: "List_Cortesias",
  components: { MaterialButton },
  data() {
    return {
      misearch: "",
      cortesias: [],
      showModal: false,
      UpdateModal: false,
      nombre: "",
      descuento: 0,
      id: 0,
      error: false
    };
  },
  apollo: {
    cortesias: {
      query: BUSCAR,
      variables() {
        return {
          name: this.misearch
        };
      },
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async addServicio() {
      if (this.nombre === "" || this.descuento === 0) {
        this.error = true;
        return false;
      }
      await this.$apollo
        .mutate({
          mutation: INSERT,
          variables: {
            name: this.nombre,
            descuento: this.descuento
          },
          refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
        }).then(response => {
          if (response.data.createCortesia.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });

            Toast.fire({
              icon: "success",
              title: "Cortesía creado correctamente"
            });
            this.reset();
            this.closeModal();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: response.createCortesia.error
            });
            this.reset();
            this.closeModal();
          }
        });
    },
    async UpdateServicio() {
      if (this.nombre === "" || this.descuento === 0) {
        this.error = true;
        return false;
      }
      await this.$apollo
        .mutate({
          mutation: UPDATE,
          variables: {
            name: this.nombre,
            descuento: this.descuento,
            id: this.id
          },
          refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
        }).then(response => {
          if (response.data.updateCortesia.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Cortesía actualizado correctamente"
            });
            this.closeUpdate();
            this.reset();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.updateCortesia.error
            });
            this.reset();
            this.closeUpdate();
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al actualizar la Categoría"
          });
          console.error("La mutación falló:", error);
        });
    },
    async DeleteCategoria(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
          });
          if (data.eliminarCortesia.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Cortesía eliminada"
            });
          }
        }
      });
    },
    async Desactive(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      if (data.desactiveCortesia.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "warning",
          title: "Cortesía desactivada"
        });
      }
    },
    async Active(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      if (data.activeCortesia.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Cortesía activada"
        });
      }
    },
    openModal() {
      this.reset();
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    openUpdate(item) {
      this.id = item.id;
      this.nombre = item.name;
      this.descuento = item.descuento;
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.reset();
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    closeModal() {
      this.reset();
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    reset() {
      this.descuento = 0;
      this.nombre = "";
      this.id = 0;
      this.error = false;
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.icon-service {
  width: 50px;
  object-fit: cover;
}
</style>
