<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de Titulares
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar Titular" aria-label="Recipient's username" aria-describedby="button-addon2">
              </div>
            </div>
            <div class="col-9 text-end">
              <button @click="openModal" class="btn btn-dark align-content-end" type="submit">Nuevo Titular</button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Nombre</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="titular in titularPerName" :key="titular.id">
                <td class="text-sm font-weight-normal" :class="titular.isActive?'':'text-danger'">
                  <div class="ms-3">{{ titular.name }}</div>
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button v-if="titular.isActive" @click="DesactiveTitular(titular.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Desactivar Titular" data-container="body"
                            data-animation="true" class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">block</i></button>
                    <button v-else @click="ActiveTitular(titular.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Activar Titular" data-container="body" data-animation="true"
                            class="btn btn-warning p-1 ms-1">
                      <i class="material-icons opacity-10">check_circle</i></button>
                    <button @click="openUpdate(titular.name, titular.id)"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Editar Titular" data-container="body" data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                    <button @click="DeleteTitular(titular.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Eliminar Titular" data-container="body" data-animation="true"
                            class="btn btn-danger p-1 ms-1">
                      <i class="material-icons opacity-10">delete</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nuevo Titular<i @click="closeModal"
                                                                                       class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Nombre</label>
            <input v-model="name" class="form-control border-bottom border-dark p-2"
                   placeholder="Escriba el nombre de un Titular" type="text">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe llenar el Nombre</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="addTitular" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-pencil-square me-2"></i>Editar Titular<i @click="closeUpdate"
                                                                                          class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Nombre</label>
            <input v-model="name" class="form-control border-bottom border-dark p-2" type="text">
          </div>
          <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar todos los campos</p></div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="UpdateTitular" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import { mapState } from "vuex";

const DESACTIVE_TITULAR = gql`
mutation DesactiveTitular($id:ID!) {
    desactiveTitular(id: $id) {
        success
    }
}`;
const BUSCAR_TITULAR = gql`
          query TitularPerName($name:String!) {
    titularPerName(name: $name) {
        id
        name
        isActive
    }
}
          `;
const ACTIVE_TITULAR = gql`
mutation ActiveTitular($id:ID!) {
    activeTitular(id: $id) {
        success
    }
}
`;
const INSERT_TITULAR = gql`
  mutation CreateTitular($name:String!) {
    createTitular(name: $name) {
        success
        errors
    }
}
  `;
const UPDATE_TITULAR = gql`
  mutation UpdateTitular($id: ID!, $name: String!) {
    updateTitular(id: $id, name: $name) {
        success
        errors
    }
}
  `;
const DELETE_TITULAR = gql`
       mutation DeleteTitular($id:ID!) {
          deleteTitular(id: $id) {
              success
    }
}
`;
export default {
  name: "List_Marca",
  components: { MaterialButton },
  data() {
    return {
      misearch: "",
      titularPerName: [],
      showModal: false,
      UpdateModal: false,
      name: "",
      titularid: 0,
      error: false
    };
  },
  apollo: {
    titularPerName: {
      query: BUSCAR_TITULAR,
      variables() {
        return {
          name: this.misearch
        };
      },
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async addTitular() {
      if (this.name === "") {
        this.error = true;
        return false;
      }
      await this.$apollo.mutate({
        mutation: INSERT_TITULAR,
        variables: {
          name: this.name
        },
        refetchQueries: [{ query: BUSCAR_TITULAR, variables: { name: this.misearch } }]
      }).then(response => {
        if (response.data.createTitular.errors) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });

          Toast.fire({
            icon: "error",
            title: response.data.createTitular.errors
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          this.name = "";
          Toast.fire({
            icon: "success",
            title: "Titular creado"
          });
        }
        this.name = "";
        this.showModal = false;
        this.error = false;
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "error",
          title: "Error al crear el Titular"
        });
        this.name = "";
        this.showModal = false;
        this.error = false;
        console.error("La mutación falló:", error);
      });
    },
    async UpdateTitular() {
      if (this.name === "") {
        this.error = true;
        return false;
      }
      await this.$apollo
        .mutate({
          mutation: UPDATE_TITULAR,
          variables: {
            name: this.name,
            id: this.titularid
          },
          refetchQueries: [{ query: BUSCAR_TITULAR, variables: { name: this.misearch } }]
        }).then(response => {
          if (response.data.updateTitular.errors) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });

            Toast.fire({
              icon: "error",
              title: response.data.updateTitular.errors
            });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Titular actualizado"
            });
          }
          this.name = "";
          this.marcaid = 0;
          this.UpdateModal = false;
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al actualizar el Titular"
          });
          console.error("La mutación falló:", error);
        });
    },
    async DeleteTitular(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE_TITULAR,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR_TITULAR, variables: { name: this.misearch } }]
          });
          if (data.deleteTitular.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Titular eliminado"
            });
          }
        }
      });
    },
    async DesactiveTitular(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE_TITULAR,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR_TITULAR, variables: { name: this.misearch } }]
      });
      if (data.desactiveTitular.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "warning",
          title: "Titular desactivado"
        });
      }
    },
    async ActiveTitular(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE_TITULAR,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR_TITULAR, variables: { name: this.misearch } }]
      });
      if (data.activeTitular.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Titular activado"
        });
      }
    },
    openModal() {
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    openUpdate(name, id) {
      this.name = name;
      this.titularid = id;
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.name = "";
      this.titularid = 0;
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    closeModal() {
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
