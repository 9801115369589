import axios from "axios";

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Authorization'] = sessionStorage.getItem('token');

export const reloadHeader = () =>{
    axios.defaults.headers.post['Authorization'] = sessionStorage.getItem('token');
};

export const getNotifications = (message, next) =>{
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
        {query: `{
                    searchFirebaseNotifications(first: 10, after: "${next}", message_Icontains: "", firebase: false){
                        pageInfo{
                            endCursor
                            hasNextPage
                        }
                        edges{
                            node{
                                id
                                message
                                readClient
                                title
                                reservation {
                                   id
                                    total
                                    email
                                    tipoCobertura
                                    dias
                                    serviciosExtras
                                    tarifaDiaria
                                    comentarios
                                    fechaRenta
                                    fechaFin
                                    nombre
                                    apellidos
                                    telefono
                                    edad
                                    pagoId
                                    origen
                                    revisado
                                    noReserva
                                    fecha
                                    estado
                                    metodoPago
                                    cantidad
                                    categoria {
                                        id
                                        name
                                        tradicionalAlta
                                        totalAlta
                                        tradicionalBaja
                                        totalBaja
                                        isActive
                                        fecha
                                        image
                                        iconoWhite
                                        iconoBlack
                                        maletas
                                        maletasSm
                                        personas
                                        transmision
                                        lugar
                                    }
                                    sucursal {
                                        id
                                        name
                                        phone
                                        contactPhone
                                        address
                                        postalCod
                                        openHour
                                        closeHour
                                        isActive
                                    }
                                    agente {
                                        id
                                        username
                                        firstName
                                        lastName
                                        email
                                        isActive
                                        phone
                                    }
                                }
                            }
                        }
                    }
                }`
        }
    );
}

export const readMessage = (obj) =>{
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
        {
            query: `mutation($id:Int!){
                    readNotification(id: $id){
                        info{
                            id
                            message
                            title
                            readClient
                            reservation {
                                   id
                                    total
                                    email
                                    tipoCobertura
                                    dias
                                    serviciosExtras
                                    tarifaDiaria
                                    comentarios
                                    fechaRenta
                                    fechaFin
                                    nombre
                                    apellidos
                                    telefono
                                    edad
                                    pagoId
                                    origen
                                    revisado
                                    noReserva
                                    fecha
                                    estado
                                    metodoPago
                                    cantidad
                                    categoria {
                                        id
                                        name
                                        tradicionalAlta
                                        totalAlta
                                        tradicionalBaja
                                        totalBaja
                                        isActive
                                        fecha
                                        image
                                        iconoWhite
                                        iconoBlack
                                        maletas
                                        maletasSm
                                        personas
                                        transmision
                                        lugar
                                    }
                                    sucursal {
                                        id
                                        name
                                        phone
                                        contactPhone
                                        address
                                        postalCod
                                        openHour
                                        closeHour
                                        isActive
                                    }
                                    agente {
                                        id
                                        username
                                        firstName
                                        lastName
                                        email
                                        isActive
                                        phone
                                    }
                                }
                        }
                   }
                 }`, variables: obj
        }
    );
}

export const getNotificationsNotRead = () =>{
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
        {query: `{
                    searchFirebaseNotifications(readClient: false, firebase: false){
                        edges{
                            node{
                                id
                                message
                                readClient
                                title
                                reservation {
                                   id
                                    total
                                    email
                                    tipoCobertura
                                    dias
                                    serviciosExtras
                                    tarifaDiaria
                                    comentarios
                                    fechaRenta
                                    fechaFin
                                    nombre
                                    apellidos
                                    telefono
                                    edad
                                    pagoId
                                    origen
                                    revisado
                                    noReserva
                                    fecha
                                    estado
                                    metodoPago
                                    cantidad
                                    categoria {
                                        id
                                        name
                                        tradicionalAlta
                                        totalAlta
                                        tradicionalBaja
                                        totalBaja
                                        isActive
                                        fecha
                                        image
                                        iconoWhite
                                        iconoBlack
                                        maletas
                                        maletasSm
                                        personas
                                        transmision
                                        lugar
                                    }
                                    sucursal {
                                        id
                                        name
                                        phone
                                        contactPhone
                                        address
                                        postalCod
                                        openHour
                                        closeHour
                                        isActive
                                    }
                                    agente {
                                        id
                                        username
                                        firstName
                                        lastName
                                        email
                                        isActive
                                        phone
                                    }
                                }
                            }
                        }
                    }
                }`
        }
    );
}
