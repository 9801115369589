<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de Clientes
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon" autocomplete="new-password"
                       placeholder="Buscar Cliente" aria-label="Recipient's username" aria-describedby="button-addon2">
              </div>
            </div>
            <div class="col-9 text-end">
              <button @click="openModal" class="btn btn-dark align-content-end" type="submit">Nuevo Cliente</button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Nombre</th>
                <th>Apellidos</th>
                <th>Correo</th>
                <th>Edad</th>
                <th>Tipo de Cliente</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="cliente in ClientePerName" :key="cliente.id">
                <td :class="cliente.isActive?'':'text-danger'">{{ cliente.nombre }}</td>
                <td :class="cliente.isActive?'':'text-danger'">{{ cliente.apellidos }}</td>
                <td :class="cliente.isActive?'':'text-danger'">{{ cliente.email }}</td>
                <td :class="cliente.isActive?'':'text-danger'">{{ cliente.edad }}</td>
                <td :class="cliente.isActive?'':'text-danger'">{{ cliente.tipo ? cliente.tipo.name : "" }}</td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button v-if="cliente.isActive" @click="Desactive(cliente.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Desactivar Cliente" data-container="body"
                            data-animation="true" class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">block</i></button>
                    <button v-else @click="Active(cliente.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Activar Cliente" data-container="body" data-animation="true"
                            class="btn btn-warning p-1 ms-1">
                      <i class="material-icons opacity-10">check_circle</i></button>
                    <button @click="openUpdate(cliente)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Actualizar Cliente" data-container="body" data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                    <button
                      @click="openCliente(cliente)"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Detalles del Cliente" data-container="body" data-animation="true"
                      class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">visibility</i></button>
                    <button @click="openFiles(cliente.id)"
                            data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Subir archivos del cliente" data-container="body" data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">upload_file</i></button>
                    <button @click="openVer(cliente)"
                            data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Ver archivos del cliente" data-container="body" data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">attach_file</i></button>
                    <button @click="Delete(cliente.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Eliminar Cliente" data-container="body" data-animation="true"
                            class="btn btn-danger p-1 ms-1">
                      <i class="material-icons opacity-10">delete</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Nuevo">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nuevo Cliente<i @click="closeModal"
                                                                                       class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Nombre<span class="text-danger ms-1">*</span></label>
            <input type="text" class="form-control mb-3"
                   :class="error_nombre?'is-invalido':''" v-model="nombre">
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Apellidos<span class="text-danger ms-1">*</span></label>
            <input type="text" class="form-control mb-3" v-model="apellidos" :class="error_apellidos?'is-invalido':''">
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Correo<span class="text-danger ms-1">*</span></label>
            <input type="email" class="form-control mb-3" v-model="email" :class="error_email?'is-invalido':''">
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Teléfono<span class="text-danger ms-1">*</span></label>
            <MazPhoneNumberInput
              v-model="telefono"
              v-model:country-code="cod_telefono"
              show-code-on-list
              :error="error_phone"
              :preferred-countries="['MX', 'CU', 'US']"
              no-radius
              noBorder="false"
              :translations="{
                      countrySelector: {
                        placeholder: 'Código de país',
                        error: 'Seleccione un país',
                        searchPlaceholder: 'Buscar un país',
                      },
                      phoneInput: {
                        placeholder: 'Número de teléfono',
                        example: 'Ejemplo:',
                      },
                    }"
            />
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Edad<span class="text-danger ms-1">*</span></label>
            <input type="number" min="0" class="form-control mb-3" v-model="edad" :class="error_edad?'is-invalido':''">
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Tipo de Cliente<span class="text-danger ms-1">*</span></label>
            <select class="form-control mb-3" v-model="tipo" :class="error_tipo?'is-invalido':''">
              <option value="0" selected disabled>Seleccione un Tipo de Cliente</option>
              <option v-for="item in allTipocliente" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Teléfono de emergencia<span class="text-danger ms-1">*</span></label>
            <MazPhoneNumberInput
              v-model="emergency_phone"
              show-code-on-list
              :error="error_emergency_phone"
              :preferred-countries="['MX', 'CU', 'US']"
              no-radius
              noBorder="false"
              :translations="{
                      countrySelector: {
                        placeholder: 'Código de país',
                        error: 'Seleccione un país',
                        searchPlaceholder: 'Buscar un país',
                      },
                      phoneInput: {
                        placeholder: 'Número de teléfono',
                        example: 'Ejemplo:',
                      },
                    }"
            />
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Dirección<span class="text-danger ms-1">*</span></label>
            <textarea style="height: 42px" class="form-control mb-3" :class="error_direccion?'is-invalido':''"
                      v-model="direccion"></textarea>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Código postal<span class="text-danger ms-1">*</span></label>
            <input class="form-control mb-3" type="text" v-model="postal" :class="error_postal?'is-invalido':''"
                   maxlength="5">
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">País<span class="text-danger ms-1">*</span></label>
            <vue-google-autocomplete
              ref="pais"
              id="map_country"
              classname="form-control mb-3"
              placeholder="Escriba el país"
              v-on:placechanged="getAddressDataPais"
              types="(regions)"
            ></vue-google-autocomplete>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Estado<span class="text-danger ms-1">*</span></label>
            <vue-google-autocomplete
              ref="estado"
              id="map_state"
              classname="form-control mb-3"
              placeholder="Escriba el estado"
              v-on:placechanged="getAddressDataEstado"
              types="(regions)"
            ></vue-google-autocomplete>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Ciudad<span class="text-danger ms-1">*</span></label>
            <vue-google-autocomplete
              ref="ciudad"
              id="map_city"
              classname="form-control mb-3"
              placeholder="Escriba la ciudad"
              v-on:placechanged="getAddressDataCiudad"
              types="(cities)"
            ></vue-google-autocomplete>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <label class="form-label">No. de licencia<span class="text-danger ms-1">*</span></label>
            <input maxlength="15" class="form-control mb-3" type="text" v-model="licencia"
                   :class="error_licencia?'is-invalido':''">
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Expiración de la licencia<span class="text-danger ms-1">*</span></label>
            <VueDatePicker v-model="exp_licencia" :class="error_exp_licencia?'error_fecha':''" format="dd/MM/yyyy"
                           placeholder="Seleccione una fecha" class="form_fecha" locale="es"
                           auto-apply />
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Trámite de la licencia (País)<span class="text-danger ms-1">*</span></label>
            <vue-google-autocomplete
              ref="country_lic"
              id="map_country_lic"
              classname="form-control mb-3"
              placeholder="Escriba el país"
              v-on:placechanged="getAddressDataPaisLicencia"
              types="(regions)"
            ></vue-google-autocomplete>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Trámite de la licencia (Estado)<span class="text-danger ms-1">*</span></label>
            <vue-google-autocomplete
              ref="estado_lic"
              id="map_state_lic"
              classname="form-control mb-3"
              placeholder="Escriba el estado"
              v-on:placechanged="getAddressDataEstadoLicencia"
              types="(regions)"
            ></vue-google-autocomplete>
          </div>
          <div class="col-lg-12 col-lx-12 col-sm-12 col-md-12 d-flex justify-content-start">
            <div class="form-check p-0">
              <input
                class="form-check-input"
                type="checkbox"
                id="conductor_extra"
                v-model="licencia_permanente"
              />
              <label for="conductor_extra" class="">Licencia permanente</label>
            </div>
          </div>
          <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar todos los campos</p></div>
          </div>
          <div v-show="phone_error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">El número de teléfono es inválido</p></div>
          </div>
          <div v-show="email_error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">El correo es inválido</p></div>
          </div>
          <div v-show="licencia_error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe escribir un numero de licencia válido</p></div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-start">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end">
            <button @click="Add" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Actualizar">
    <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-pencil-square me-2"></i>Editar Cliente<i @click="closeUpdate"
                                                                                          class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Nombre<span class="text-danger ms-1">*</span></label>
            <input type="text" class="form-control mb-3"
                   :class="error_nombre?'is-invalido':''" v-model="nombre">
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Apellidos<span class="text-danger ms-1">*</span></label>
            <input type="text" class="form-control mb-3" v-model="apellidos" :class="error_apellidos?'is-invalido':''">
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Correo<span class="text-danger ms-1">*</span></label>
            <input type="email" class="form-control mb-3" v-model="email" :class="error_email?'is-invalido':''">
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Teléfono<span class="text-danger ms-1">*</span></label>
            <MazPhoneNumberInput
              v-model="telefono"
              v-model:country-code="cod_telefono"
              show-code-on-list
              :error="error_phone"
              :preferred-countries="['MX', 'CU', 'US']"
              no-radius
              noBorder="false"
              :translations="{
                      countrySelector: {
                        placeholder: 'Código de país',
                        error: 'Seleccione un país',
                        searchPlaceholder: 'Buscar un país',
                      },
                      phoneInput: {
                        placeholder: 'Número de teléfono',
                        example: 'Ejemplo:',
                      },
                    }"
            />
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Edad<span class="text-danger ms-1">*</span></label>
            <input type="number" min="0" class="form-control mb-3" v-model="edad" :class="error_edad?'is-invalido':''">
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Tipo de Cliente<span class="text-danger ms-1">*</span></label>
            <select class="form-control mb-3" v-model="tipo" :class="error_tipo?'is-invalido':''">
              <option value="0" selected disabled>Seleccione un Tipo de Cliente</option>
              <option v-for="item in allTipocliente" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Teléfono de emergencia<span class="text-danger ms-1">*</span></label>
            <MazPhoneNumberInput
              v-model="emergency_phone"
              show-code-on-list
              :error="error_emergency_phone"
              :preferred-countries="['MX', 'CU', 'US']"
              no-radius
              noBorder="false"
              :translations="{
                      countrySelector: {
                        placeholder: 'Código de país',
                        error: 'Seleccione un país',
                        searchPlaceholder: 'Buscar un país',
                      },
                      phoneInput: {
                        placeholder: 'Número de teléfono',
                        example: 'Ejemplo:',
                      },
                    }"
            />
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Dirección<span class="text-danger ms-1">*</span></label>
            <textarea style="height: 42px" class="form-control mb-3" :class="error_direccion?'is-invalido':''"
                      v-model="direccion"></textarea>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Código postal<span class="text-danger ms-1">*</span></label>
            <input class="form-control mb-3" type="text" v-model="postal" :class="error_postal?'is-invalido':''"
                   maxlength="5">
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">País<span class="text-danger ms-1">*</span></label>
            <input v-if="pais!==''" v-model="pais" class="form-control mb-3" :class="error_pais?'is-invalido':''">
            <vue-google-autocomplete
              v-else
              ref="pais"
              id="map_country"
              classname="form-control mb-3"
              placeholder="Escriba el país"
              v-on:placechanged="getAddressDataPais"
              types="(regions)"
            ></vue-google-autocomplete>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Estado<span class="text-danger ms-1">*</span></label>
            <input v-if="estado!==''" v-model="estado" class="form-control mb-3" :class="error_estado?'is-invalido':''">
            <vue-google-autocomplete
              v-else
              ref="estado"
              id="map_state"
              classname="form-control mb-3"
              placeholder="Escriba el estado"
              v-on:placechanged="getAddressDataEstado"
              types="(regions)"
            ></vue-google-autocomplete>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Ciudad<span class="text-danger ms-1">*</span></label>
            <input v-if="ciudad!==''" class="form-control mb-3" v-model="ciudad"
                   :class="error_ciudad?'is-invalido':''">
            <vue-google-autocomplete
              v-else
              ref="ciudad"
              id="map_city"
              classname="form-control mb-3"
              placeholder="Escriba la ciudad"
              v-on:placechanged="getAddressDataCiudad"
              types="(cities)"
            ></vue-google-autocomplete>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <label class="form-label">No. de licencia<span class="text-danger ms-1">*</span></label>
            <input class="form-control mb-3" type="text" v-model="licencia" :class="error_licencia?'is-invalido':''"
                   maxlength="15">
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Expiración de la licencia<span class="text-danger ms-1">*</span></label>
            <VueDatePicker v-model="exp_licencia" :class="error_exp_licencia?'error_fecha':''" format="dd/MM/yyyy"
                           placeholder="Seleccione una fecha" class="form_fecha" locale="es"
                           auto-apply />
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Trámite de la licencia (País)<span class="text-danger ms-1">*</span></label>
            <input v-if="paisLicencia!==''" v-model="paisLicencia" class="form-control mb-3"
                   :class="error_paisLic?'is-invalido':''">
            <vue-google-autocomplete
              v-else
              ref="country_lic"
              id="map_country_lic"
              classname="form-control mb-3"
              placeholder="Escriba el país"
              v-on:placechanged="getAddressDataPaisLicencia"
              types="(regions)"
            ></vue-google-autocomplete>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="form-label">Trámite de la licencia (Estado)<span class="text-danger ms-1">*</span></label>
            <input v-if="estado_licencia!==''" v-model="estado_licencia" class="form-control mb-3"
                   :class="error_estado_licencia?'is-invalido':''">
            <vue-google-autocomplete
              v-else
              ref="estado_lic"
              id="map_state_lic"
              classname="form-control mb-3"
              placeholder="Escriba el estado"
              v-on:placechanged="getAddressDataEstadoLicencia"
              types="(regions)"
            ></vue-google-autocomplete>
          </div>
          <div class="col-lg-12 col-lx-12 col-sm-12 col-md-12 d-flex justify-content-start">
            <div class="form-check p-0">
              <input
                class="form-check-input"
                type="checkbox"
                id="conductor_extra"
                v-model="licencia_permanente"
              />
              <label for="conductor_extra" class="">Licencia permanente</label>
            </div>
          </div>
          <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar todos los campos</p></div>
          </div>
          <div v-show="phone_error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">El número de teléfono es inválido</p></div>
          </div>
          <div v-show="email_error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">El correo es inválido</p></div>
          </div>
          <div v-show="licencia_error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe escribir un numero de licencia válido</p></div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end">
            <button @click="Update" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Detalles">
    <div v-if="showCliente" :class="['modal', { 'show': showCliente }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="h-100">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles del Cliente<i @click="closeCliente"
                                                                                         class="material-icons-round opacity-10 modal-icon">close</i>
            </h4>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Nombre:</strong> &nbsp;
                    {{ this.nombre }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Email:</strong> &nbsp; {{ this.email }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Tipo de Cliente:</strong> &nbsp; {{ this.tipo }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Teléfono de emergencia:</strong> &nbsp; {{ this.emergency_phone }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">País:</strong> &nbsp; {{ this.pais }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Estado:</strong> &nbsp; {{ this.estado }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Ciudad:</strong> &nbsp; {{ this.ciudad }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Dirección:</strong> &nbsp; {{ this.direccion }}
                  </li>
                </ul>
              </div>
              <div class="col-lg-6 col-sm-12">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Apellidos:</strong> &nbsp;
                    {{ this.apellidos }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Teléfono:</strong> &nbsp; {{ this.telefono }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Edad:</strong> &nbsp; {{ this.edad }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Numero de la licencia:</strong> &nbsp; {{ this.licencia }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Expiración de la licencia:</strong> &nbsp; {{ this.exp_licencia }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Trámite de la licencia (País):</strong> &nbsp;
                    {{ this.estado_licencia ? this.paisLicencia : "" }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Trámite de la licencia (Estado):</strong> &nbsp;
                    {{ this.estado_licencia ? this.estado_licencia : "" }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Licencia permanente:</strong> &nbsp;
                    {{ this.licencia_permanente ? "Si" : "No" }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Código postal:</strong> &nbsp; {{ this.postal }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row text-end">
              <div class="col-8"></div>
              <div class="col-4">
                <button @click="closeCliente" class="mt-3 btn btn-dark text-end">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal FilesUpload">
    <div v-if="fileShow" :class="['modal', { 'show': fileShow }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Subir archivos del cliente<i
            @click="closeFiles"
            class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Seleccione la imagen del ID</label>
            <input type="file" class="form-control mb-3 p-2 border-dark border-bottom"
                   :class="file_Id===null?'text-danger':'text-success'" @change="onID">
            <label class="form-label">Seleccione la imagen del pasaporte</label>
            <input type="file" class="form-control mb-3 p-2 border-dark border-bottom"
                   :class="file_Pasaporte===null?'text-danger':'text-success'" @change="onPasaporte">
            <label class="form-label">Seleccione la imagen de la licencia</label>
            <input type="file" class="form-control mb-3 p-2 border-dark border-bottom"
                   :class="file_Licencia===null?'text-danger':'text-success'" @change="onLicencia">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Todos los archivos son obligatorios</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeFiles" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="guardarFiles" class="btn btn-dark" type="submit">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal FilesDownload">
    <div v-if="VerShow" :class="['modal', { 'show': VerShow }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-eye me-2"></i>Ver archivos del cliente<i
            @click="closeVer"
            class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12 d-flex justify-content-between align-items-center">
            <label class="form-label">Imagen del ID</label>
            <a target="_blank" :href="file_Id"
               data-bs-toggle="tooltip" data-bs-placement="top"
               title="Subir archivos del cliente" data-container="body" data-animation="true"
               class="btn btn-info p-1 ms-1">
              <i class="material-icons opacity-10">attach_file</i></a>
          </div>
          <div class="col-12 d-flex justify-content-between align-items-center">
            <label class="form-label">Imagen del pasaporte</label>
            <a target="_blank" :href="file_Pasaporte"
               data-bs-toggle="tooltip" data-bs-placement="top"
               title="Subir archivos del cliente" data-container="body" data-animation="true"
               class="btn btn-info p-1 ms-1">
              <i class="material-icons opacity-10">attach_file</i></a>
          </div>
          <div class="col-12 d-flex justify-content-between align-items-center">
            <label class="form-label">Imagen de la licencia</label>
            <a target="_blank" :href="file_Licencia"
               data-bs-toggle="tooltip" data-bs-placement="top"
               title="Subir archivos del cliente" data-container="body" data-animation="true"
               class="btn btn-info p-1 ms-1">
              <i class="material-icons opacity-10">attach_file</i></a>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeVer" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import axios from "axios";
import moment from "moment/moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import { mapState } from "vuex";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";

const TIPOS = gql`
query AllTipocliente {
    allTipocliente {
        id
        name
        isActive
    }
}`;
const DESACTIVE = gql`
mutation DesactiveCliente($id:ID!) {
    desactiveCliente(id: $id) {
        success
    }
}`;
const BUSCAR = gql`
          query ClientePerName($name:String!) {
    ClientePerName(name: $name) {
        id
        nombre
        apellidos
        telefono
        email
        edad
        isActive
        direccion
        postal
        telefonoEmergencia
        carnet
        licenciaFile
        pasaporte
        tipo {
            id
            name
            isActive
        }
        ciudad
        estado
        pais
        licencia {
            id
            numero
            vencimiento
            permanente
            estado
            pais
        }
    }
}`;
const ACTIVE = gql`
mutation ActiveCliente($id:ID!) {
    activeCliente(id: $id) {
        success
    }
}`;
const DELETE = gql`
mutation EliminarCliente($id:ID!) {
    eliminarCliente(id: $id) {
        success
    }
}`;
const NUEVO_CLIENTE = gql`
  mutation CreateCliente($apellidos:String!, $edad:Int!, $email:String!, $nombre:String!, $telefono:String!, $tipo:Int!
  $ciudad:String!, $estado:String!, $codigo:String!, $pais:String!, $licencia:Int!, $postal:String!, $telefonoEmergencia:String!, $direccion:String!) {
    createCliente(
        apellidos: $apellidos
        edad: $edad
        email: $email
        nombre: $nombre
        telefono: $telefono
        tipo: $tipo
        ciudad: $ciudad
        pais: $pais
        estado: $estado
        licencia: $licencia
        postal: $postal
        telefonoEmergencia: $telefonoEmergencia
        direccion:$direccion
        codigo:$codigo
    ) {
        success
        cliente {
            id
            nombre
            apellidos
            telefono
            email
            edad
            isActive
            direccion
            postal
            telefonoEmergencia
        }
    }
}`;
const UPDATE_CLIENTE = gql`
  mutation CreateCliente($apellidos:String!, $edad:Int!, $email:String!, $nombre:String!, $telefono:String!, $tipo:Int!
  $ciudad:String!, $estado:String!, $codigo:String!, $pais:String!, $licencia:Int!, $postal:String!, $telefonoEmergencia:String!, $direccion:String!, $id:Int!) {
    updateCliente(
        apellidos: $apellidos
        edad: $edad
        email: $email
        nombre: $nombre
        telefono: $telefono
        tipo: $tipo
        ciudad: $ciudad
        pais: $pais
        estado: $estado
        licencia: $licencia
        postal: $postal
        telefonoEmergencia: $telefonoEmergencia
        direccion:$direccion
        id:$id
        codigo:$codigo
    ) {
        success
    }
}`;
const NUEVA_LICENCIA = gql`
mutation CreateLicencia($pais:String!,$estado: String!, $numero: String!, $permanente: Boolean!, $vencimiento: Date!) {
    createLicencia(pais:$pais,estado: $estado, numero: $numero, permanente: $permanente, vencimiento: $vencimiento) {
        success
        licencia {
            id
            numero
            vencimiento
            estado
            permanente
        }
    }
}`;
const UPDATE_LICENCIA = gql`
mutation UpdateLicencia($pais:String!, $estado: String!, $numero: String!, $permanente: Boolean!, $vencimiento: Date!, $id:Int!) {
    updateLicencia(pais:$pais, estado: $estado, numero: $numero, permanente: $permanente, vencimiento: $vencimiento, id:$id) {
        success
        licencia {
            id
            numero
            vencimiento
            estado
            permanente
        }
    }
}`;
const ALLESTADOS = gql`
query AllEstados {
    allEstados {
        id
        name
        isActive
    }
}
`;
const CIUDADPERESTADO = gql`
query($id:ID!){
  ciudadPerNamee(id:$id){
    id
    name
    estado{
      name
      id
    }
    isActive
  }
}`;
export default {
  name: "List_Estado",
  components: { MazPhoneNumberInput, MaterialButton, VueGoogleAutocomplete, VueDatePicker },
  data() {
    return {
      cod_telefono: "",
      paisLicencia: "",
      misearch: "",
      ClientePerName: [],
      allTipocliente: [],
      allEstados: [],
      ciudadPerNamee: [],
      showModal: false,
      showCliente: false,
      id: 0,
      apellidos: "",
      nombre: "",
      telefono: "",
      email: "",
      edad: 0,
      tipo: 0,
      emergency_phone: "",
      direccion: "",
      estado: "",
      ciudad: "",
      postal: "",
      UpdateModal: false,
      error: false,
      ventana: true,
      licencia: "",
      exp_licencia: null,
      estado_licencia: "",
      tarjeta: "",
      exp_tarjeta: null,
      tarjeta_autorizacion: "",
      licencia_permanente: false,
      id_tarjeta: 0,
      id_licencia: 0,
      file_Id: null,
      file_Licencia: null,
      file_Pasaporte: null,
      fileShow: false,
      VerShow: false,
      pais: "",
      phone_error: false,
      email_error: false,
      tarjeta_error: false,
      licencia_error: false,
      autorizacion_error: false,
      error_apellidos: false,
      error_nombre: false,
      error_edad: false,
      error_tipo: false,
      error_emergency_phone: false,
      error_direccion: false,
      error_estado: false,
      error_ciudad: false,
      error_postal: false,
      error_licencia: false,
      error_exp_licencia: false,
      error_estado_licencia: false,
      error_phone: false,
      error_email: false,
      error_pais: false,
      error_paisLic: false
    };
  },
  apollo: {
    ClientePerName: {
      query: BUSCAR,
      variables() {
        return {
          name: this.misearch
        };
      },
      fetchPolicy: "cache-and-network"
    },
    allTipocliente: {
      query: TIPOS,
      fetchPolicy: "cache-and-network"
    }
  },
  mounted() {
    this.$store.state.Cargando = true;
  },
  watch: {
    ClientePerName() {
      this.$store.state.Cargando = false;
    },
    apellidos(value) {
      if (value !== "") {
        this.error_apellidos = false;
      }
    },
    nombre(value) {
      if (value !== "") {
        this.error_nombre = false;
      }
    },
    edad(value) {
      if (value !== 0) {
        this.error_edad = false;
      }
    },
    tipo(value) {
      if (value !== 0) {
        this.error_tipo = false;
      }
    },
    emergency_phone(value) {
      if (value !== "") {
        this.error_emergency_phone = false;
      }
    },
    direccion(value) {
      if (value !== "") {
        this.error_direccion = false;
      }
    },
    estado(value) {
      if (value !== "") {
        this.error_estado = false;
      }
    },
    ciudad(value) {
      if (value !== "") {
        this.error_ciudad = false;
      }
    },
    pais(value) {
      if (value !== "") {
        this.error_pais = false;
      }
    },
    postal(value) {
      if (value !== "") {
        this.error_postal = false;
      }
    },
    licencia(value) {
      if (value !== "") {
        this.error_licencia = false;
      }
    },
    exp_licencia(value) {
      if (value !== null) {
        this.error_exp_licencia = false;
      }
    },
    paisLicencia(value) {
      if (value !== "") {
        this.error_paisLic = false;
      }
    },
    estado_licencia(value) {
      if (value !== "") {
        this.error_estado_licencia = false;
      }
    },
    phone(value) {
      if (value !== "") {
        this.error_phone = false;
      }
    },
    email(value) {
      if (value !== "") {
        this.error_email = false;
      }
    }
  },
  methods: {
    Siguiente() {
      var validacion = true;
      if (this.nombre === "") {
        this.error_nombre = true;
        validacion = false;
      }
      if (this.apellidos === "") {
        this.error_apellidos = true;
        validacion = false;
      }
      if (this.telefono === "") {
        this.phone_error = true;
        validacion = false;
      }
      if (this.tipo === 0) {
        this.error_tipo = true;
        validacion = false;
      }
      if (this.ciudad === 0) {
        this.error_ciudad = true;
        validacion = false;
      }
      if (this.edad === 0 || this.edad < 21) {
        this.error_edad = true;
        validacion = false;
      }
      if (this.direccion === "") {
        this.error_direccion = true;
        validacion = false;
      }
      if (this.postal === "") {
        this.error_postal = true;
        validacion = false;
      }
      if (validacion) {
        if (/^\d+$/.test(this.telefono) === false) {
          this.phone_error = true;
          return false;
        }
        this.phone_error = false;
        if (/^\d+$/.test(this.emergency_phone) === false) {
          this.phone_error = true;
          return false;
        }
        this.phone_error = false;
        if (/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(this.email) === false) {
          this.email_error = true;
          return false;
        }
        this.email_error = false;
        this.ventana = !this.ventana;
      } else {
        this.error = true;
      }

    },
    async Add() {
      var validacion = true;
      if (this.nombre === "") {
        this.error_nombre = true;
        validacion = false;
      }
      if (this.apellidos === "") {
        this.error_apellidos = true;
        validacion = false;
      }
      if (this.email === "") {
        this.error_email = true;
        validacion = false;
      }
      if (this.telefono === "") {
        this.error_phone = true;
        validacion = false;
      }
      if (this.emergency_phone === "") {
        this.error_emergency_phone = true;
        validacion = false;
      }
      if (this.tipo === "") {
        this.error_tipo = true;
        validacion = false;
      }
      if (this.ciudad === "") {
        this.error_ciudad = true;
        validacion = false;
      }
      if (this.pais === "") {
        this.error_pais = true;
        validacion = false;
      }
      if (this.edad === 0 || this.edad < 21) {
        this.error_edad = true;
        validacion = false;
      }
      if (this.direccion === "") {
        this.error_direccion = true;
        validacion = false;
      }
      if (this.postal === "") {
        this.error_postal = true;
        validacion = false;
      }
      if (this.estado === 0) {
        this.error_estado = true;
        validacion = false;
      }
      if (this.exp_licencia === null) {
        this.error_exp_licencia = true;
        validacion = false;
      }
      if (this.licencia === "") {
        this.error_licencia = true;
        validacion = false;
      }
      if (this.estado_licencia === "") {
        this.error_estado_licencia = true;
        validacion = false;
      }
      if (this.paisLicencia === "") {
        this.error_paisLic = true;
        validacion = false;
      }

      if (validacion) {
        if (/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(this.email) === false) {
          this.email_error = true;
          return false;
        }
        this.email_error = false;
        if (this.licencia === "" || this.exp_licencia === null || this.estado_licencia === "") {
          this.error = true;
          return false;
        }
        this.error = false;
        if (/^[a-zA-Z0-9]*$/.test(this.licencia) === false) {
          this.licencia_error = true;
          return false;
        }
        this.licencia_error = false;
        this.$apollo.mutate({
          mutation: NUEVA_LICENCIA,
          variables: {
            estado: this.estado_licencia,
            pais: this.paisLicencia,
            numero: this.licencia,
            vencimiento: moment(this.exp_licencia).format("YYYY-MM-DD"),
            permanente: this.licencia_permanente
          }
        }).then(response => {
          if (response.data.createLicencia.success) {
            this.id_licencia = response.data.createLicencia.licencia.id;
            this.$apollo.mutate({
              mutation: NUEVO_CLIENTE,
              variables: {
                apellidos: this.apellidos,
                edad: this.edad,
                email: this.email,
                nombre: this.nombre,
                telefono: this.telefono,
                tipo: this.tipo,
                codigo: this.cod_telefono,
                ciudad: this.ciudad,
                estado: this.estado,
                pais: this.pais,
                licencia: this.id_licencia,
                postal: this.postal,
                telefonoEmergencia: this.emergency_phone,
                direccion: this.direccion
              },
              refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
            }).then(response => {
              if (response.data.createCliente.success) {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  }
                });
                Toast.fire({
                  icon: "success",
                  title: "Cliente creado"
                });
                this.closeModal();
              }
            }).catch(error => {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al crear el Cliente"
              });
            });
          }
        });
      } else {
        this.error = true;
      }
    },
    async Update() {
      var validacion = true;
      if (this.nombre === "") {
        this.error_nombre = true;
        validacion = false;
      }
      if (this.apellidos === "") {
        this.error_apellidos = true;
        validacion = false;
      }
      if (this.email === "") {
        this.error_email = true;
        validacion = false;
      }
      if (this.telefono === "") {
        this.error_phone = true;
        validacion = false;
      }
      if (this.emergency_phone === "") {
        this.error_emergency_phone = true;
        validacion = false;
      }
      if (this.tipo === 0) {
        this.error_tipo = true;
        validacion = false;
      }
      if (this.ciudad === "") {
        this.error_ciudad = true;
        validacion = false;
      }
      if (this.pais === "") {
        this.error_pais = true;
        validacion = false;
      }
      if (this.edad === 0 || this.edad < 21) {
        this.error_edad = true;
        validacion = false;
      }
      if (this.direccion === "") {
        this.error_direccion = true;
        validacion = false;
      }
      if (this.postal === "") {
        this.error_postal = true;
        validacion = false;
      }
      if (this.estado === "") {
        this.error_estado = true;
        validacion = false;
      }
      if (this.paisLicencia === "") {
        this.error_paisLic = true;
        validacion = false;
      }
      if (this.exp_licencia === null) {
        this.error_exp_licencia = true;
        validacion = false;
      }
      if (this.licencia === "") {
        this.error_licencia = true;
        validacion = false;
      }
      if (this.estado_licencia === "") {
        this.error_estado_licencia = true;
        validacion = false;
      }

      if (validacion) {
        if (/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(this.email) === false) {
          this.email_error = true;
          return false;
        }
        this.email_error = false;
        if (this.licencia === "" || this.exp_licencia === null || this.estado_licencia === "") {
          this.error = true;
          return false;
        }
        this.error = false;
        if (/^[a-zA-Z0-9]*$/.test(this.licencia) === false) {
          this.licencia_error = true;
          return false;
        }
        this.licencia_error = false;
        if (this.id_licencia !== 0) {
          this.$apollo.mutate({
            mutation: UPDATE_LICENCIA,
            variables: {
              estado: this.estado_licencia,
              pais: this.paisLicencia,
              numero: this.licencia,
              vencimiento: moment(this.exp_licencia).format("YYYY-MM-DD"),
              permanente: this.licencia_permanente,
              id: this.id_licencia
            }
          }).then(response => {
            if (response.data.updateLicencia.success) {
              this.id_licencia = response.data.updateLicencia.licencia.id;
              this.$apollo.mutate({
                mutation: UPDATE_CLIENTE,
                variables: {
                  apellidos: this.apellidos,
                  edad: this.edad,
                  email: this.email,
                  nombre: this.nombre,
                  codigo: this.cod_telefono,
                  telefono: this.telefono,
                  tipo: this.tipo,
                  ciudad: this.ciudad,
                  estado: this.estado,
                  pais: this.pais,
                  licencia: this.id_licencia,
                  postal: this.postal,
                  telefonoEmergencia: this.emergency_phone,
                  direccion: this.direccion,
                  id: this.id
                },
                refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
              }).then(response => {
                if (response.data.updateCliente.success) {
                  const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    }
                  });
                  Toast.fire({
                    icon: "success",
                    title: "Cliente actualizado"
                  });
                  this.closeUpdate();
                } else {
                  const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    }
                  });
                  Toast.fire({
                    icon: "error",
                    title: "Error al actualizar el cliente"
                  });
                  this.closeUpdate();
                }
              }).catch(error => {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  }
                });
                Toast.fire({
                  icon: "error",
                  title: "Error al actualizar el cliente"
                });
                this.closeUpdate();
              });
            }
          });
        } else {
          this.$apollo.mutate({
            mutation: NUEVA_LICENCIA,
            variables: {
              estado: this.estado_licencia,
              pais: this.paisLicencia,
              numero: this.licencia,
              vencimiento: moment(this.exp_licencia).format("YYYY-MM-DD"),
              permanente: this.licencia_permanente
            }
          }).then(response => {
            if (response.data.createLicencia.success) {
              this.id_licencia = response.data.createLicencia.licencia.id;
              this.$apollo.mutate({
                mutation: UPDATE_CLIENTE,
                variables: {
                  apellidos: this.apellidos,
                  edad: this.edad,
                  email: this.email,
                  codigo: this.cod_telefono,
                  nombre: this.nombre,
                  telefono: this.telefono,
                  tipo: this.tipo,
                  ciudad: this.ciudad,
                  estado: this.estado,
                  pais: this.pais,
                  licencia: this.id_licencia,
                  postal: this.postal,
                  telefonoEmergencia: this.emergency_phone,
                  direccion: this.direccion,
                  id: this.id
                },
                refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
              }).then(response => {
                if (response.data.updateCliente.success) {
                  const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    }
                  });
                  Toast.fire({
                    icon: "success",
                    title: "Cliente actualizado"
                  });
                  this.closeUpdate();
                } else {
                  const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    }
                  });
                  Toast.fire({
                    icon: "error",
                    title: "Error al actualizar el cliente"
                  });
                  this.closeUpdate();
                }
              }).catch(error => {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  }
                });
                Toast.fire({
                  icon: "error",
                  title: "Error al actualizar el cliente"
                });
                this.closeUpdate();
              });
            }
          });
        }
      } else {
        this.error = true;
      }
    },
    async Delete(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
          });
          if (data.eliminarCliente.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Cliente eliminado"
            });
          }
        }
      });
    },
    async Desactive(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      if (data.desactiveCliente.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "warning",
          title: "Cliente desactivado"
        });
      }
    },
    async Active(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      if (data.activeCliente.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Cliente activado"
        });
      }
    },
    openModal() {
      this.showModal = true;
      this.error = false;
      this.email_error = false;
      this.phone_error = false;
      document.body.classList.add("modal-open");
      this.error_apellidos = false;
      this.error_nombre = false;
      this.error_edad = false;
      this.error_tipo = false;
      this.error_emergency_phone = false;
      this.error_direccion = false;
      this.error_estado = false;
      this.error_ciudad = false;
      this.error_postal = false;
      this.error_licencia = false;
      this.error_exp_licencia = false;
      this.error_estado_licencia = false;
      this.error_phone = false;
      this.error_email = false;
    },
    closeModal() {
      this.showModal = false;
      this.estado_licencia = "";
      this.estado = "";
      this.pais = "";
      this.ciudad = "";
      this.paisLicencia = "";
      this.licencia = "";
      this.exp_licencia = null;
      this.licencia_permanente = false;
      this.id_licencia = 0;
      this.tarjeta = "";
      this.tarjeta_autorizacion = "";
      this.exp_tarjeta = null;
      this.id_tarjeta = 0;
      this.apellidos = "";
      this.edad = 0;
      this.email = "";
      this.nombre = "";
      this.telefono = "";
      this.tipo = 0;
      this.postal = "";
      this.emergency_phone = "0";
      this.direccion = "";
      this.id_licencia = 0;
      this.id = 0;
      this.ventana = true;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    openUpdate(cliente) {

      this.estado_licencia = cliente.licencia !== null ? cliente.licencia.estado ? cliente.licencia.estado : "" : "";
      this.paisLicencia = cliente.licencia !== null ? cliente.licencia.pais ? cliente.licencia.pais : "" : "";

      this.licencia = cliente.licencia !== null ? cliente.licencia.numero : "";

      this.exp_licencia = cliente.licencia !== null ? cliente.licencia.vencimiento : null;
      this.licencia_permanente = cliente.licencia !== null ? cliente.licencia.permanente : false;
      this.id_licencia = cliente.licencia !== null ? cliente.licencia.id : 0;
      this.apellidos = cliente.apellidos !== null ? cliente.apellidos : "";
      this.edad = cliente.edad !== null ? cliente.edad : "";
      this.email = cliente.email !== null ? cliente.email : "";
      this.nombre = cliente.nombre !== null ? cliente.nombre : "";
      this.telefono = cliente.telefono !== null ? cliente.telefono : "";
      this.tipo = cliente.tipo !== null ? cliente.tipo.id : 0;

      this.ciudad = cliente.ciudad !== null ? cliente.ciudad : "";
      this.pais = cliente.pais !== null ? cliente.pais : "";

      this.postal = cliente.postal !== null ? cliente.postal : "";

      this.emergency_phone = cliente.telefonoEmergencia !== null ? cliente.telefonoEmergencia : "";

      this.direccion = cliente.direccion !== null ? cliente.direccion : "";

      this.id = cliente.id;
      this.estado = cliente.estado ? cliente.estado : "";
      this.error = false;
      this.email_error = false;
      this.phone_error = false;
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
      this.error_apellidos = false;
      this.error_nombre = false;
      this.error_edad = false;
      this.error_tipo = false;
      this.error_emergency_phone = false;
      this.error_direccion = false;
      this.error_estado = false;
      this.error_ciudad = false;
      this.error_postal = false;
      this.error_licencia = false;
      this.error_exp_licencia = false;
      this.error_estado_licencia = false;
      this.error_phone = false;
      this.error_email = false;
    },
    openCliente(cliente) {
      this.estado_licencia = cliente.licencia ? cliente.licencia.estado ? cliente.licencia.estado : "" : "";
      this.paisLicencia = cliente.licencia ? cliente.licencia.pais ? cliente.licencia.pais : "" : "";
      this.licencia = cliente.licencia ? cliente.licencia.numero : "";
      this.exp_licencia = cliente.licencia ? cliente.licencia.vencimiento : null;
      this.licencia_permanente = cliente.licencia ? cliente.licencia.permanente : false;
      this.id_licencia = cliente.licencia ? cliente.licencia.id : 0;
      this.tarjeta = cliente.tarjeta ? cliente.tarjeta.numero : "";
      this.tarjeta_autorizacion = cliente.tarjeta ? cliente.tarjeta.autorizacion : "";
      this.exp_tarjeta = cliente.tarjeta ? cliente.tarjeta.vencimiento : null;
      this.id_tarjeta = cliente.tarjeta ? cliente.tarjeta.id : 0;
      this.apellidos = cliente.apellidos;
      this.edad = cliente.edad;
      this.email = cliente.email;
      this.nombre = cliente.nombre;
      this.telefono = cliente.telefono;
      this.tipo = cliente.tipo ? cliente.tipo.name : "";
      this.ciudad = cliente.ciudad ? cliente.ciudad : "";
      this.pais = cliente.pais ? cliente.pais : "";
      this.estado = cliente.estado ? cliente.estado : "";
      this.postal = cliente.postal;
      this.emergency_phone = cliente.telefonoEmergencia;
      this.direccion = cliente.direccion;
      this.id = cliente.id;
      this.showCliente = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.UpdateModal = false;
      this.estado_licencia = "";
      this.estado = "";
      this.pais = "";
      this.ciudad = "";
      this.paisLicencia = "";
      this.licencia = "";
      this.exp_licencia = null;
      this.licencia_permanente = false;
      this.id_licencia = 0;
      this.tarjeta = "";
      this.tarjeta_autorizacion = "";
      this.exp_tarjeta = null;
      this.id_tarjeta = 0;
      this.apellidos = "";
      this.edad = 0;
      this.email = "";
      this.nombre = "";
      this.telefono = "";
      this.tipo = 0;
      this.postal = "";
      this.emergency_phone = "0";
      this.direccion = "";
      this.id = 0;
      this.id_licencia = 0;
      this.error = false;
      this.ventana = true;
      // document.body.classList.remove("modal-open");
    },
    closeCliente() {
      this.showCliente = false;
      this.estado_licencia = "";
      this.estado = "";
      this.pais = "";
      this.ciudad = "";
      this.paisLicencia = "";
      this.licencia = "";
      this.exp_licencia = null;
      this.licencia_permanente = false;
      this.id_licencia = 0;
      this.tarjeta = "";
      this.tarjeta_autorizacion = "";
      this.exp_tarjeta = null;
      this.id_tarjeta = 0;
      this.apellidos = "";
      this.edad = 0;
      this.email = "";
      this.nombre = "";
      this.telefono = "";
      this.tipo = 0;
      this.postal = "";
      this.emergency_phone = "0";
      this.direccion = "";
      this.id = 0;
      this.ventana = true;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    guardarFiles() {
      this.$store.state.Cargando = true;
      if (this.file_Id === null || this.file_Licencia === null || this.file_Pasaporte === null) {
        this.error = true;
        return false;
      }
      let data = new FormData();
      data.append("operations", `{"query": "mutation ($carnet: Upload, $id: Int!) {\\n  uploadCarnet(id: $id, carnet: $carnet) {\\n    error  }\\n}","variables": {"id": ${this.id},"carnet": null},"operationName": null}`);
      data.append("map", "{\"1\": [\"variables.carnet\"]}");
      data.append("1", this.file_Id, this.file_Id.name);
      return axios.post(`${this.$store.state.url}graphql/`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(resp => {
        let data1 = new FormData();
        data1.append("operations", `{"query": "mutation ($licencia: Upload, $id: Int!) {\\n  uploadLicencia(id: $id, licencia: $licencia) {\\n    error  }\\n}","variables": {"id": ${this.id},"licencia": null},"operationName": null}`);
        data1.append("map", "{\"1\": [\"variables.licencia\"]}");
        data1.append("1", this.file_Licencia, this.file_Licencia.name);
        return axios.post(`${this.$store.state.url}graphql/`, data1, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then(response => {
          let data2 = new FormData();
          data2.append("operations", `{"query": "mutation ($pasaporte: Upload, $id: Int!) {\\n  uploadPasaporte(id: $id, pasaporte: $pasaporte) {\\n    error  }\\n}","variables": {"id": ${this.id},"pasaporte": null},"operationName": null}`);
          data2.append("map", "{\"1\": [\"variables.pasaporte\"]}");
          data2.append("1", this.file_Pasaporte, this.file_Pasaporte.name);
          return axios.post(`${this.$store.state.url}graphql/`, data2, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }).then(response => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Archivo guardado"
            });
            this.closeFiles();
            this.$apollo.mutate({
              mutation: BUSCAR,
              variables: {
                name: this.misearch
              }
            }).then(response => {
              this.closeFiles();
            });
          });
        });
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
        this.closeFiles();
      });
      this.closeFiles();
    },
    openFiles(id) {
      this.id = id;
      this.fileShow = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    closeFiles() {
      this.$store.state.Cargando = false;
      this.id = 0;
      this.file_Id = null;
      this.file_Licencia = null;
      this.file_Pasaporte = null;
      this.fileShow = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    onID(event) {
      this.file_Id = event.target.files[0];
    },
    onPasaporte(event) {
      this.file_Pasaporte = event.target.files[0];
    },
    onLicencia(event) {
      this.file_Licencia = event.target.files[0];
    },
    closeVer() {
      this.file_Id = null;
      this.file_Pasaporte = null;
      this.file_Licencia = null;
      this.VerShow = false;
    },
    openVer(cliente) {
      if (cliente.carnet && cliente.licenciaFile && cliente.pasaporte) {
        this.file_Id = cliente.carnet ? this.$store.state.store_url + cliente.carnet : "";
        this.file_Licencia = cliente.licenciaFile ? this.$store.state.store_url + cliente.licenciaFile : "";
        this.file_Pasaporte = cliente.pasaporte ? this.$store.state.store_url + cliente.pasaporte : "";
        this.VerShow = true;
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "El cliente no tiene archivos asociados"
        });
      }

    },
    Contrato(item) {
      this.$store.state.anterior = "Clientes";
      this.$store.state.cliente = item;
      this.$router.push("/contratos/nuevo");
    },
    getAddressDataPais: function(addressData, placeResultData, id) {
      this.pais = addressData.country;
    },
    getAddressDataEstado: function(addressData, placeResultData, id) {
      this.estado = addressData.administrative_area_level_1;
    },
    getAddressDataCiudad: function(addressData, placeResultData, id) {
      this.ciudad = addressData.locality;
    },
    getAddressDataEstadoLicencia: function(addressData, placeResultData, id) {
      this.estado_licencia = addressData.administrative_area_level_1;
    },
    getAddressDataPaisLicencia: function(addressData, placeResultData, id) {
      this.paisLicencia = addressData.country;
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}


.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 60%;
  z-index: 9999999999999;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.contenedor {
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.imagen_doc {
  object-fit: cover;
}

@media (max-width: 550px) {
  .modal-content {
    width: 90%;
    margin-top: 700px;
  }
}
</style>
