<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="row">
            <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
              <div class="card-header bg-gradient-warning">
                <h5 class="mb-0 text-white">
                  COBERTURA ISSY TOTAL
                </h5>
              </div>
              <div class="d-flex justify-content-between m-3">
                <div class="col-5">
                  <div class="input-group">
                    <input v-model="search_total" type="text" class="form-control input-icon"
                           placeholder="Buscar cobertura" aria-label="Recipient's username"
                           aria-describedby="button-addon2">
                  </div>
                </div>
                <div class="col-7 text-end">
                  <button @click="openModal('total')"
                          class="btn btn-dark align-content-end" type="submit">Nueva Cobertura
                  </button>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead class="thead-light">
                  <tr class="text-center align-middle">
                    <th>Cobertura</th>
                    <th>Acciones</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center align-middle" v-for="item in coberturasPerTarifaTotal" :key="item.id">
                    <td class="text-sm font-weight-normal">
                      <div :class="item.isActive?'':'text-danger'" class="ms-3 text-center">{{ item.cobertura }}</div>
                    </td>
                    <td>
                      <div class="btn-group pt-3" role="group">
                        <button v-if="item.isActive" @click="DesactiveTotal(item.id)" data-bs-toggle="tooltip"
                                data-bs-placement="top" title="Desactivar cobertura" data-container="body"
                                data-animation="true" class="btn btn-success p-1 ms-1">
                          <i class="material-icons opacity-10">block</i></button>
                        <button v-else @click="ActiveTotal(item.id)" data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Activar cobertura" data-container="body" data-animation="true"
                                class="btn btn-warning p-1 ms-1">
                          <i class="material-icons opacity-10">check_circle</i></button>
                        <button data-bs-toggle="tooltip"
                                @click="openUpdate('total', item)"
                                data-bs-placement="top"
                                title="Editar cobertura" data-container="body" data-animation="true"
                                class="btn btn-info p-1 ms-1">
                          <i class="material-icons opacity-10">edit</i></button>
                        <button @click="Delete('total',item.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                                title="Eliminar cobertura" data-container="body" data-animation="true"
                                class="btn btn-danger p-1 ms-1">
                          <i class="material-icons opacity-10">delete</i></button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
              <div class="card-header bg-gradient-warning">
                <h5 class="mb-0 text-white">
                  COBERTURA ISSY TRADICIONAL
                </h5>
                <p class="mb-0 text-sm">
                </p>
              </div>
              <div class="d-flex justify-content-between m-3">
                <div class="col-5">
                  <div class="input-group">
                    <input v-model="search_tradicional" type="text"
                           class="form-control input-icon"
                           placeholder="Buscar cobertura" aria-label="Recipient's username"
                           aria-describedby="button-addon2">
                  </div>
                </div>
                <div class="col-7 text-end">
                  <button @click="openModal('tradicional')"
                          class="btn btn-dark align-content-end" type="submit">Nueva Cobertura
                  </button>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead class="thead-light">
                  <tr class="text-center align-middle">
                    <th>Cobertura</th>
                    <th>Acciones</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center align-middle" v-for="item in coberturasPerTarifaTradicional" :key="item.id">
                    <td class="text-sm font-weight-normal">
                      <div :class="item.isActive?'':'text-danger'" class="ms-3">{{ item.cobertura }}</div>
                    </td>
                    <td>
                      <div class="btn-group pt-3" role="group">
                        <button v-if="item.isActive" @click="DesactiveTradicional(item.id)" data-bs-toggle="tooltip"
                                data-bs-placement="top" title="Desactivar cobertura" data-container="body"
                                data-animation="true" class="btn btn-success p-1 ms-1">
                          <i class="material-icons opacity-10">block</i></button>
                        <button v-else @click="ActiveTradicional(item.id)" data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Activar cobertura" data-container="body" data-animation="true"
                                class="btn btn-warning p-1 ms-1">
                          <i class="material-icons opacity-10">check_circle</i></button>
                        <button data-bs-toggle="tooltip"
                                @click="openUpdate('tradicional', item)"
                                data-bs-placement="top"
                                title="Editar cobertura" data-container="body" data-animation="true"
                                class="btn btn-info p-1 ms-1">
                          <i class="material-icons opacity-10">edit</i></button>
                        <button @click="Delete('tradicional',item.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                                title="Eliminar cobertura" data-container="body" data-animation="true"
                                class="btn btn-danger p-1 ms-1">
                          <i class="material-icons opacity-10">delete</i></button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nueva cobertura<i @click="closeModal"
                                                                                         class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Cobertura</label>
            <input v-model="cobertura" class="form-control border-bottom border-dark p-2"
                   placeholder="Escriba la cobertura" type="text">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe llenar el campo</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="Nueva" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-pencil-square me-2"></i>Editar cobertura<i @click="closeUpdate"
                                                                                            class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Cobertura</label>
            <input v-model="cobertura" class="form-control border-bottom border-dark p-2"
                   placeholder="Escriba la cobertura" type="text">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe llenar el campo</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="Update" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import { mapState } from "vuex";

const NUEVA_TOTAL = gql`
mutation CreateCoberturaTotal($name:String!) {
    createCoberturaTotal(cobertura: $name) {
        error
        success
    }
}`;
const NUEVA_TRADICIONAL = gql`
mutation CreateCoberturaTradicional($name:String!) {
    createCoberturaTradicional(cobertura: $name) {
        error
        success
    }
}`;
const DELETE_TOTAL = gql`
       mutation DeleteCoberturaTotal($id:Int!) {
    deleteCoberturaTotal(id: $id) {
        error
        success
    }
}`;
const DELETE_TRADICIONAL = gql`
mutation DeleteCoberturaTradicional($id:Int!) {
    deleteCoberturaTradicional(id: $id) {
        error
        success
    }
}`;
const COBERTURAS_TOTALES = gql`
query CoberturasPerTarifaTotal($name:String!) {
    coberturasPerTarifaTotal(name: $name) {
        id
        isActive
        cobertura
    }
}`;
const COBERTURAS_TRADICIONALES = gql`
query CoberturasPerTarifaTradicional($name: String!) {
    coberturasPerTarifaTradicional(name: $name) {
        id
        isActive
        cobertura
    }
}`;
const UPDATE_TOTAL = gql`
mutation UpdateCoberturaTotal($cobertura: String!, $id: Int!) {
    updateCoberturaTotal(cobertura: $cobertura, id: $id) {
        error
        success
    }
}`;
const UPDATE_TRADICIONAL = gql`
mutation UpdateCoberturaTradicional($cobertura: String!, $id: Int!) {
    updateCoberturaTradicional(cobertura: $cobertura, id: $id) {
        error
        success
    }
}`;
const ACTIVE_TOTAL = gql`
mutation ActiveCoberturaTradicional($id:Int!) {
    activeCoberturaTotal(id: $id) {
        error
        success
    }
}`;
const DESACTIVE_TOTAL = gql`
mutation ActiveCoberturaTradicional($id:Int!) {
    desactiveCoberturaTotal(id: $id) {
        error
        success
    }
}`;
const ACTIVE_TRADICIONAL = gql`
mutation ActiveCoberturaTradicional($id:Int!) {
    activeCoberturaTradicional(id: $id) {
        error
        success
    }
}`;
const DESACTIVE_TRADICIONAL = gql`
mutation ActiveCoberturaTradicional($id:Int!) {
    desactiveCoberturaTradicional(id: $id) {
        error
        success
    }
}`;
export default {
  name: "List_Cobertura",
  components: { MaterialButton },
  data() {
    return {
      search_total: "",
      search_tradicional: "",
      marcaPerName: [],
      showModal: false,
      UpdateModal: false,
      cobertura: "",
      tarifa: 0,
      id: 0,
      error: false,
      cobertura_total: null,
      cobertura_tradicional: null,
      coberturasPerTarifaTotal: [],
      coberturasPerTarifaTradicional: [],
      tipo: ""
    };
  },
  apollo: {
    coberturasPerTarifaTotal: {
      query: COBERTURAS_TOTALES,
      variables() {
        return {
          name: this.search_total
        };
      },
      fetchPolicy: "cache-and-network"
    },
    coberturasPerTarifaTradicional: {
      query: COBERTURAS_TRADICIONALES,
      variables() {
        return {
          name: this.search_tradicional
        };
      },
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async Nueva() {
      if (this.cobertura === "") {
        this.error = true;
        return false;
      }
      await this.$apollo.mutate({
        mutation: this.tipo === "total" ? NUEVA_TOTAL : NUEVA_TRADICIONAL,
        variables: {
          name: this.cobertura
        },
        refetchQueries: [
          { query: COBERTURAS_TOTALES, variables: { name: this.search_total } },
          { query: COBERTURAS_TRADICIONALES, variables: { name: this.search_tradicional } }
        ]
      }).then(response => {
        if (this.tipo === "total") {
          if (response.data.createCoberturaTotal.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Cobertura creada"
            });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.createCoberturaTotal.error
            });
          }
          this.closeModal();
        } else {
          if (response.data.createCoberturaTradicional.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Cobertura creada"
            });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.createCoberturaTradicional.error
            });
          }
          this.closeModal();
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      });
    },
    async Update() {
      if (this.cobertura === "") {
        this.error = true;
        return false;
      }
      await this.$apollo.mutate({
        mutation: this.tipo === "total" ? UPDATE_TOTAL : UPDATE_TRADICIONAL,
        variables: {
          id: this.id,
          cobertura: this.cobertura
        },
        refetchQueries: [
          { query: COBERTURAS_TOTALES, variables: { name: this.search_total } },
          { query: COBERTURAS_TRADICIONALES, variables: { name: this.search_tradicional } }
        ]
      }).then(response => {
        if (this.tipo === "total") {
          if (response.data.updateCoberturaTotal.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Cobertura actualizada"
            });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.updateCoberturaTotal.error
            });
          }
          this.closeUpdate();
        } else {
          if (response.data.updateCoberturaTradicional.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Cobertura actualizada"
            });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.updateCoberturaTradicional.error
            });
          }
          this.closeUpdate();
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      });
    },
    async Delete(tipo, id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          await this.$apollo.mutate({
            mutation: tipo === "total" ? DELETE_TOTAL : DELETE_TRADICIONAL,
            variables: {
              id: id
            },
            refetchQueries: [
              { query: COBERTURAS_TOTALES, variables: { name: this.search_total } },
              { query: COBERTURAS_TRADICIONALES, variables: { name: this.search_tradicional } }
            ]
          }).then(response => {
            if (tipo === "total") {
              if (response.data.deleteCoberturaTotal.success) {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  }
                });
                Toast.fire({
                  icon: "error",
                  title: "Cobertura eliminada"
                });
              } else {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  }
                });
                Toast.fire({
                  icon: "error",
                  title: response.data.deleteCoberturaTradicional.error
                });
              }
            } else {
              if (response.data.deleteCoberturaTradicional.success) {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  }
                });
                Toast.fire({
                  icon: "error",
                  title: "Cobertura eliminada"
                });
              } else {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  }
                });
                Toast.fire({
                  icon: "error",
                  title: response.data.deleteCoberturaTradicional.error
                });
              }
            }
          });
        }
      });
    },
    openModal(tipo) {
      this.tipo = tipo;
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    openUpdate(tipo, item) {
      this.tipo = tipo;
      this.cobertura = item.cobertura;
      this.id = item.id;
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.cobertura = "";
      this.tipo = "";
      this.error = false;
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    closeModal() {
      this.cobertura = "";
      this.tipo = "";
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    async DesactiveTotal(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE_TOTAL,
        variables: {
          id: id
        },
        refetchQueries: [{ query: COBERTURAS_TOTALES, variables: { name: this.search_total } }]
      });
      if (data.desactiveCoberturaTotal.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "warning",
          title: "Cobertura desactivada"
        });
      }
    },
    async ActiveTotal(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE_TOTAL,
        variables: {
          id: id
        },
        refetchQueries: [{ query: COBERTURAS_TOTALES, variables: { name: this.search_total } }]
      });
      if (data.activeCoberturaTotal.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "warning",
          title: "Cobertura activada"
        });
      }
    },
    async DesactiveTradicional(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE_TRADICIONAL,
        variables: {
          id: id
        },
        refetchQueries: [{ query: COBERTURAS_TRADICIONALES, variables: { name: this.search_tradicional } }]
      });
      if (data.desactiveCoberturaTradicional.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "warning",
          title: "Cobertura desactivada"
        });
      }
    },
    async ActiveTradicional(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE_TRADICIONAL,
        variables: {
          id: id
        },
        refetchQueries: [{ query: COBERTURAS_TRADICIONALES, variables: { name: this.search_tradicional } }]
      });
      if (data.activeCoberturaTradicional.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "warning",
          title: "Cobertura activada"
        });
      }
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
