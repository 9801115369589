<template>
  <nav
    id="navbarBlur"
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    data-scroll="true"
    :class="isAbsolute ? 'mt-4' : 'mt-4'"
  >
    <div class="px-4 py-1 container-fluid">
      <breadcrumbs :current-page="currentRouteName" :color="color" />
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
      </div>
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
        </div>
        <ul class="navbar-nav justify-content-end">
          <li
            class="nav-item dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
              id="dropdownMenuButton1"
              href="#"
              class="p-0 nav-link lh-1"
              :class="[color, showMenu1 ? 'show' : '']"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu1 = !showMenu1"
            ><p class="d-flex align-items-center align-center justify-content-center"><i
              class="material-icons cursor-pointer me-2">person</i><span class="menus">{{ username }}</span></p>
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu1 ? 'show' : ''"
              aria-labelledby="dropdownMenuButton1"
            >
              <li class="mb-2">
                <router-link :to="{ name: 'Perfil de Usuario' }"
                             class="dropdown-item border-radius-md d-flex align-center">
                  <i class="material-icons cursor-pointer me-2">account_circle</i>
                  Perfil de Usuario
                </router-link>
              </li>
              <li class="mb-2">
                <a @click="logout" class="dropdown-item border-radius-md d-flex align-center">
                  <i class="material-icons cursor-pointer me-2">exit_to_app</i>
                  Cerrar Sesi&oacute;n
                </a>
              </li>
            </ul>
          </li>
          <li
            class="nav-item d-flex align-items-center dropdown"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a href="#" id="menuNotifications"
               class="nav-link align-content-center d-flex align-items-center align-center justify-content-center"
               data-bs-toggle="dropdown" aria-expanded="false"
               :class="[color, showMenu2 ? 'show' : '']" @click="showMenu2 = !showMenu2">
              <p class="d-flex align-items-center align-center justify-content-center">
                <i class="material-icons cursor-pointer"> notifications </i>
                <span v-if="notifications.length > 0" class="contador text-white bg-info cursor-pointer">
                    {{ notifications.length }}
                  </span>
              </p>
            </a>
            <ul class="px-1 py-2 dropdown-menu dropdown-menu-end me-sm-n4" :class="showMenu2 ? 'show' : ''"
                aria-labelledby="menuNotifications">
              <li v-if="notifications.length > 0" class="dropdown-item"
                  v-for="notification in notifications.slice(0, 5)">
                <a class="cursor-pointer"
                   @click="goToReservation(notification.reservation, notification)">
                <div class="card-body d-flex" style="padding-left: 0 !important">
                  <div>
                    <i class="material-icons cursor-pointer" style="font-size:42px;"> circle_notifications </i>
                  </div>
                  <div>
                      <span class="card-title ml-0 mb-0 d-block">
                            <strong>{{ notification.title }}</strong>
                      </span>

                      <span class="card-subtitle d-block">
                            {{ notification.message.length > 50 ? `${notification.message.substring(0, 47)}...` : notification.message
                        }}
                          </span>

                  </div>
                </div>
                </a>
              </li>
              <li v-else>
                <EmptyNotifications size="180" />
              </li>
              <li v-if="notifications.length>5">
                <hr class="dropdown-divider" />
              </li>
              <li class="dropdown-item d-flex" v-if="notifications.length>5">
                <router-link class="ml-auto mr-auto" to="/notifications">Ver m&aacute;s notificaciones</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item align-items-center ms-3"
              :class="isRTL ? 'ps-2' : 'pe-2'">
            <a
              id="iconNavbarSidenav"
              class="nav-link cursor-pointer"
              @click="toggleSidebar"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>


<script>
import MaterialInput from "@/components/MaterialInput.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import gql from "graphql-tag";
import { getNotificationsNotRead, readMessage } from "@/actions";
import { decodeBase64 } from "@/util";
import EmptyNotifications from "@/components/EmptyNotifications.vue";

export default {
  name: "Navbar",
  components: {
    Breadcrumbs,
    MaterialInput,
    EmptyNotifications
  },
  created() {
    this.minNav;
  },
  props: {
    minNav: {
      type: Function,
      default: () => {
      }
    },
    color: {
      type: String,
      default: "text-body"
    }
  },
  data() {
    return {
      showMenu: false,
      showMenu1: false,
      showMenu2: false,
      showMenu3: false,
      username: this.$store.state.username,
      temporada: false,
      socket: new WebSocket(`${process.env.VUE_APP_URL_WS_NOTIFICATIONS}?user=${this.$store.state.userid}`)
    };
  },
  mounted() {
    this.socket.onmessage = (data, event) => {
      const newNotification = JSON.parse(data.data.replaceAll("'", "\""));
      if (!this.$store.state.notifications.find(e => e.id === newNotification.id)) {
        this.$store.state.notifications = [
          { ...newNotification },
          ...this.$store.state.notifications
        ];
        this.createNotification(newNotification.title, newNotification.message);
      }
    };

    this.socket.onopen = (evt) => {
      console.log(evt);
    };

    getNotificationsNotRead().then(resp => {
      this.$store.state.notifications = resp.data.data.searchFirebaseNotifications.edges.map(e => {
        e.node.id = decodeBase64(e.node.id);
        return e.node;
      });
    }).catch(err => console.log(err));
  },
  computed: {
    ...mapState(["isRTL", "isAbsolute", "notificaciones"]),
    currentRouteName() {
      return this.$route.name;
    },
    notifications() {
      return this.$store.state.notifications;
    }
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    toggleSidebar() {
      this.navbarMinimize();
    },
    createNotification(title, message) {
      Notification.requestPermission().then((result) => {
        console.log(result);
        if (result == "granted") {
          const notification = new Notification(title, {
            body: message,
            icon: process.env.VUE_APP_URL_IMAGE_NOTIFICATIONS,
            image: process.env.VUE_APP_URL_IMAGE_NOTIFICATIONS
          });
        }
      });
    },
    logout() {
      this.$store.state.isAuth = false;
      this.$store.state.userid = null;
      this.$store.state.userSucursal = null;
      this.$store.state.username = null;
      this.$store.state.email = null;
      this.$store.state.firstName = null;
      this.$store.state.lastName = null;
      this.$store.state.rol = null;
      this.$router.push("/");
      this.$store.Cargando = false;
    },
    goToReservation(item, notify) {
      readMessage(notify)
      getNotificationsNotRead().then(resp => {
        this.$store.state.notifications = resp.data.data.searchFirebaseNotifications.edges.map(e => {
          e.node.id = decodeBase64(e.node.id);
          return e.node;
        });
      }).catch(err => console.log(err));
      if (item !== null) {
        console.log(item)
        this.$store.state.reserva = item;
        this.$store.state.reserva_notification = true;
        if (item.origen === "Administración") {
          this.$router.push({ name: "Reservas desde la Administración" });
        } else {
          this.$router.push({ name: "Reservas desde el Portal Web" });
        }
      }
    }
  }
}
;
</script>

<style scoped>
.contador {
  margin-top: -10px;
  margin-left: -2px;
  border-radius: 50%;
  font-size: 13px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boton-notificar {
  display: flex;
}

.temp-grande {
  display: flex;
}

.temp-pequeña {
  display: none;
}

@media (max-width: 550px) {
  .menus {
    display: none;
    margin-right: 0;
  }

  .contador {
    margin-top: -18px;
    margin-left: -15px;
  }

  .temp-grande {
    display: none;
  }

  .temp-pequeña {
    display: flex;
  }
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}
</style>
