<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de Usuarios
            </h5>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar usuario" aria-describedby="button-addon2"
                       id="search_user">
              </div>
            </div>
            <div class="col-9 text-end">
              <button @click="openAdd" class="btn btn-dark align-content-end" type="submit">Nuevo Usuario</button>
            </div>
          </div>
          <div class="table-responsive">
            <table id="users" class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Nombre</th>
                <th>Sucursal</th>
                <th>Correo</th>
                <th>Rol</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in usersPerName" :key="item.id" class="text-center align-middle">
                <td>
                  {{ item.firstName }}
                </td>
                <td>
                  {{ item.sucursal ? item.sucursal.name : "Sin Sucursal" }}
                </td>
                <td>
                  {{ item.email }}
                </td>
                <td>
                  {{ item.groups ? item.groups.name : "Sin Rol" }}
                </td>
                <td>
                  <div v-if="$store.state.rol==='Super Administrador'" class="btn-group pt-3" role="group">
                    <button data-bs-toggle="tooltip" data-bs-placement="top" title="Desactivar Usuario"
                            data-container="body" data-animation="true" @click="desactiveUser(item.id)"
                            v-if="item.isActive" class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">block</i></button>
                    <button data-bs-toggle="tooltip" data-bs-placement="top" title="Activar Usuario"
                            data-container="body" data-animation="true" @click="activeUser(item.id)" v-else
                            class="btn btn-warning p-1 ms-1">
                      <i class="material-icons opacity-10">check_circle</i></button>
                    <button data-bs-toggle="tooltip" data-bs-placement="top" title="Editar Usuario"
                            data-container="body" data-animation="true"
                            @click="openUpdate(item.id, item.username, item.firstName, item.lastName, item.email,
                              item.ciudad!==null?item.ciudad.id:0, item.groups!==null?item.groups.id:0,
                              item.sucursal!==null?item.sucursal.id:0, item.phone)"
                            class="btn btn-info  p-1 ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                    <button @click="openUser(item.username, item.firstName, item.lastName, item.email,
                              item.ciudad!==null?item.ciudad.name:0, item.groups!==null?item.groups.name:0,
                              item.sucursal!==null?item.sucursal.name:0, item.phone)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Detalles del Usuario" data-container="body" data-animation="true"
                            class="btn btn-success  p-1 ms-1">
                      <i class="material-icons opacity-10">visibility</i></button>
                    <button data-bs-toggle="tooltip" data-bs-placement="top" title="Cambiar Contraseña"
                            data-container="body" data-animation="true" @click="openPassword(item.id)"
                            class="btn btn-dark  p-1 ms-1">
                      <i class="material-icons opacity-10">vpn_key</i></button>
                    <button data-bs-toggle="tooltip" data-bs-placement="top" title="Eliminar Usuario"
                            data-container="body" data-animation="true" @click="DeleteUser(item.id)"
                            class="btn btn-danger  p-1 ms-1">
                      <i class="material-icons opacity-10">delete</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModel">
    <div v-if="showUser" :class="['modal', { 'show': showUser }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="h-100">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles del Usuario<i @click="closeUser"
                                                                                         class="material-icons-round opacity-10 modal-icon">close</i>
            </h4>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <div class="col-6">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Nombre Completo:</strong> &nbsp;
                    {{ this.firstName }} {{ this.lastName }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Teléfono:</strong> &nbsp; {{ this.phone }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Correo:</strong> &nbsp; {{ this.email }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Usuario:</strong> &nbsp;
                    {{ this.username }}
                  </li>
                </ul>
              </div>
              <div class="col-6">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Rol:</strong> &nbsp;
                    {{ this.rolName }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Sucursal:</strong> &nbsp; {{ this.sucursalName }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Ciudad:</strong> &nbsp; {{ this.ciudadName }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row text-end">
              <div class="col-8"></div>
              <div class="col-4">
                <button @click="closeUser" class="mt-3 btn btn-dark text-end">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModel">
    <div v-if="showAdd" :class="['modal', { 'show': showAdd }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nuevo Usuario<i @click="closeAdd"
                                                                                       class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-lg-5 col-md-12 col-sm-12">
            <label class="form-label">Nombre</label><input autofocus v-model="firstName"
                                                           class="form-control border-bottom border-dark mb-3 p-2">
            <label class="form-label">Apellidos</label><input v-model="lastName"
                                                              class="form-control border-bottom border-dark mb-3 p-2">
            <label class="form-label">Usuario</label><input v-model="username"
                                                            class="form-control border-bottom border-dark mb-3 p-2">
            <label class="form-label">Correo</label><input autocomplete="new-password" v-model="email" type="email"
                                                           class="form-control border-bottom border-dark mb-3 p-2">
            <label class="form-label">Contraseña</label><input autocomplete="new-password" v-model="password"
                                                               type="password"
                                                               class="form-control border-bottom border-dark p-2">
          </div>
          <div class="col-lg-5 col-md-12 col-sm-12"></div>
          <div class="col-lg-5 col-md-12 col-sm-12">
            <label class="form-label">Seleccione el Rol</label>
            <select v-model="group" class="form-select mb-3 p-2">
              <option v-for="group in allGroups" :value="group.id">{{ group.name }}</option>
            </select>
            <label class="form-label">Seleccione la Sucursal</label>
            <select v-model="sucursal" class="form-select mb-3 p-2">
              <option v-for="sucurs in allSucursales" :value="sucurs.id" v-show="sucurs.isActive">{{ sucurs.name }}
              </option>
            </select>
            <label class="form-label">Seleccione la Ciudad</label>
            <select v-model="ciudad" class="form-select mb-3 p-2">
              <option v-for="city in allCiudades" :value="city.id" v-show="city.isActive">{{ city.name }}</option>
            </select>
            <label class="form-label">Número de Teléfono</label><input v-model="phone" type="tel" maxlength="10"
                                                                       class="form-control border-bottom border-dark mb-3 p-2">
            <label class="form-label">Confirmar Contraseña</label><input autocomplete="new-password" v-model="password1"
                                                                         type="password"
                                                                         class="form-control border-bottom border-dark p-2">
          </div>
          <div v-show="errorAddEmpty" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">No pueden haber campos vacíos</p></div>
          </div>
          <div v-show="errorAdd" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Las contraseñas no coinciden</p></div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeAdd" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="addUser" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModel">
    <div v-if="showUpdate" :class="['modal', { 'show': showUpdate }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-pencil-square me-2"></i>Editar Usuario<i @click="closeUpdate"
                                                                                          class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-5">
            <label class="form-label">Nombre</label><input autofocus v-model="firstName"
                                                           class="form-control border-bottom border-dark mb-3 p-2">
            <label class="form-label">Apellidos</label><input v-model="lastName"
                                                              class="form-control border-bottom border-dark mb-3 p-2">
            <label class="form-label">Usuario</label><input v-model="username"
                                                            class="form-control border-bottom border-dark mb-3 p-2">
            <label class="form-label">Correo</label><input autocomplete="new-password" v-model="email" type="email"
                                                           class="form-control border-bottom border-dark p-2">
          </div>
          <div class="col-2"></div>
          <div class="col-5">
            <label class="form-label">Seleccione el Rol</label>
            <select v-model="group" class="form-select mb-3 p-2">
              <option v-for="group in allGroups" :value="group.id">{{ group.name }}</option>
            </select>
            <label class="form-label">Seleccione la Sucursal</label>
            <select v-model="sucursal" class="form-select mb-3 p-2">
              <option v-for="sucurs in allSucursales" :value="sucurs.id" v-show="sucurs.isActive">{{ sucurs.name }}
              </option>
            </select>
            <label class="form-label">Seleccione la Ciudad</label>
            <select v-model="ciudad" class="form-select mb-3 p-2">
              <option v-for="city in allCiudades" :value="city.id" v-show="city.isActive">{{ city.name }}</option>
            </select>
            <label class="form-label">Número de Teléfono</label><input v-model="phone" type="tel" maxlength="10"
                                                                       class="form-control border-bottom border-dark p-2">
          </div>
          <div v-show="errorAddEmpty" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">No pueden haber campos vacíos</p></div>
          </div>
          <div v-show="errorAdd" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">El campo de teléfono tiene solo puede contener números</p></div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="updateUsuario" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModel">
    <div v-if="showPassword" :class="['modal', { 'show': showPassword }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="material-icons opacity-10 me-2">vpn_key</i>Restablecer contraseña<i
            @click="closePassword"
            class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Nueva contraseña</label>
            <input @focus="misearch=''" type="password"
                   autocomplete="new-password" v-model="password" class="form-control mb-3"
            >
          </div>
          <div class="col-12">
            <label class="form-label">Repita la contraseña</label>
            <input @focus="misearch=''" type="password"
                   autocomplete="new-password" v-model="password1" class="form-control mb-3"
            >
          </div>
          <div v-show="empty_password" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">No pueden haber campos vacíos</p></div>
          </div>
          <div v-show="diff_password" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Las contraseñas no coinciden</p></div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closePassword" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="changePassword" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import { DataTable } from "simple-datatables";


const DESACTIVE_USER = gql`
mutation($id:ID!){
  desactiveUser(id:$id){
    success
  }
}
`;
const BUSCAR_USER = gql`
          query UsersPerName($name:String!) {
    usersPerName(username: $name) {
        id
        username
        firstName
        lastName
        email
        phone
        isActive
        groups {
            id
            name
        }
        sucursal {
            id
            name
            isActive
        }
        ciudad {
            id
            name
            isActive
        }
    }
}
`;
const ACTIVE_USER = gql`
mutation($id:ID!){
  activeUser(id:$id){
    success
  }
}
`;
const INSERT_USER = gql`
  mutation ($username: String!, $email: String!, $firstName: String!, $lasName: String!,
   $password: String!, $password1: String!, $ciudad: ID!, $sucursal: ID!, $group: ID!, $phone: String!) {
    register(
        firstName: $firstName
        email: $email
        lastName: $lasName
        username: $username
        password: $password
        password1: $password1
        groups: $group
        ciudad: $ciudad
        sucursal: $sucursal
        phone: $phone
    ) {
        success
        errors
    }
}
`;
const UPDATE_USER = gql`
  mutation($id:ID!,$username: String!, $email: String!, $firstName: String!,
   $lasName: String!, $ciudad: ID!, $sucursal: ID!, $group: ID!, $phone: String!){
    updateUser(
        id:$id
        firstName: $firstName
        email: $email
        lastName: $lasName
        username: $username
        groups: $group
        ciudad: $ciudad
        sucursal: $sucursal
        phone: $phone
    ){
      success
      errors
    }
  }`;
const RESET_PASSWORD = gql`
mutation ResetPassword ($id:ID!, $password:String!, $password1:String!) {
    resetPassword(id: $id, password: $password, password1: $password1) {
        success
        errors
    }
}
`;
const ALL_ROLES = gql`
query AllGroups {
    allGroups {
        id
        name
    }
}
`;
const DELETE_USER = gql`
            mutation($id:ID!){
              deleteUser(id:$id){
                success
              }
            }
        `;
const ALL_CITYS = gql`
query AllCiudades {
    allCiudades {
        id
        name
        isActive
    }
}
`;
const ALL_SUCURSALES = gql`
query AllSucursales {
    allSucursales {
        id
        name
        isActive
    }
}
`;
export default {
  name: "List_Users",
  components: { MaterialButton },
  data() {
    return {
      misearch: "",
      usersPerName: [],
      allGroups: [],
      allCiudades: [],
      allSucursales: [],
      showUser: false,
      showAdd: false,
      errorAddEmpty: false,
      showUpdate: false,
      userID: 0,
      groupID: 0,
      errorRol: false,
      errorAdd: false,
      errorUpdate: false,
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      phone: "",
      rol: "",
      ciudad: 0,
      sucursal: 0,
      password: "",
      password1: "",
      group: 0,
      rolName: "",
      sucursalName: "",
      ciudadName: "",

      showPassword: false,
      empty_password: false,
      diff_password: false
    };
  },
  watch: {
    password() {
      this.misearch = "";
    },
    password1() {
      this.misearch = "";
    }
  },
  apollo: {
    usersPerName: {
      query: BUSCAR_USER,
      variables() {
        return {
          name: this.misearch
        };
      },
      fetchPolicy: "cache-and-network"
    },
    allGroups: {
      query: ALL_ROLES,
      fetchPolicy: "cache-and-network"
    },
    allCiudades: {
      query: ALL_CITYS,
      fetchPolicy: "cache-and-network"
    },
    allSucursales: {
      query: ALL_SUCURSALES,
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async addUser() {
      this.errorAddEmpty = false;
      this.errorAdd = false;
      if (this.firstName === "" || this.lastName === "" || this.username === "" || this.email === "" || this.group === 0 ||
        this.password === "" || this.password1 === "" || this.phone === "" || this.ciudad === 0 || this.sucursal === 0) {
        this.errorAddEmpty = true;
      } else if (this.password !== this.password1) {
        this.errorAdd = true;
      } else if (/^\d+$/.test(this.phone) === false) {
        this.errorAdd = true;
      } else {
        this.$apollo.mutate({
          mutation: INSERT_USER,
          variables: {
            firstName: this.firstName,
            email: this.email,
            lasName: this.lastName,
            username: this.username,
            password: this.password,
            password1: this.password1,
            group: this.group,
            ciudad: this.ciudad,
            sucursal: this.sucursal,
            phone: this.phone
          },
          refetchQueries: [{ query: BUSCAR_USER, variables: { name: this.misearch } }]
        }).then(response => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          this.showAdd = false;
          this.firstName = "";
          this.lastName = "";
          this.username = "";
          this.email = "";
          this.phone = "";
          this.rol = "";
          this.ciudad = 0;
          this.sucursal = 0;
          this.password = "";
          this.password1 = "";
          this.group = 0;
          Toast.fire({
            icon: "success",
            title: "Usuario creado"
          });
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });

          Toast.fire({
            icon: "error",
            title: "Error al crear el usuario"
          });
          console.error("La mutación falló:", error);
        });
      }
    },
    async updateUsuario() {
      this.errorAddEmpty = false;
      this.errorAdd = false;
      if (this.firstName === "" || this.lastName === "" || this.username === "" || this.email === "" || this.group === 0 ||
        this.phone === "" || this.ciudad === 0 || this.sucursal === 0) {
        this.errorAddEmpty = true;
      } else if (/^\d+$/.test(this.phone) === false) {
        this.errorAdd = true;
      } else {
        await this.$apollo
          .mutate({
            mutation: UPDATE_USER,
            variables: {
              id: this.userID,
              username: this.username,
              firstName: this.firstName,
              lasName: this.lastName,
              email: this.email,
              ciudad: this.ciudad,
              group: this.group,
              sucursal: this.sucursal,
              phone: this.phone
            },
            refetchQueries: [{ query: BUSCAR_USER, variables: { name: this.misearch } }]
          }).then(response => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            this.showUpdate = false;
            this.firstName = "";
            this.lastName = "";
            this.username = "";
            this.email = "";
            this.phone = "";
            this.rol = "";
            this.ciudad = 0;
            this.sucursal = 0;
            this.password = "";
            this.password1 = "";
            this.group = 0;
            this.userID = 0;
            Toast.fire({
              icon: "success",
              title: "Usuario editado"
            });
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Error al actualizar el usuario"
            });
            console.error("La mutación falló:", error);
          });
      }
    },
    async DeleteUser(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE_USER,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR_USER, variables: { name: this.misearch } }]
          });
          if (data.deleteUser.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });

            Toast.fire({
              icon: "error",
              title: "Usuario eliminado"
            });
          }
        }
      });
    },
    async desactiveUser(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE_USER,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR_USER, variables: { name: this.misearch } }]
      });
      if (data.desactiveUser.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "warning",
          title: "Usuario desactivado"
        });
      }
    },
    async activeUser(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE_USER,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR_USER, variables: { name: this.misearch } }]
      });
      if (data.activeUser.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Usuario activado"
        });
      }
    },
    openUser(usuario, nombre, apellidos, correo, ciudad, group, sucursal, phone) {
      this.firstName = nombre;
      this.lastName = apellidos;
      this.username = usuario;
      this.email = correo;
      this.phone = phone;
      this.rol = group;
      this.ciudadName = ciudad;
      this.sucursalName = sucursal;
      this.rolName = group;
      this.showUser = true;
      this.errorRol = false;
      document.body.classList.add("modal-open");
    },
    closeUser() {
      this.firstName = "";
      this.lastName = "";
      this.username = "";
      this.email = "";
      this.phone = "";
      this.rol = "";
      this.password = "";
      this.password1 = "";
      this.rolName = "";
      this.sucursalName = "";
      this.groupName = "";
      this.showUser = false;
      this.errorRol = false;
      document.body.classList.remove("modal-open");
    },
    openAdd() {
      this.showAdd = true;
      this.errorAdd = false;
      document.body.classList.add("modal-open");
    },
    closeAdd() {
      this.showAdd = false;
      this.errorAdd = false;
      document.body.classList.remove("modal-open");
    },
    openUpdate(id, usuario, nombre, apellidos, correo, ciudad, group, sucursal, phone) {
      this.firstName = nombre;
      this.lastName = apellidos;
      this.username = usuario;
      this.email = correo;
      this.phone = phone;
      this.rol = group;
      this.ciudad = ciudad;
      this.sucursal = sucursal;
      this.group = group;
      this.userID = id;
      this.showUpdate = true;
      this.errorUpdate = false;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.showUpdate = false;
      this.errorUpdate = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showUser) {
        this.$emit("close");
      }
    },
    openPassword(id) {
      this.diff_password = false;
      this.empty_password = false;
      this.userID = id;
      this.showPassword = true;
      document.body.classList.add("modal-open");
    },
    closePassword() {
      this.diff_password = false;
      this.empty_password = false;
      this.password = "";
      this.password1 = "";
      this.showPassword = false;
      document.body.classList.remove("modal-open");
    },
    changePassword() {
      if (this.password === "" || this.password1 === "") {
        this.empty_password = true;
        return false;
      }
      if (this.password !== this.password1) {
        this.diff_password = true;
        return false;
      }
      this.$apollo
        .mutate({
          mutation: RESET_PASSWORD,
          variables: {
            password: this.password,
            password1: this.password1,
            id: this.userID
          },
          refetchQueries: [{ query: BUSCAR_USER, variables: { name: this.misearch } }]
        }).then(response => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Contraseña cambiada"
        });
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Error al cambiar la contraseña" + error
        });
        console.error("La mutación falló:", error);
      });
      this.closePassword();
    }
  }
}
;
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

@media (max-width: 550px) {
  .modal-content {
    width: 90%;
    margin-top: 20%;
  }
}
</style>
