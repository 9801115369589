<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="row">
        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 mb-2">
          <label class="form-label">No. Económico</label>
          <input v-model="no_eco" type="text" class="form-control" />
        </div>
        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 mb-2">
          <label class="form-label">Categoría</label>
          <select v-model="categoria" class="form-select">
            <option selected disabled value="0">Seleccione una Categoría</option>
            <option v-for="item in allCategoriasAdmin" :value="item.id" v-show="item.isActive">{{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 mb-2">
          <label class="form-label">Marca</label>
          <select v-model="marca" class="form-select">
            <option selected disabled value="0">Seleccione una Marca</option>
            <option v-for="marca in allMarcas" :value="marca.id" v-show="marca.isActive">{{ marca.name }}
            </option>
          </select>
        </div>
        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 mb-2">
          <label class="form-label">Modelo</label>
          <select v-model="modelo" class="form-select" v-bind:disabled="marca === 0">
            <option selected disabled value="0">Seleccione un Modelo</option>
            <option v-for="item in modeloPerMarca" :value="item.id" v-show="item.isActive">{{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 mb-2">
          <label class="form-label">Año</label>
          <input v-model="anno" type="text" class="form-control" />
        </div>
        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 mb-2">
          <label class="form-label">Versión</label>
          <select v-model="version" class="form-select" v-bind:disabled="modelo === 0">
            <option selected disabled value="0">Seleccione una Versión</option>
            <option v-for="item in versionPerModelo" :value="item.id" v-show="item.isActive">{{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 mb-2">
          <label class="form-label">Color</label>
          <input v-model="color" type="text" class="form-control" />
        </div>
        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 mb-2">
          <label class="form-label">No. Serie</label>
          <input v-model="no_serie" type="text" class="form-control" />
        </div>
        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 mb-2">
          <label class="form-label">Placa</label>
          <input v-model="placa" type="text" class="form-control" />
        </div>
        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 mb-2">
          <label class="form-label">Cantidad del tanque(Litros)</label>
          <input v-model="capacidad" type="number" min="0" class="form-control" />
        </div>
      </div>
      <div v-show="this.$parent.error" class="text-danger mt-3 text-center p-2"
           style="background-color: rgba(255,19,28,0.08)">
        <div class="d-flex align-center justify-content-center"><i
          class="material-icons-round opacity-10 me-2">error</i>
          <p class="text-dark">Debe llenar todos los campos</p></div>
      </div>
      <div v-show="this.$parent.placa_error" class="text-danger mt-3 text-center p-2"
           style="background-color: rgba(255,19,28,0.08)">
        <div class="d-flex align-center justify-content-center"><i
          class="material-icons-round opacity-10 me-2">error</i>
          <p class="text-dark">La placa solo debe contener letras y números</p></div>
      </div>
      <div class="button-row d-flex mt-4">
        <div class="col-6">
          <material-button
            type="button"
            color="secondary"
            variant="gradient"
            class="ms-auto"
            @click="this.$router.push('/vehiculos/listado')"
          >Cancelar
          </material-button
          >
        </div>
        <div class="col-6 text-end">
          <material-button
            type="button"
            color="dark"
            variant="gradient"
            class="ms-auto js-btn-next"
            @click="this.$parent.nextStep(this.anno, this.version, this.categoria, this.capacidad, this.color, this.no_serie, this.no_eco,this.placa )"
          >Siguiente
          </material-button
          >
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import Choices from "choices.js";
import marker from "jsvectormap/src/js/elements/marker";
import gql from "graphql-tag";

const ALLCATEGORIAS = gql`
query AllCategorias {
    allCategoriasAdmin {
        id
        name
        isActive
    }
}

`;
const ALLMARCAS = gql`
query AllMarcas {
    allMarcas {
        id
        name
        isActive
    }
}

`;
const MODELOPERMARCA = gql`
query ModeloPerMarca($id:ID!) {
    modeloPerMarca(id: $id) {
        id
        name
        isActive
    }
}
`;
const VERSIOPERMODELO = gql`
query VersionPerModelo($id:ID!) {
    versionPerModelo(id: $id) {
        id
        name
        isActive
    }
}
`;

export default {
  name: "Address",
  computed: {
    marker() {
      return marker;
    }
  },
  components: {
    MaterialInput,
    MaterialButton
  },
  data() {
    return {
      color: "",
      no_serie: "",
      placa: "",
      no_eco: "",
      capacidad: 0,
      anno: "",
      version: 0,
      categoria: 0,
      marca: 0,
      modelo: 0,
      allCategoriasAdmin: [],
      allMarcas: [],
      modeloPerMarca: [],
      versionPerModelo: []
    };
  },
  apollo: {
    allCategoriasAdmin: {
      query: ALLCATEGORIAS,
      fetchPolicy: "cache-and-network"
    },
    allMarcas: {
      query: ALLMARCAS,
      fetchPolicy: "cache-and-network"
    },
    modeloPerMarca: {
      query: MODELOPERMARCA,
      variables() {
        return {
          id: this.marca
        };
      },
      fetchPolicy: "cache-and-network"
    },
    versionPerModelo: {
      query: VERSIOPERMODELO,
      variables() {
        return {
          id: this.modelo
        };
      },
      fetchPolicy: "cache-and-network"
    }
  },
  created() {
    this.categoria = this.$store.state.categoria_vehiculo!==null?this.$store.state.categoria_vehiculo.id:0;
  },
  mounted() {
    if (document.getElementById("choices-state")) {
      var element = document.getElementById("choices-state");
      new Choices(element, {
        searchEnabled: false
      });
    }
  }
};
</script>

<style></style>
