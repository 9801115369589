<template xmlns="http://www.w3.org/1999/html">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de Vehículos
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="d-flex justify-content-between m-3 row">
            <div class="col-3">
            </div>
            <div class="col-5"></div>
            <div class="col-4 text-end">
              <select v-model="status" class="form-control ps-2 border-dark border-bottom align-content-end">
                <option value="0">Todos</option>
                <option value="1">Auto Contratado</option>
                <option value="2">Auto Rentado</option>
                <option value="3">Auto Disponible</option>
              </select>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped" id="miTabla">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Foto</th>
                <th>Categoria</th>
                <th>Sucursal</th>
                <th>Estatus</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="auto in vehiculosPerStatus" :key="auto.id"
                  :class="auto.isActive?'':'text-danger'">
                <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
                  <div class="ms-3"><img class="rounded-circle" style="height: 60px; width: 90px"
                                         :src="this.$store.state.store_url+auto.imagen"></div>
                </td>
                <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
                  {{ auto.categoria.name }}
                </td>
                <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
                  {{ auto.sucursal.name }}
                </td>
                <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
                  <div
                    v-bind:class="{ 'bg-danger': auto.estatus === 'Contratado', 'bg-success': auto.estatus === 'Disponible', 'bg-info': status === 'Rentado' }"
                    class="text-white">{{ auto.estatus }}
                  </div>
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button v-if="auto.estatus === 'Disponible'" @click="Contrato(auto)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Crear contrato" data-container="body"
                            data-animation="true" class="btn btn-primary p-1 ms-1">
                      <i class="material-icons opacity-10">description</i></button>
                    <!--                    <button v-else @click="ActiveVehiculo(auto.id)" data-bs-toggle="tooltip"-->
                    <!--                            data-bs-placement="top"-->
                    <!--                            title="Activar Vehículo" data-container="body" data-animation="true"-->
                    <!--                            class="btn btn-success p-1 ms-1">-->
                    <!--                      <i class="material-icons opacity-10">check_circle</i></button>-->
                    <!--                    <button @click="EditarCar(auto.id)"-->
                    <!--                            data-bs-toggle="tooltip"-->
                    <!--                            data-bs-placement="top"-->
                    <!--                            title="Editar Vehículo" data-container="body" data-animation="true"-->
                    <!--                            class="btn btn-info p-1 ms-1">-->
                    <!--                      <i class="material-icons opacity-10">edit</i></button>-->
                    <!--                    <button-->
                    <!--                      @click="openImagen(auto.imagen, auto.id)"-->
                    <!--                      data-bs-toggle="tooltip"-->
                    <!--                      data-bs-placement="top"-->
                    <!--                      title="Agregar o Actualizar Imagen de Vehículo" data-container="body" data-animation="true"-->
                    <!--                      class="btn btn-secondary p-1 ms-1">-->
                    <!--                      <i class="material-icons opacity-10">add_photo_alternate</i></button>-->
                    <button @click="openCar(auto)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Detalles del Vehículo" data-container="body" data-animation="true"
                            class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">visibility</i></button>
                    <!--                    <button @click="openFechas(auto.fechaCredito, auto.fechaPoliza, auto.fechaCirculacion, auto.id)"-->
                    <!--                            data-bs-toggle="tooltip" data-bs-placement="top"-->
                    <!--                            title="Ver o cambiar fechas de vencimiento" data-container="body" data-animation="true"-->
                    <!--                            class="btn p-1 ms-1"-->
                    <!--                            v-bind:class="Vencido(auto.fechaCredito, auto.fechaPoliza, auto.fechaCirculacion) ? 'btn-danger' : 'btn-success'">-->
                    <!--                      <i class="material-icons opacity-10">schedule</i></button>-->
                    <!--                    <button @click="DeleteVehiculo(auto.id)" data-bs-toggle="tooltip" data-bs-placement="top"-->
                    <!--                            title="Eliminar Vehículo" data-container="body" data-animation="true"-->
                    <!--                            class="btn btn-danger p-1 ms-1">-->
                    <!--                      <i class="material-icons opacity-10">delete</i></button>-->
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showCar" :class="['modal', { 'show': showCar }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="h-100">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles del Vehículo<i @click="closeCar"
                                                                                          class="material-icons-round opacity-10 modal-icon">close</i>
            </h4>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <div class="col-4">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">No. Económico:</strong> &nbsp;
                    {{ this.car.noEcon }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Categoría:</strong> &nbsp; {{ this.car.categoria.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Año:</strong> &nbsp; {{ this.car.anno }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Marca:</strong> &nbsp;
                    {{ this.car.version.modelo.marca.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Modelo:</strong> &nbsp;
                    {{ this.car.version.modelo.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Versión:</strong> &nbsp;
                    {{ this.car.version.name }}
                  </li>
                </ul>
              </div>
              <div class="col-4">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Titular:</strong> &nbsp;
                    {{ this.car.titular.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Aseguradora:</strong> &nbsp; {{ this.car.aseguradora.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Placa:</strong> &nbsp; {{ this.car.placa }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Estatus:</strong> &nbsp;
                    {{ this.car.estatus }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Cap. del Tanque:</strong> &nbsp;
                    {{ this.car.capacidadTanke }} lts
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Estado Emplacado:</strong> &nbsp;
                    {{ this.car.estado.name }}
                  </li>
                </ul>
              </div>
              <div class="col-4">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Color:</strong> &nbsp;
                    {{ this.car.color }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Número de Serie:</strong> &nbsp;
                    {{ this.car.numeroSerie }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Fecha de Alta:</strong> {{ this.car.fechaAlta }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Transmisión:</strong> {{ this.car.transmision }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Mínimo:</strong> &nbsp;
                    {{ this.car.minimo }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Sucursal:</strong> &nbsp;
                    {{ this.car.sucursal.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row text-end">
              <div class="col-8"></div>
              <div class="col-4">
                <button @click="closeCar" class="mt-3 btn btn-dark text-end">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import "datatables.net";
import { DataTable } from "simple-datatables";
import axios from "axios";
import moment from "moment";

const BUSCAR = gql`
          query VehiculosPerStatus($status:Int!) {
    vehiculosPerStatus(status: $status) {
        id
        noEcon
        anno
        transmision
        vin
        imagen
        fechaCredito
        fechaCirculacion
        fechaPoliza
        placa
        estado{
        name
        }
        minimo
        sucursal{
        name
        }
        estatus
        isActive
        numeroSerie
        color
        capacidadTanke
        fechaAlta
        aseguradora {
            name
        }
        titular {
            name
        }
        categoria {
            id
            name
            }
        version{
          id
          name
          modelo{
            id
            name
            marca{
              id
              name
              }
          }
        }
      }
    }`;

export default {
  name: "List_Categorias",
  components: { MaterialButton },
  data() {
    return {
      misearch: "",
      vehiculosPerStatus: [],
      showModal: false,
      showFechas: false,
      showCar: false,
      error: false,
      fechaCirculacion: null,
      fechaCredito: null,
      fechaPoliza: null,
      estatus: "",
      status: 0,
      carid: 0,
      preview: null,
      imagen: null,
      car: null,
      ImageModal: false,
      ImageUpdateModal: false
    };
  },
  apollo: {
    vehiculosPerStatus: {
      query: BUSCAR,
      variables() {
        return {
          status: this.status
        };
      },
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async DeleteVehiculo(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
          });
          if (data.deleteVehiculo.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Vehículo eliminado"
            });
          }
        }
      });
    },
    async DesactiveVehiculo(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      if (data.desactiveVehiculo.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "warning",
          title: "Vehículo desactivado"
        });
      }
    },
    async ActiveVehiculo(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      if (data.activeVehiculo.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Vehículo activado"
        });
      }
    },
    openImagen(imagen, id) {
      if (imagen) {
        this.preview = this.$store.state.store_url + imagen;
      }
      this.carid = id;
      this.ImageModal = true;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    guardarImagen() {
      if (this.imagen === null) {
        this.error = true;
        return false;
      }
      let data = new FormData();
      data.append("operations", `{"query": "mutation ($file: Upload, $id: ID) {\\n  uploadImageVehiculo(id: $id, file: $file) {\\n    error  }\\n}","variables": {"id": ${this.carid},"file": null},"operationName": null}`);
      data.append("map", "{\"1\": [\"variables.file\"]}");
      data.append("1", this.imagen, this.imagen.name);
      return axios.post(`${this.$store.state.url}graphql/`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(resp => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Imagen guardada"
        });
        this.$apollo.mutate({
          mutation: BUSCAR,
          variables: {
            name: this.misearch
          }
        }).then(response => {
        });
        this.closeImageModal();
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      });
    },
    async guardarFechas() {
      if (this.fechaPoliza === null || this.fechaCredito === null || this.fechaCirculacion === null) {
        this.error = true;
        return false;
      }
      await this.$apollo.mutate({
        mutation: FECHAS,
        variables: {
          fechaCirculacion: this.fechaCirculacion,
          fechaCredito: this.fechaCredito,
          fechaPoliza: this.fechaPoliza,
          id: this.carid
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      }).then(response => {
        if (response.data.fechasvehiculos.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Fechas guardadas"
          });
          this.closeFechas();
        } else {
          this.closeFechas();
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.fechasvehiculos.errors
          });
        }
      });
    },
    closeImageModal() {
      this.imagen = null;
      this.preview = null;
      this.ImageModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    EditarCar(id) {
      this.$store.state.carid = id;
      this.$router.push("editar");
    },
    openModal(id) {
      this.carid = id;
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    openFechas(fechaCredito, fechaPoliza, fechaCirculacion, id) {
      this.fechaCredito = fechaCredito;
      this.fechaPoliza = fechaPoliza;
      this.fechaCirculacion = fechaCirculacion;
      this.carid = id;
      this.showFechas = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    async openCar(car) {
      this.car = car;
      this.showCar = true;
      document.body.classList.add("modal-open");
    },
    closeCar() {
      this.showCar = false;
      document.body.classList.remove("modal-open");
    },
    closeFechas() {
      this.showFechas = false;
      this.fechaCredito = null;
      this.fechaPoliza = null;
      this.fechaCirculacion = null;
      document.body.classList.remove("modal-open");
    },
    closeModal() {
      this.carid = 0;
      this.estatus = "";
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    onImagenSeleccionada(event) {
      this.imagen = event.target.files[0];
      this.preview = URL.createObjectURL(this.imagen);
    },
    Vencido(fechaCredito, fechaPoliza, fechaCirculacion) {
      return fechaCirculacion <= moment().format("YYYY-MM-DD") ||
        fechaPoliza <= moment().format("YYYY-MM-DD") ||
        fechaCredito <= moment().format("YYYY-MM-DD");
    },
    Contrato(item) {
      this.$store.state.vehiculo = item;
      this.$router.push("/contratos/nuevo");
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 70%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
