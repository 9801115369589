<template>
  <div class="row">
    <total_ventas_card
    />
    <total_clientes_card
    />
    <total_card
    />
  </div>
  <div class="mt-4 row">
    <div class="col-lg-4 col-sm-12 ">
      <channels-chart-card />
    </div>
    <div class="mt-4 col-lg-8 col-sm-12 mt-sm-0">
      <revenue-chart-card />
    </div>
  </div>
  <div class="mt-4 row">
    <div class="col-lg-6 col-sm-12">
      <Categorias_mas_rentadas
      />
    </div>
    <div class="col-lg-6 col-sm-12">
      <Categorias_mas_dinero
      />
    </div>
    <div class="col-lg-6 col-sm-12">
      <Agentes_mas_ventas
      />
    </div>
    <div class="col-lg-6 col-sm-12">
      <Sucursal_mas_ventas
      />
    </div>
  </div>
</template>

<script>

import { defineComponent } from "vue";
import DefaultStatisticsCard from "@/views/dashboards/components/DefaultStatisticsCard.vue";
import HorizontalBarChart from "@/examples/Charts/HorizontalBarChart.vue";
import ChannelsChartCard from "@/components/Informes/card_admin/ChannelsChartCard.vue";
import RevenueChartCard from "@/components/Informes/card_admin/RevenueChartCard.vue";
import OrdersListCard from "@/examples/Cards/OrdersListCard.vue";
import total_ventas_card from "@/components/Informes/card_admin/total_ventas_card.vue";
import total_clientes_card from "@/components/Informes/card_admin/total_clientes_card.vue";
import total_card from "@/components/Informes/card_admin/total_card.vue";
import Categorias_mas_rentadas from "@/components/Informes/card_admin/Categorias_mas_rentadas.vue"
import Categorias_mas_dinero from "@/components/Informes/card_admin/Categorias_mas_dinero.vue"
import Agentes_mas_ventas from "@/components/Informes/card_admin/Agentes_mas_ventas.vue";
import Sucursal_mas_ventas from "@/components/Informes/card_admin/Sucursal_mas_ventas.vue";

export default defineComponent({
  components: {
    OrdersListCard,
    RevenueChartCard,
    ChannelsChartCard,
    HorizontalBarChart,
    DefaultStatisticsCard,
    total_ventas_card,
    total_clientes_card,
    total_card,
    Categorias_mas_rentadas,
    Categorias_mas_dinero,
    Agentes_mas_ventas,
    Sucursal_mas_ventas
  },
  data() {
    return {
      totalVentas: 0,
    };
  },
  created() {
    this.$store.state.Cargando = false;
  }
});
</script>

<style scoped>

</style>
