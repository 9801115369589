import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import moment from "moment/moment";

const store = new Vuex.Store({
  state: {
    // url: "http://localhost:3000/",
    url: process.env.VUE_APP_URL_SERVICES,
    store_url: process.env.VUE_APP_URL_IMAGE,
    carid: 0,
    notificaciones: 0,
    userid: null,
    userSucursal: null,
    username: null,
    email: null,
    isAuth: false,
    jwt: "",
    isAdmin: false,
    firstName: null,
    lastName: null,
    rol: null,
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-gradient-dark",
    isRTL: false,
    color: "success",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    isDarkMode: false,
    autorizado: false,
    user: null,
    contrato: null,
    vehiculo: null,
    reserva: null,
    cliente: null,
    cotizacion: null,
    reserva_cotizacion: false,
    services: [],
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    Cargando: false,
    temporada: true,
    anterior: "",
    salida: null,
    regreso: null,
    reserva_disponibles: false,
    permisos: null,
    categoria_vehiculo: null,
    update_reserva: "",
    update_contrato: "",
    fecha_entrega: moment().format("YYYY-MM-DD"),
    notifications: [],
    reserva_notification: false
  },
  mutations: {
    async Notificaciones(state, item) {
      state.notificaciones = item;
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    setIsPinned(state, payload) {
      state.isPinned = payload;
    },
    color(state, payload) {
      state.color = payload;
    },
    navbarMinimize(state) {
      const sidenavShow = document.getElementsByClassName("g-sidenav-show")[0];
      if (sidenavShow.classList.contains("g-sidenav-pinned")) {
        sidenavShow.classList.remove("g-sidenav-pinned");
        sidenavShow.classList.add("g-sidenav-hidden");
        state.isPinned = true;
      } else {
        sidenavShow.classList.remove("g-sidenav-hidden");
        sidenavShow.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    storeId(state, id) {
      state.userid = id;
    },
    doLogin(state) {
      state.isAuth = true;
    },
    doLogout(state) {
      state.isAuth = false;
      state.username = null;
      state.firstName = null;
      state.lastName = null;
      state.jwt = "";
      state.isAdmin = false;
      localStorage.removeItem("token");
      localStorage.removeItem("isAuth");
      localStorage.removeItem("username");
      localStorage.removeItem("email");
      localStorage.removeItem("userid");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("userSucursal");
    },
    setToken(state, token) {
      state.jwt = token;
    },
    isAdmin(state) {
      state.isAdmin = true;
    },
    SetPermisos(state, permisos) {
      state.permisos = permisos;
    }
  },
  actions: {
    Notificar({ commit }, item) {
      commit("Notificaciones", item);
    },
    setColor({ commit }, payload) {
      commit("color", payload);
    },
    storeId({ commit }, id) {
      commit("storeId", id);
    },
    doLogin({ commit }) {
      commit("doLogin");
    },
    doLogout({ commit }) {
      commit("doLogout");
    },
    setToken({ commit }, token) {
      commit("setToken", token);
    },
    isAdmin({ commit }) {
      commit("isAdmin");
    },
    guardarPermisos({ commit }, permisos) {
      commit("SetPermisos", permisos);
    }
  },
  getters: {
    tienePermiso: (state) => (permiso) => {
      let check = false;
      if (state.rol === "Super Administrador") {
        check = true;
      } else if (state.permisos !== null) {
        state.permisos.forEach(item => {
          if (item.name === permiso) {
            check = true;
          }
        });
      }
      return check;
    }
  },
  plugins: [createPersistedState()]
});

export default store;
