<template xmlns="http://www.w3.org/1999/html">
  <div class="Cargando">
    <div v-if="loading" :class="['modal_cargando', { 'show': loading }]"
         @transitionend="onTransitionEnd">
      <div class="spinner"></div>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table table-striped" id="miTabla">
      <thead class="thead-light">
      <tr class="text-center align-middle">
        <th>Kilometraje</th>
        <th>Número económico</th>
        <th>Categoría</th>
        <th>Sucursal</th>
        <th>Marca</th>
        <th>Modelo</th>
      </tr>
      </thead>
      <tbody>
      <tr class="text-center align-middle" v-for="auto in vehiculosMantenimientoPendiente" :key="auto.id"
          :class="auto.isActive?'':'text-danger'">
        <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
          {{ auto.kilometraje }}
        </td>
        <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
          {{ auto.noEcon }}
        </td>
        <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
          {{ auto.categoria.name }}
        </td>
        <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
          {{ auto.sucursal.name }}
        </td>
        <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
          {{ auto.version.modelo.marca.name }}
        </td>
        <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
          {{ auto.version.modelo.name }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="divModal  DetallesVehiculo">
    <div v-if="showCar" :class="['modal', { 'show': showCar }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="h-100">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles del Vehículo<i @click="closeCar"
                                                                                          class="material-icons-round opacity-10 modal-icon">close</i>
            </h4>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <div class="col-lg-4 col-sm-12">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">No. Económico:</strong> &nbsp;
                    {{ this.car.noEcon }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Categoría:</strong> &nbsp; {{ this.car.categoria.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Año:</strong> &nbsp; {{ this.car.anno }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Marca:</strong> &nbsp;
                    {{ this.car.version.modelo.marca.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Modelo:</strong> &nbsp;
                    {{ this.car.version.modelo.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Versión:</strong> &nbsp;
                    {{ this.car.version.name }}
                  </li>
                </ul>
              </div>
              <div class="col-lg-4 col-sm-12">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Titular:</strong> &nbsp;
                    {{ this.car.titular.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Aseguradora:</strong> &nbsp; {{ this.car.aseguradora.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Placa:</strong> &nbsp; {{ this.car.placa }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Estatus:</strong> &nbsp;
                    {{ this.car.estatus }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Cap. del Tanque:</strong> &nbsp;
                    {{ this.car.capacidadTanke }} lts
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Estado Emplacado:</strong> &nbsp;
                    {{ this.car.estado.name }}
                  </li>
                </ul>
              </div>
              <div class="col-lg-4 col-sm-12">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Color:</strong> &nbsp;
                    {{ this.car.color }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Número de Serie:</strong> &nbsp;
                    {{ this.car.numeroSerie }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Fecha de Alta:</strong> {{ this.car.fechaAlta }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Transmisión:</strong> {{ this.car.transmision }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Sucursal:</strong> &nbsp;
                    {{ this.car.sucursal.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-6 d-flex justify-content-start">
              </div>
              <div class="col-6 d-flex justify-content-end">
                <button @click="closeCar" class="btn btn-dark" type="button">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import "datatables.net";
import { DataTable } from "simple-datatables";
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";

const FECHAS = gql`
mutation Fechasvehiculos($fechaCirculacion:Date!,$fechaCredito:Date!, $fechaPoliza:Date!, $id:ID!) {
    fechasvehiculos(
        fechaCirculacion: $fechaCirculacion
        fechaCredito: $fechaCredito
        fechaPoliza: $fechaPoliza
        id: $id
    ) {
        success
        errors
    }
}
`;
const DESACTIVE = gql`
mutation DesactiveVehiculo($id:ID!) {
    desactiveVehiculo(id: $id) {
        success
    }
}`;
const BUSCAR = gql`
          query VehiculosPerName($name:String!) {
    vehiculosMantenimientoPendiente(name: $name) {
        id
        noEcon
        anno
        files
        transmision
        vin
        fechaCredito
        fechaCirculacion
        fechaPoliza
        placa
        kilometraje
        estado{
        name
        }
        sucursal{
        name
        }
        estatus
        isActive
        numeroSerie
        color
        capacidadTanke
        fechaAlta
        aseguradora {
            name
        }
        titular {
            name
        }
        categoria {
            id
            name
            }
        version{
          name
          modelo{
            name
            marca{
              name
              }
          }
        }
      }
    }`;
const ACTIVE = gql`
mutation ActiveVehiculo($id:ID!) {
    activeVehiculo(id: $id) {
        success
    }
}
`;
const DELETE = gql`
       mutation DeleteVehiculo($id:ID!) {
          deleteVehiculo(id: $id) {
              success
    }
}
`;
const CAR = gql`
query VehiculoPerId($id:ID!) {
    vehiculoPerId(id: $id) {
        id
        noEcon
        anno
        transmision
        vin
        placa
        estado{
        name
        id
        }
        minimo
        sucursal{
        name
        id
        }
        estatus
        isActive
        numeroSerie
        color
        capacidadTanke
        fechaAlta
        aseguradora {
        name
            id
        }
        titular {
        name
            id
        }
        categoria {
        name
            id
            }
        version{
        name
          id
          modelo{
          name
            id
            marca{
            name
              id
              }
          }
        }
      }
    }`;
const CATEGORIAS = gql`
query AllCategorias {
    allCategoriasAdmin {
        id
        name
        tradicionalAlta
        totalAlta
        tradicionalBaja
        totalBaja
        isActive
        fecha
        image
        iconoWhite
        iconoBlack
        maletas
        maletasSm
        personas
        transmision
        lugar
        descripcion
    }
}
`;
export default {
  name: "List_Vehiculos",
  components: { MaterialButton },
  data() {
    return {
      misearch: "",
      vehiculosMantenimientoPendiente: [],
      allCategoriasAdmin: [],
      showModal: false,
      showFechas: false,
      showCar: false,
      error: false,
      fechaCirculacion: null,
      fechaCredito: null,
      fechaPoliza: null,
      estatus: "",
      carid: 0,
      fileShow: false,
      files: null,
      preview: null,
      imagen: null,
      car: null,
      ImageModal: false,
      ImageUpdateModal: false,
      file_address: ""
    };
  },
  created() {
    this.misearch = this.$store.state.categoria_vehiculo ? this.$store.state.categoria_vehiculo.name : "";
  },
  apollo: {
    vehiculosMantenimientoPendiente: {
      query: BUSCAR,
      variables() {
        return {
          name: this.search
        };
      },
      fetchPolicy: "cache-and-network"
    },
    allCategoriasAdmin: {
      query: CATEGORIAS,
      fetchPolicy: "cache-and-network"
    }
  },
  props: {
    search: {
      type: String,
      required: true
    }
  },
  methods: {
    async DeleteVehiculo(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
          });
          if (data.deleteVehiculo.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Vehículo eliminado"
            });
          }
        }
      });
    },
    async DesactiveVehiculo(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      if (data.desactiveVehiculo.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "warning",
          title: "Vehículo desactivado"
        });
      }
    },
    async ActiveVehiculo(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      if (data.activeVehiculo.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Vehículo activado"
        });
      }
    },
    openImagen(imagen, id) {
      if (imagen) {
        this.preview = this.$store.state.store_url + imagen;
      }
      this.carid = id;
      this.ImageModal = true;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    guardarImagen() {
      if (this.imagen === null) {
        this.error = true;
        return false;
      }
      let data = new FormData();
      data.append("operations", `{"query": "mutation ($file: Upload, $id: ID) {\\n  uploadImageVehiculo(id: $id, file: $file) {\\n    error  }\\n}","variables": {"id": ${this.carid},"file": null},"operationName": null}`);
      data.append("map", "{\"1\": [\"variables.file\"]}");
      data.append("1", this.imagen, this.imagen.name);
      return axios.post(`${this.$store.state.url}graphql/`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(resp => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Imagen guardada"
        });
        this.$apollo.mutate({
          mutation: BUSCAR,
          variables: {
            name: this.misearch
          }
        }).then(response => {

        });
        this.closeImageModal();
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      });
    },
    guardarFiles() {
      if (this.files === null) {
        this.error = true;
        return false;
      }
      let data = new FormData();
      data.append("operations", `{"query": "mutation ($file: Upload, $id: ID) {\\n  uploadFiles(id: $id, file: $file) {\\n    error  }\\n}","variables": {"id": ${this.carid},"file": null},"operationName": null}`);
      data.append("map", "{\"1\": [\"variables.file\"]}");
      data.append("1", this.files, this.files.name);
      return axios.post(`${this.$store.state.url}graphql/`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(resp => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Archivo guardado"
        });
        this.closeFiles();
        this.$apollo.mutate({
          mutation: BUSCAR,
          variables: {
            name: this.misearch
          }
        }).then(response => {

        });

      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      });
    },
    async guardarFechas() {
      if (this.fechaPoliza === null || this.fechaCredito === null || this.fechaCirculacion === null) {
        this.error = true;
        return false;
      }
      await this.$apollo.mutate({
        mutation: FECHAS,
        variables: {
          fechaCirculacion: this.fechaCirculacion,
          fechaCredito: this.fechaCredito,
          fechaPoliza: this.fechaPoliza,
          id: this.carid
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      }).then(response => {
        if (response.data.fechasvehiculos.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Fechas guardadas"
          });
          this.closeFechas();
        } else {
          this.closeFechas();
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.fechasvehiculos.errors
          });
        }
      });
    },
    closeImageModal() {
      this.imagen = null;
      this.preview = null;
      this.ImageModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    EditarCar(id) {
      this.$store.state.carid = id;
      this.$router.push("editar");
    },
    openModal(id) {
      this.carid = id;
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    openFiles(item) {
      this.carid = item.id;
      this.file_address = item.files !== "" ? this.$store.state.store_url + item.files : "";
      this.fileShow = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    openFechas(fechaCredito, fechaPoliza, fechaCirculacion, id) {
      this.fechaCredito = fechaCredito;
      this.fechaPoliza = fechaPoliza;
      this.fechaCirculacion = fechaCirculacion;
      this.carid = id;
      this.showFechas = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    async openCar(item) {
      this.car = item;
      this.showCar = true;
      document.body.classList.add("modal-open");
    },
    closeCar() {
      this.showCar = false;
      document.body.classList.remove("modal-open");
    },
    closeFechas() {
      this.showFechas = false;
      this.fechaCredito = null;
      this.fechaPoliza = null;
      this.fechaCirculacion = null;
      document.body.classList.remove("modal-open");
    },
    closeModal() {
      this.carid = 0;
      this.estatus = "";
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    closeFiles() {
      this.carid = 0;
      this.files = null;
      this.file_address = "";
      this.fileShow = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    onImagenSeleccionada(event) {
      this.imagen = event.target.files[0];
      this.preview = URL.createObjectURL(this.imagen);
    },
    onFile(event) {
      this.files = event.target.files[0];
    },
    Vencido(fechaCredito, fechaPoliza, fechaCirculacion) {
      return fechaCirculacion <= moment().format("YYYY-MM-DD") ||
        fechaPoliza <= moment().format("YYYY-MM-DD") ||
        fechaCredito <= moment().format("YYYY-MM-DD");
    }
  },
  computed: {
    loading() {
      return this.$apollo.loading;
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 70%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

@media (max-width: 550px) {
  .modal-content {
    width: 90%;
  }
}

</style>
