<template xmlns="http://www.w3.org/1999/html">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Cotizaciones
            </h5>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar cotización" aria-label="Recipient's username"
                       aria-describedby="button-addon2" autocomplete="new-password">
              </div>
            </div>
            <div class="col-9 text-end">
              <button @click="openModal" class="btn btn-dark align-content-end" type="submit">Nueva cotización</button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped" style="font-size: 0.875rem !important;">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Cliente</th>
                <th>Categoria</th>
                <th>Sucursal</th>
                <th>Salida - Regreso</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in cotizacionesPerName" :key="item.id"
                  class="text-center align-middle">
                <td class="text-wrap">{{ item.nombre ? item.nombre : "" }}
                  {{ item.apellidos }}
                </td>
                <td class="text-wrap">
                  {{ item.categoria ? item.categoria.name : "" }}
                </td>
                <td class="text-wrap">{{ item.sucursal ? item.sucursal.name : ""
                  }}
                </td>
                <td class="text-wrap">
                  {{ item.fechaRenta ? formatearFecha(item.fechaRenta) : "" }} -<br>
                  {{ item.fechaFin ? formatearFecha(item.fechaFin) : "" }}
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button @click="openDetalles(item)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Ver detalles de la cotización" data-container="body"
                            data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">visibility</i></button>
                    <button v-show="!item.reservado" @click="Reserva(item)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Crear reserva" data-container="body"
                            data-animation="true" class="btn btn-secondary p-1 ms-1">
                      <i class="material-icons opacity-10">description</i></button>
                    <button v-show="!item.reservado" @click="Enviar_email(item.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Enviar cotización por correo" data-container="body" data-animation="true"
                            class="btn btn-warning p-1 ms-1">
                      <i class="material-icons opacity-10">email</i></button>
                    <button v-show="!item.reservado" @click="Actualizar_Modal(item)"
                            data-bs-toggle="tooltip" data-bs-placement="top" title="Actualizar cotización"
                            data-container="body" data-animation="true" class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                    <button v-show="!item.reservado" @click="Eliminar(item.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Eliminar cotización" data-container="body" data-animation="true"
                            class="btn btn-danger p-1 ms-1">
                      <i class="material-icons opacity-10">delete</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Detalles">
    <div v-if="showDetalles" :class="['modal', { 'show': showDetalles }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div id="detalles_cotizacion">
          <div class="mb-3 border-bottom border-dark d-flex align-items-center justify-content-between">
            <img src="@/assets/img/issyrentallogoweb.png" style="width: 200px; height: 80px; margin-bottom: 10px">
            <div class="text-end modal-icon1">
              <strong class="text-dark">Fecha:</strong><br>
              {{ formatearFecha(new Date()) }}
            </div>
            <br>
          </div>
          <h3>Detalles de la cotización</h3>

          <div class="row mb-3" style="display: flex">
            <div class="col-6">
              <ul class="list-group">
                <li class="text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Nombre y apellidos:</strong> &nbsp;
                  {{ this.item.nombre }} {{ this.item.apellidos }}
                </li>
                <li class="text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Email:</strong> &nbsp;
                  {{ this.item.email }}
                </li>
                <li class="text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Fecha de Renta:</strong><br>
                  {{ formatearFecha(this.item.fechaRenta) }}
                </li>
                <li class="text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Categoría:</strong> &nbsp; {{ this.item.categoria.name }}
                </li>
                <li class="text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Teléfono:</strong> {{ this.item.telefono }}
                </li>
                <li class="text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Tarifa diaria:</strong> &nbsp;
                  $ {{ numeroFormateado(this.item.tarifaDiaria) }} MXN
                </li>
                <li class="text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Total (Impuestos incluidos): </strong>$
                  {{ numeroFormateado(this.item.total)
                  }} MXN
                </li>
              </ul>
            </div>
            <div class="col-6">
              <ul class="list-group">
                <li class="text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Teléfono:</strong> &nbsp;
                  {{ this.item.telefono }}
                </li>
                <li class="text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Edad:</strong> &nbsp; {{ this.item.edad }}
                </li>
                <li class="text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Fecha de Regreso:</strong><br>
                  {{ formatearFecha(this.item.fechaFin) }}
                </li>
                <li class="text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Sucursal:</strong> &nbsp; {{ this.item.sucursal.name }}
                </li>
                <li class="text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Días:</strong> &nbsp;
                  {{ this.item.dias }}
                </li>
                <li class="text-sm border-0 list-group-item ps-0">
                  <strong class="text-dark">Servicios extras: </strong>$
                  {{ numeroFormateado(this.item.serviciosExtras)
                  }} MXN
                </li>
              </ul>
            </div>
          </div>
          <div class="row mb-3" v-show="servicios_extras.length>0">
            <div class="col-12">
              <strong class="text-dark">Servicios extras cotizados</strong>
              <ul>
                <li v-for="item in servicios_extras" :key="item.id">{{ item.servicio.name }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button style="@media print {
        display: none;
        }" @click="closeDetalles" class="btn btn-secondary" type="button">Cerrar
            </button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button style="@media print {
        display: none;
        }" @click="imprimirModal" class="btn btn-dark" type="button">Imprimir
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Nueva">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start">
            <i v-if="accion==='nuevo'" class="fa fa-plus-square me-2"></i>
            <i v-else class="fa fa-pencil-square me-2"></i>
            {{ accion === "update" ? "Editar cotización" : "Nueva cotización" }}
            <i @click="closeModal" class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row" v-show="pagina_uno">
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Nombre<span class="text-danger ms-1">*</span></label>
            <div class="input-container">
              <input type="text" v-model="nombre" @input="autocomplete"
                     class="form-control dropdown mb-3">
              <span class="clear-icon" @click="Clear_Data" v-show="nombre!==''">
                            <i class="fas fa-times text-danger"></i>
                          </span>
            </div>
            <ul v-if="showSubmenu" class="position-absolute z-index2 bg-light">
              <li class="dropdown-item cursor-pointer d-block" v-for="item in autocompleteItems"
                  :key="item.id"
                  @click="selectItem(item)">{{ item.nombre }} {{ item.apellidos }}
              </li>
            </ul>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Apellidos<span class="text-danger ms-1">*</span></label>
            <input v-model="apellidos" type="text" class="form-control"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Correo<span class="text-danger ms-1">*</span></label>
            <input v-model="email" type="email" class="form-control"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Edad<span class="text-danger ms-1">*</span></label>
            <input v-model="edad" type="number" class="form-control"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Teléfono<span class="text-danger ms-1">*</span></label>
            <input id="telefono" v-model="telefono" pattern="[0-9]*" type="text"
                   class="form-control" maxlength="10">
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Sucursal<span class="text-danger ms-1">*</span> </label>
            <select v-model="sucursal" class="form-select mb-3 p-2 border border-secondary">
              <option selected disabled value="0">Seleccione una Sucursal</option>
              <option v-for="item in allSucursales" :value="item.id" v-show="item.isActive">{{ item.name }}
              </option>
            </select></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Fecha de renta<span class="text-danger ms-1">*</span></label>
            <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es" placeholder="Seleccione una fecha"
                           minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}"
                           v-model="fechaRenta" class="form_fecha" format="dd/MM/yyyy hh:mm aa">
            </VueDatePicker>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Fecha de regreso<span class="text-danger ms-1">*</span></label>
            <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es" placeholder="Seleccione una fecha"
                           minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}"
                           v-model="fechaFin" class="form_fecha" format="dd/MM/yyyy hh:mm aa">
            </VueDatePicker>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Días<span class="text-danger ms-1">*</span></label>
            <input v-model="dias" disabled type="number" class="form-control"></div>
          <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar todos los campos</p></div>
          </div>
          <div v-show="phone_error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">El número de teléfono es inválido</p></div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
            <button @click="Siguiente_Dos" class="btn btn-dark" type="button">Siguiente</button>
          </div>
        </div>
        <div class="row" v-show="pagina_dos">
          <div class="row mb-2 services_extras_container">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 services_extras mb-2 shadow rounded"
                 v-for="item in elementosPaginaActual" :key="item.id">
              <div class="d-flex align-items-center">
                <img :src="getURL(item.icono)" class="img-fluid me-3" style="height: 35px; width: 35px">
                <span>{{ item.name }}</span>
              </div>
              <div class="d-flex align-items-center">
                <a :id="'adicionar'+item.id" class="btn btn-issy mb-0"
                   style="width: 315px" :class="services[item.id]?'d-none':''"
                   @click="Adicionar(item.id, item.precio, item.diario, item.name)">
                  <i class="fa fa-plus-square"></i> Adicionar<span
                  class="ms-2">$ {{ item.precio }} MXN</span>
                  <span v-if="item.diario" class="ms-1">/ día</span>
                  <span v-else class="ms-1">/ único pago</span>
                </a>
                <a :id="'quitar'+item.id" class="btn btn-primary1 mb-0"
                   style="width: 315px" :class="services[item.id]?'':'d-none'"
                   @click="Quitar(item.id, item.precio, item.diario, item.name)">
                  <i class="fa fa-minus-square"></i> Quitar<span
                  class="ms-2">$ {{ item.precio }} MXN</span>
                  <span v-if="item.diario" class="ms-1">/ día</span>
                  <span v-else class="ms-1">/ único pago</span>
                </a>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <button @click="paginaAnterior" class="btn btn-dark me-3"><i class="material-icons opacity-10">arrow_back_ios</i>
            </button>
            <button v-for="index in totalPaginas" :key="index" @click="irPaginaActual(index)"
                    class="btn me-3" :class="index===paginaActual?'btn-info':'btn-secondary'">{{ index }}
            </button>
            <button @click="paginaSiguiente" class="btn btn-dark"><i
              class="material-icons opacity-10">arrow_forward_ios</i></button>
          </div>
          <div class="row">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ps-2 pe-2 mb-2 shadow rounded">
              <h5 class="text-center">Otro servicio</h5>
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <label class="form-label">Descripción</label>
                  <input type="text" class="form-control p-2 border border-secondary">
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <label class="form-label">Precio</label>
                  <input type="number" class="form-control p-2 border border-secondary" v-model="precio_otro">
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <div class="form-check p-0">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="pago_diario"
                      v-model="diario_otro"
                    />
                    <label for="pago_diario" class="h6 text-bold mb-1">Pago diario</label>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <div class="d-flex align-items-center">
                    <a :id="'adicionar'+'otro'" class="btn w-100 btn-issy mb-0"
                       @click="Adicionar('otro', precio_otro, diario_otro)">
                      <i class="fa fa-plus-square"></i> Adicionar<span
                      class="ms-2">$ {{ precio_otro }} MXN</span>
                      <span class="ms-1">/ {{ diario_otro ? "Día" : "Único pago" }}</span>
                    </a>
                    <a :id="'quitar'+'otro'" class="btn w-100 btn-primary1 mb-0 d-none"
                       @click="Quitar('otro', precio_otro, diario_otro)">
                      <i class="fa fa-minus-square"></i> Quitar<span
                      class="ms-2">$ {{ precio_otro }} MXN</span>
                      <span class="ms-1">/ {{ diario_otro ? "Día" : "Único pago" }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between">
              <button @click="Anterior_Uno" class="btn btn-secondary" type="button">Regresar</button>
              <button @click="Siguiente_Tres" class="btn btn-dark" type="button">Siguiente</button>
            </div>
          </div>
        </div>
        <div class="row" v-show="pagina_tres">
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Categoría<span class="text-danger ms-1">*</span></label>
            <select v-model="categoria" class="form-select">
              <option selected disabled :value="0">Seleccione una categoria</option>
              <option v-for="item in allCategoriasAdmin" :value="item.id" v-show="item.isActive">{{ item.name }}
              </option>
            </select></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Tipo de cobertura<span class="text-danger ms-1">*</span></label>
            <select v-model="tipo" class="form-select">
              <option value="total">Cobertura total : {{ tarifa_total }}</option>
              <option value="tradicional">Cobertura tradicional : {{ tarifa_tradicional }}</option>
            </select></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Tarifa<span class="text-danger ms-1">*</span></label>
            <input v-model="tarifa" type="number" class="form-control"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Cantidad<span class="text-danger ms-1">*</span></label>
            <select v-model="cantidad" class="form-select">
              <option selected disabled :value="0">Seleccione la cantidad de vehículos</option>
              <option v-for="item in cantidad_vehiculos" :value="item" :key="item">{{ item }}</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Servicios extras<span class="text-danger ms-1">*</span></label>
            <input disabled v-model="total_extras" type="number" class="form-control">
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Total (Impuestos incluidos)<span class="text-danger ms-1">*</span></label>
            <input disabled v-model="total" type="number" class="form-control"></div>
          <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar todos los campos</p></div>
          </div>
          <div v-show="phone_error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">El número de teléfono es inválido</p></div>
          </div>
          <div v-show="email_error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">El correo es inválido</p></div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between">
            <button @click="Anterior_Dos" class="btn btn-secondary" type="button">Regresar</button>
            <button v-if="accion==='nuevo'" @click="Guardar" class="btn btn-dark" type="button">Guardar</button>
            <button v-else @click="Update" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Update">
    <div v-if="showUpdate" :class="['modal', { 'show': showUpdate }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Actualizar cotización<i @click="closeUpdate"
                                                                                               class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row" v-show="pagina_uno">
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Nombre<span class="text-danger ms-1">*</span></label>
            <input class="form-control dropdown mb-3" type="text" v-model="nombre"
                   @input="autocomplete">
            <ul v-if="showSubmenu" class="position-absolute z-index2 bg-light">
              <li class="dropdown-item cursor-pointer d-block" v-for="item in autocompleteItems"
                  :key="item.id"
                  @click="selectItem(item)">{{ item.nombre }} {{ item.apellidos }}
              </li>
            </ul>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Apellidos<span class="text-danger ms-1">*</span></label>
            <input v-model="apellidos" type="text" class="form-control"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Correo<span class="text-danger ms-1">*</span></label>
            <input v-model="email" type="email" class="form-control"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Edad<span class="text-danger ms-1">*</span></label>
            <input v-model="edad" type="number" class="form-control"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Teléfono<span class="text-danger ms-1">*</span></label>
            <input id="telefono" v-model="telefono" pattern="[0-9]*" type="text"
                   class="form-control" maxlength="10">
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Sucursal<span class="text-danger ms-1">*</span> </label>
            <select v-model="sucursal" class="form-select mb-3 p-2 border border-secondary">
              <option selected disabled value="0">Seleccione una Sucursal</option>
              <option v-for="item in allSucursales" :value="item.id" v-show="item.isActive">{{ item.name }}
              </option>
            </select></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Fecha de renta<span class="text-danger ms-1">*</span></label>
            <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                           placeholder="Seleccione una fecha" format="dd/MM/yyyy hh:mm aa"
                           v-model="fechaRenta" class="form_fecha"
                           minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}">
            </VueDatePicker>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Fecha de regreso<span class="text-danger ms-1">*</span></label>
            <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                           placeholder="Seleccione una fecha" format="dd/MM/yyyy hh:mm aa"
                           v-model="fechaFin" class="form_fecha"
                           minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}">
            </VueDatePicker>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Días<span class="text-danger ms-1">*</span></label>
            <input v-model="dias" disabled type="number" class="form-control"></div>
          <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar todos los campos</p></div>
          </div>
          <div v-show="phone_error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">El número de teléfono es inválido</p></div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
            <button @click="Siguiente_Dos" class="btn btn-dark" type="button">Siguiente</button>
          </div>
        </div>
        <div class="row" v-show="pagina_dos">
          <div class="row mb-2 services_extras_container">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 services_extras mb-2 shadow rounded"
                 v-for="item in elementosPaginaActual" :key="item.id">
              <div class="d-flex align-items-center">
                <img :src="getURL(item.icono)" class="img-fluid me-3" style="height: 35px; width: 35px">
                <span>{{ item.name }}</span>
              </div>
              <div class="d-flex align-items-center">
                <a :id="'adicionar'+item.id" class="btn btn-issy mb-0"
                   style="width: 315px" :class="services[item.id]?'d-none':''"
                   @click="Adicionar(item.id, item.precio, item.diario, item.name)">
                  <i class="fa fa-plus-square"></i> Adicionar<span
                  class="ms-2">$ {{ item.precio }} MXN</span>
                  <span v-if="item.diario" class="ms-1">/ día</span>
                  <span v-else class="ms-1">/ único pago</span>
                </a>
                <a :id="'quitar'+item.id" class="btn btn-primary1 mb-0"
                   style="width: 315px" :class="services[item.id]?'':'d-none'"
                   @click="Quitar(item.id, item.precio, item.diario, item.name)">
                  <i class="fa fa-minus-square"></i> Quitar<span
                  class="ms-2">$ {{ item.precio }} MXN</span>
                  <span v-if="item.diario" class="ms-1">/ día</span>
                  <span v-else class="ms-1">/ único pago</span>
                </a>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <button @click="paginaAnterior" class="btn btn-dark me-3"><i class="material-icons opacity-10">arrow_back_ios</i>
            </button>
            <button v-for="index in totalPaginas" :key="index" @click="irPaginaActual(index)"
                    class="btn me-3" :class="index===paginaActual?'btn-info':'btn-secondary'">{{ index }}
            </button>
            <button @click="paginaSiguiente" class="btn btn-dark"><i
              class="material-icons opacity-10">arrow_forward_ios</i></button>
          </div>
          <div class="row">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ps-2 pe-2 mb-2 shadow rounded">
              <h5 class="text-center">Otro servicio</h5>
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <label class="form-label">Descripción</label>
                  <input type="text" class="form-control p-2 border border-secondary">
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <label class="form-label">Precio</label>
                  <input type="number" class="form-control p-2 border border-secondary" v-model="precio_otro">
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <div class="form-check p-0">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="pago_diario"
                      v-model="diario_otro"
                    />
                    <label for="pago_diario" class="h6 text-bold mb-1">Pago diario</label>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <div class="d-flex align-items-center">
                    <a :id="'adicionar'+'otro'" class="btn w-100 btn-issy mb-0"
                       @click="Adicionar('otro', precio_otro, diario_otro)">
                      <i class="fa fa-plus-square"></i> Adicionar<span
                      class="ms-2">$ {{ precio_otro }} MXN</span>
                      <span class="ms-1">/ {{ diario_otro ? "Día" : "Único pago" }}</span>
                    </a>
                    <a :id="'quitar'+'otro'" class="btn w-100 btn-primary1 mb-0 d-none"
                       @click="Quitar('otro', precio_otro, diario_otro)">
                      <i class="fa fa-minus-square"></i> Quitar<span
                      class="ms-2">$ {{ precio_otro }} MXN</span>
                      <span class="ms-1">/ {{ diario_otro ? "Día" : "Único pago" }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between">
              <button @click="Anterior_Uno" class="btn btn-secondary" type="button">Regresar</button>
              <button @click="Siguiente_Tres" class="btn btn-dark" type="button">Siguiente</button>
            </div>
          </div>
        </div>
        <div class="row" v-show="pagina_tres">
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Categoría<span class="text-danger ms-1">*</span></label>
            <select v-model="categoria" class="form-select">
              <option selected disabled :value="0">Seleccione una categoria</option>
              <option v-for="item in allCategoriasAdmin" :value="item.id" v-show="item.isActive">{{ item.name }}
              </option>
            </select></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Tipo de cobertura<span class="text-danger ms-1">*</span></label>
            <select v-model="tipo" class="form-select">
              <option value="total">Cobertura total : {{ tarifa_total }}</option>
              <option value="tradicional">Cobertura tradicional : {{ tarifa_tradicional }}</option>
            </select></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Tarifa<span class="text-danger ms-1">*</span></label>
            <input v-model="tarifa" type="number" class="form-control"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Cantidad<span class="text-danger ms-1">*</span></label>
            <input type="number" class="form-control" :value="cantidad">
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Servicios extras<span class="text-danger ms-1">*</span></label>
            <input disabled v-model="total_extras" type="number" class="form-control">
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label class="form-label">Total (Impuestos incluidos)<span class="text-danger ms-1">*</span></label>
            <input disabled v-model="total" type="number" class="form-control"></div>
          <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar todos los campos</p></div>
          </div>
          <div v-show="phone_error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">El número de teléfono es inválido</p></div>
          </div>
          <div v-show="email_error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">El correo es inválido</p></div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between">
            <button @click="Anterior_Dos" class="btn btn-secondary" type="button">Regresar</button>
            <button @click="Update" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref } from "vue";
import noImagen from "@/assets/img/NoImage.jpg";

const LINK = gql`mutation CreateCotizacion(
        $apellidos: String!,
        $categoria: Int!,
        $email: String!,
        $nombre: String!,
        $sucursal: Int!,
        $telefono: String!,
        $total: Decimal!,
        $dias: Int!,
        $edad: Int!,
        $fechaFin: DateTime!,
        $fechaRenta: DateTime!,
        $cantidad:Int!,
        $tarifa:Decimal!,
        $serviciosComprados:[Int!],
        $serviciosExtras:Decimal!,
        ) {
        createCotizacion(
        apellidos: $apellidos
        categoria: $categoria
        email: $email
        nombre: $nombre
        sucursal: $sucursal
        telefono: $telefono
        total: $total
        dias: $dias
        edad: $edad
        fechaFin: $fechaFin
        fechaRenta: $fechaRenta
        cantidad:$cantidad
        tarifa:$tarifa
        serviciosComprados:$serviciosComprados
        serviciosExtras:$serviciosExtras
    ) {
        success
        error
    }
}`;
const ACTUALIZAR = gql`mutation UpdateCotizacion(
        $apellidos: String!,
        $categoria: Int!,
        $email: String!,
        $nombre: String!,
        $sucursal: Int!,
        $telefono: String!,
        $total: Decimal!,
        $dias: Int!,
        $edad: Int!,
        $fechaFin: DateTime!,
        $fechaRenta: DateTime!,
        $id:Int!,
        $cantidad:Int!
        $tarifa:Decimal!,
        $serviciosComprados:[Int!],
        $serviciosExtras:Decimal!,
        ) {
        updateCotizacion(
        id:$id
        apellidos: $apellidos
        categoria: $categoria
        email: $email
        nombre: $nombre
        sucursal: $sucursal
        telefono: $telefono
        total: $total
        dias: $dias
        edad: $edad
        fechaFin: $fechaFin
        fechaRenta: $fechaRenta
        cantidad:$cantidad
        tarifa:$tarifa
        serviciosComprados:$serviciosComprados
        serviciosExtras:$serviciosExtras
    ) {
        success
        error
    }
}`;
const ELIMINAR = gql`mutation DeleteCotizacion($id:Int!) {
    deleteCotizacion(id: $id) {
        success
        error
    }
}`;
const ACTIVAR = gql`mutation ActivateCotizacion($id:Int!) {
    activateCotizacion(id: $id) {
        success
        error
    }
}`;
const DESACTIVAR = gql`mutation DesactivateCotizacion($id:Int!) {
    desactivateCotizacion(id: $id) {
        success
        error
    }
}`;
const NOTIFICACIONES = gql`{
  notificaciones
}`;
const BUSCAR = gql`
          query CotizacionesPerName($name:String!) {
    cotizacionesPerName(name: $name) {
        id
        total
        email
        dias
        tarifaDiaria
        fechaRenta
        fechaFin
        nombre
        apellidos
        telefono
        reservado
        edad
        fecha
        isActive
        cantidad
        serviciosExtras
        categoria {
            id
            name
            tradicionalAlta
            totalAlta
            tradicionalBaja
            totalBaja
            isActive
            fecha
            image
            iconoWhite
            iconoBlack
            maletas
            maletasSm
            personas
            transmision
            lugar
            descripcion
        }
        sucursal {
            id
            name
            phone
            contactPhone
            address
            postalCod
            openHour
            closeHour
            isActive
        }
    }
}`;
const REVISADA = gql`
mutation Revisada($id: Int!) {
    revisada(id: $id) {
        success
        errors
    }
}`;
const ALLCATEGORIAS = gql`
query AllCategorias {
    allCategoriasAdmin {
        id
        name
        tradicionalAlta
        totalAlta
        tradicionalBaja
        totalBaja
        isActive
        fecha
        image
        iconoWhite
        iconoBlack
        maletas
        maletasSm
        personas
        transmision
        lugar
    }
}

`;
const ALLCLIENTE = gql`query AllCliente {
    allCliente {
         id
        nombre
        apellidos
        telefono
        email
        edad
        isActive
        direccion
        postal
        telefonoEmergencia
        ciudad
        estado
        pais
        tipo {
            id
            name
            isActive
        }
        licencia {
            id
            numero
            vencimiento
            estado
            permanente
        }
    }
}`;
const ALLSUCURSALES = gql`
query AllSucursales {
    allSucursales {
        id
        name
        isActive
    }
}
`;
const CANTIDAD = gql`query CantidadVehiculos($categoria:Int!) {
    cantidadVehiculos(categoria: $categoria)
}`;
const TEMPORADA = gql`
query TemporadaAlta($fechaInicio: Date!, $fechaFin: Date!) {
    temporadaAlta(fechaInicio: $fechaInicio, fechaFin: $fechaFin)
}`;
const CATEGORIA_ID = gql`
query CategoriaPerId($id:ID!) {
    categoriaPerId(id: $id) {
        id
        name
        tradicionalAlta
        totalAlta
        tradicionalBaja
        totalBaja
        isActive
        fecha
        image
        iconoWhite
        iconoBlack
        maletas
        maletasSm
        personas
        transmision
        lugar
    }
}`;
const SERVICIOS = gql`
query ServiciosPerName {
    serviciosPerName(name: "") {
        id
        isActive
        name
        diario
        precio
        icono
    }
}`;
const RESERVA_COTIZACION = gql`
query ServicioPorCotizacion($idCotizacion:Int!,
$idServicio:Int!) {
    servicioPorCotizacion(idCotizacion: $idCotizacion, idServicio: $idServicio)
}`;
const COMPROBAR_CATEGORIA = gql`
query CategoriaIsDisponible($fechaSalida:DateTime!,
$categoria:Int!,
$fechaRegreso:DateTime!) {
    categoriaIsDisponible(
        fechaSalida: $fechaSalida
        categoria: $categoria
        fechaRegreso: $fechaRegreso
    )
}`;
const ENVIAR_CORREO = gql`
mutation EnviarCotizacion($id:ID!) {
    enviarCotizacion(id: $id) {
        error
        success
    }
}`;
const SERVICIOS_COTIZACION = gql`
query ServiciosCotizacion($id:ID!) {
    serviciosCotizacion(id: $id) {
        id
        servicio {
            id
            isActive
            diario
            name
            precio
            icono
        }
    }
}`;
export default {
  name: "Cotizaciones",
  components: { MaterialButton, VueGoogleAutocomplete, VueDatePicker },
  data() {
    return {
      misearch: "",
      item: null,
      cotizacionesPerName: [],
      allCategoriasAdmin: [],
      servicios_extras: [],
      allCliente: [],
      allSucursales: [],
      autocompleteItems: [],
      serviciosPerName: [],
      iva: null,
      showSubmenu: false,
      showModal: false,
      showDetalles: false,
      error: false,
      total: 0,
      subtotal: 0,
      apellidos: "",
      categoria: 0,
      email: "",
      nombre: "",
      sucursal: 0,
      telefono: "",
      comentarios: "",
      dias: 0,
      edad: 0,
      fechaFin: null,
      fechaRenta: null,
      pagina_uno: true,
      pagina_dos: false,
      pagina_tres: false,
      tarifa: 0,
      cantidad: 0,
      tarifa_tradicional: 0,
      tarifa_total: 0,
      phone_error: false,
      showUpdate: false,
      cantidad_vehiculos: 0,
      email_error: false,
      Alta: false,
      tipo: "total",
      id: 0,
      servicios_reservados: [],
      total_extras: 0,
      services: [],
      elementosPorPagina: 3,
      paginaActual: 1,
      totalPaginas: 0,
      accion: ""
    };
  },
  apollo: {
    serviciosPerName: {
      query: SERVICIOS,
      fetchPolicy: "cache-and-network"
    },
    cotizacionesPerName: {
      query: BUSCAR,
      variables() {
        return {
          name: this.misearch
        };
      },
      fetchPolicy: "cache-and-network"
    },
    allSucursales: {
      query: ALLSUCURSALES,
      fetchPolicy: "cache-and-network"
    },
    allCategoriasAdmin: {
      query: ALLCATEGORIAS,
      fetchPolicy: "cache-and-network"
    },
    allCliente: {
      query: ALLCLIENTE,
      fetchPolicy: "cache-and-network"
    }
  },
  watch: {
    fechaRenta(value) {
      if (this.accion === "nuevo") {
        if (this.Difechas(Date.now(), value) <= 0) {
          this.fechaRenta = null;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "La fecha de renta no puede ser anterior a la fecha de hoy"
          });
        }
      }
      this.Temporada_alta();
    },
    fechaFin(value) {
      if (this.Difechas(this.fechaRenta, value) <= 0) {
        this.fechaRenta = null;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "La fecha de regreso no puede ser anterior a la fecha de renta"
        });
        return false;
      }
      this.dias = parseInt(this.Difechas(this.fechaRenta, value) / 24 < 1 ? 1 : this.Difechas(this.fechaRenta, value) / 24);
      this.Temporada_alta();
    },
    categoria(value) {
      if (value !== null) {
        this.$apollo.query({
          query: CATEGORIA_ID,
          variables: {
            id: value
          }
        }).then(resp => {
          const value = resp.data.categoriaPerId;
          this.tarifa_tradicional = parseFloat(this.Alta ? value.tradicionalAlta : value.tradicionalBaja).toFixed(2);
          this.tarifa_total = parseFloat(this.Alta ? value.totalAlta : value.totalBaja).toFixed(2);
          this.tarifa = parseFloat(this.tipo === "total" ? this.tarifa_total : this.tarifa_tradicional).toFixed(2);
          this.cobertura = parseFloat(this.tipo === "total" ? this.tarifa_total : this.tarifa_tradicional).toFixed(2);
        });
        this.$apollo.query({
          query: CANTIDAD,
          variables: {
            categoria: this.categoria
          }
        }).then(response => {
          this.cantidad_vehiculos = response.data.cantidadVehiculos;
        });
      }
    },
    tarifa(value) {
      if (this.cantidad !== 0 && this.dias !== 0 && this.tarifa !== 0) {
        this.total = (parseFloat(this.tarifa) * parseInt(this.dias) * parseInt(this.cantidad) + parseFloat(this.total_extras)).toFixed(2);
      }
    },
    cantidad(value) {
      if (this.cantidad !== 0 && this.dias !== 0 && this.tarifa !== 0) {
        this.total = (parseFloat(this.tarifa) * parseInt(this.dias) * parseInt(this.cantidad) + parseFloat(this.total_extras)).toFixed(2);
      }
    },
    tipo(value) {
      this.tarifa = parseFloat(value === "total" ? this.tarifa_total : this.tarifa_tradicional).toFixed(2);
    },
    cotizacionesPerName() {
      this.$store.state.Cargando = false;
    },
    serviciosPerName() {
      this.totalPaginas = Math.ceil(this.serviciosPerName.length / this.elementosPorPagina);
    }
  },
  mounted() {
    this.sucursal = this.$store.state.userSucursal ? this.$store.state.userSucursal : 0;
    this.$store.state.Cargando = true;
  },
  computed: {
    elementosPaginaActual() {
      const inicio = (this.paginaActual - 1) * this.elementosPorPagina;
      const fin = inicio + this.elementosPorPagina;
      return this.serviciosPerName.slice(inicio, fin);
    }
  },
  methods: {
    Siguiente_Dos() {
      if (/^\d+$/.test(this.telefono) === false) {
        this.phone_error = true;
        return false;
      }
      this.phone_error = false;
      if (/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(this.email) === false) {
        this.email_error = true;
        return false;
      }
      this.email_error = false;
      if (this.apellidos === "" || this.email === "" || this.nombre === "" || this.sucursal === 0 ||
        this.telefono === "" || this.edad === 0) {
        this.error = true;
        return false;
      }
      this.pagina_uno = false;
      this.pagina_dos = true;
      this.pagina_tres = false;
    },
    Siguiente_Tres() {
      this.pagina_uno = false;
      this.pagina_dos = false;
      this.pagina_tres = true;
    },
    Anterior_Dos() {
      this.pagina_uno = false;
      this.pagina_dos = true;
      this.pagina_tres = false;
    },
    Anterior_Uno() {
      this.pagina_uno = true;
      this.pagina_dos = false;
      this.pagina_tres = false;
    },
    Adicionar(id, precio, diario) {
      if (diario) {
        this.total_extras = parseFloat(parseFloat(this.total_extras) + (parseFloat(precio) * this.dias)).toFixed(2);
        this.total = parseFloat(parseFloat(this.total) + (parseFloat(precio) * this.dias)).toFixed(2);
      } else {
        this.total_extras = parseFloat(parseFloat(this.total_extras) + (parseFloat(precio))).toFixed(2);
        this.total = parseFloat(parseFloat(this.total) + (parseFloat(precio))).toFixed(2);
      }
      const elemento = document.getElementById("adicionar" + id);
      elemento.classList.add("d-none");
      const elemento1 = document.getElementById("quitar" + id);
      elemento1.classList.remove("d-none");
      if (!this.servicios_reservados.includes(id)) {
        this.servicios_reservados.push(id);
      }
    },
    Quitar(id, precio, diario) {
      if (diario) {
        this.total_extras = parseFloat(parseFloat(this.total_extras) - (parseFloat(precio) * this.dias)).toFixed(2);
        this.total = parseFloat(parseFloat(this.total) - (parseFloat(precio) * this.dias)).toFixed(2);
      } else {
        this.total_extras = parseFloat(parseFloat(this.total_extras) - (parseFloat(precio))).toFixed(2);
        this.total = parseFloat(parseFloat(this.total) - (parseFloat(precio))).toFixed(2);
      }
      const elemento = document.getElementById("adicionar" + id);
      elemento.classList.remove("d-none");
      const elemento1 = document.getElementById("quitar" + id);
      elemento1.classList.add("d-none");
      const index = this.servicios_reservados.indexOf(id);
      if (index > -1) {
        this.servicios_reservados.splice(index, 1);
      }
    },
    Guardar() {
      if (this.total === 0 || this.tarifa === 0 || this.apellidos === "" || this.categoria === null ||
        this.email === "" || this.nombre === "" || this.sucursal === 0 || this.telefono === "" || this.dias === 0 ||
        this.edad === 0 || this.fechaFin === null || this.fechaRenta === null) {
        this.error = true;
        return false;
      }
      this.$apollo.mutate({
        mutation: LINK,
        variables: {
          apellidos: this.apellidos,
          categoria: parseInt(this.categoria),
          email: this.email,
          nombre: this.nombre,
          sucursal: parseInt(this.sucursal),
          telefono: this.telefono,
          total: this.total,
          dias: this.dias,
          edad: this.edad,
          fechaFin: this.fechaFin,
          fechaRenta: this.fechaRenta,
          cantidad: this.cantidad,
          tarifa: this.tarifa,
          serviciosComprados: this.servicios_reservados,
          serviciosExtras: this.total_extras
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      }).then(response => {
        if (response.data.createCotizacion.success) {
          this.$store.state.Cargando = true;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Cotización creada correctamente"
          });
          this.closeModal();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.createCotizacion.error
          });
          this.closeModal();
        }
      });
    },
    Update() {
      this.$apollo.mutate({
        mutation: ACTUALIZAR,
        variables: {
          apellidos: this.apellidos,
          categoria: parseInt(this.categoria),
          email: this.email,
          nombre: this.nombre,
          sucursal: parseInt(this.sucursal),
          telefono: this.telefono,
          total: this.total,
          dias: this.dias,
          edad: this.edad,
          fechaFin: this.fechaFin,
          fechaRenta: this.fechaRenta,
          cantidad: this.cantidad,
          id: this.id,
          tarifa: this.tarifa,
          serviciosComprados: this.servicios_reservados,
          serviciosExtras: this.total_extras
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      }).then(response => {
        if (response.data.updateCotizacion.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Cotización actualizada correctamente"
          });
          this.closeModal();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.updateCotizacion.error
          });
          this.closeModal();
        }
      });
    },
    Actualizar_Modal(item) {
      this.accion = "update";
      this.apellidos = item.apellidos;
      this.categoria = item.categoria.id;
      this.email = item.email;
      this.nombre = item.nombre;
      this.sucursal = item.sucursal.id;
      this.telefono = item.telefono;
      this.total = item.total;
      this.dias = item.dias;
      this.edad = item.edad;
      this.fechaFin = new Date(item.fechaFin);
      this.fechaRenta = new Date(item.fechaRenta);
      this.tarifa = item.tarifaDiaria;
      this.cantidad = item.cantidad;
      this.total_extras = item.serviciosExtras;
      this.id = item.id;
      this.showModal = true;
      this.error = false;
      this.phone_error = false;
      this.serviciosPerName.forEach(item_for => {
        this.$apollo.query({
          query: RESERVA_COTIZACION,
          variables: {
            idCotizacion: item.id,
            idServicio: item_for ? item_for.id : 0
          },
          fetchPolicy: "network-only"
        }).then(response => {
          this.services[item_for.id] = response.data.servicioPorCotizacion;
          if (response.data.servicioPorCotizacion) {
            this.servicios_reservados.push(item_for.id);
          }
        });
      });
      document.body.classList.add("modal-open");
    },
    Desactivar(id) {
      this.$apollo.mutate({
        mutation: DESACTIVAR,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      }).then(response => {
        if (response.data.desactivateCotizacion.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Cotización desactivada correctamente"
          });
          this.closeModal();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.desactivateCotizacion.error
          });
          this.closeModal();
        }
      });
    },
    Activar(id) {
      this.$apollo.mutate({
        mutation: ACTIVAR,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      }).then(response => {
        if (response.data.activateCotizacion.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Cotización desactivada correctamente"
          });
          this.closeModal();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.activateCotizacion.error
          });
          this.closeModal();
        }
      });
    },
    Eliminar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: ELIMINAR,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
          });
          if (data.deleteCotizacion.success) {
            this.$store.state.Cargando = true;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Cotización eliminada"
            });
          }
        }
      });
    },
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("dddd, D [de] MMMM [de] YYYY h:mm A");
    },
    numeroFormateado(numero) {
      const num = parseFloat(numero);
      return num.toLocaleString("en-US", { minimumFractionDigits: 2 });
    },
    openDetalles(item) {
      this.item = item;
      this.showDetalles = true;
      document.body.classList.add("modal-open");
      this.$apollo.mutate({
        mutation: REVISADA,
        variables: {
          id: item.id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      this.$apollo.query({
        query: NOTIFICACIONES
      }).then(response => {
        this.$store.state.notificaciones = response.data.notificaciones;
      });
      this.$apollo.query({
        query: SERVICIOS_COTIZACION,
        variables: {
          id: item.id
        }
      }).then(response => {
        this.servicios_extras = response.data.serviciosCotizacion;
      });
    },
    openModal() {
      this.accion = "nuevo";
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    closeModal() {
      this.accion = "";
      this.total = 0;
      this.subtotal = 0;
      this.apellidos = "";
      this.categoria = null;
      this.email = "";
      this.nombre = "";
      this.sucursal = 0;
      this.phone_error = false;
      this.telefono = "";
      this.comentarios = "";
      this.dias = 0;
      this.edad = 0;
      this.fechaFin = null;
      this.fechaRenta = null;
      this.showModal = false;
      this.error = false;
      this.pagina_uno = true;
      this.pagina_dos = false;
      this.pagina_tres = false;
      this.tarifa = 0;
      this.tarifa_total = 0;
      this.tarifa_tradicional = 0;
      document.body.classList.remove("modal-open");
    },
    closeUpdate() {
      this.accion = "";
      this.total = 0;
      this.subtotal = 0;
      this.apellidos = "";
      this.categoria = null;
      this.email = "";
      this.nombre = "";
      this.sucursal = 0;
      this.phone_error = false;
      this.telefono = "";
      this.comentarios = "";
      this.dias = 0;
      this.edad = 0;
      this.fechaFin = null;
      this.fechaRenta = null;
      this.showUpdate = false;
      this.error = false;
      this.pagina_uno = true;
      this.pagina_dos = false;
      this.pagina_tres = false;
      this.tarifa = 0;
      this.tarifa_total = 0;
      this.tarifa_tradicional = 0;
      document.body.classList.remove("modal-open");
    },
    closeDetalles() {
      this.item = null;
      this.showDetalles = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    paginaAnterior() {
      if (this.paginaActual > 1) {
        this.paginaActual--;
      }
    },
    irPaginaActual(index) {
      this.paginaActual = index;
    },
    paginaSiguiente() {
      if (this.paginaActual < this.totalPaginas) {
        this.paginaActual++;
      }
    },
    Contrato(item) {
      this.$store.state.reserva = item;
      this.$router.push("/contratos/nuevo");
    },
    autocomplete() {
      this.autocompleteItems = this.allCliente.filter(item =>
        item.nombre.toLowerCase().includes(this.nombre.toLowerCase())
      );
      this.showSubmenu = this.nombre !== "";
    },
    selectItem(item) {
      this.nombre = item.nombre;
      this.apellidos = item.apellidos;
      this.edad = item.edad;
      this.email = item.email;
      this.telefono = item.telefono;
      this.showSubmenu = false;
    },
    Clear_Data() {
      this.nombre = "";
      this.apellidos = "";
      this.edad = "";
      this.email = "";
      this.telefono = "";
      this.showSubmenu = false;
    },
    Difechas(fecha1, fecha2) {
      return moment(fecha2).diff(moment(fecha1), "hours");
    },
    Temporada_alta() {
      if (this.fechaRenta !== null && this.fechaFin !== null) {
        this.$apollo.query({
          query: TEMPORADA,
          variables: {
            fechaInicio: this.fechaRenta.toISOString().slice(0, 10),
            fechaFin: this.fechaFin.toISOString().slice(0, 10)
          }
        }).then(response => {
          this.Alta = response.data.temporadaAlta;
        });
      }
    },
    getURL(url) {
      if (url !== "") {
        return this.$store.state.store_url + url;
      } else {
        return noImagen;
      }
    },
    Reserva(item) {
      this.$apollo.query({
        query: COMPROBAR_CATEGORIA,
        variables: {
          fechaSalida: item.fechaRenta,
          categoria: item.categoria.id,
          fechaRegreso: item.fechaFin,
          sucursal: item.sucursal.id
        }
      }).then(response => {
        if (response.data.categoriaIsDisponible) {
          this.$store.state.reserva_cotizacion = true;
          this.$store.state.cotizacion = item;
          this.serviciosPerName.forEach(item_for => {
            this.$apollo.query({
              query: RESERVA_COTIZACION,
              variables: {
                idCotizacion: item.id,
                idServicio: item_for ? item_for.id : 0
              }
            }).then(response => {
              this.services[item_for.id] = response.data.servicioPorCotizacion;
            });
          });
          this.$store.state.services = this.services;
          this.$router.push("/transacciones/administracion");
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "No existe disponibilidad de esa categoría en ese rango de fechas"
          });
        }
      });
    },
    Enviar_email(id) {
      this.$apollo.mutate({
        mutation: ENVIAR_CORREO,
        variables: {
          id: id
        }
      }).then(response => {
        if (response.data.enviarCotizacion.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Cotización enviada correctamente"
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al enviar la cotización"
          });
        }
      });
    },
    imprimirModal() {
      const detalles_cotizacion = document.getElementById("detalles_cotizacion");
      const contenidoModal = detalles_cotizacion.innerHTML; // Captura el contenido HTML del modal
      const ventanaImpresion = window.open("", "_blank");
      ventanaImpresion.document.open();
      ventanaImpresion.document.write(contenidoModal);
      ventanaImpresion.document.close();
      ventanaImpresion.print();
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.input-icon:focus {
  background-image: url('../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.input-container {
  position: relative;
}

.clear-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-icon1 {
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 60%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.btn-primary1 {
  background-color: #FFA633;
  border: 2px solid #FFA633 !important;
  box-shadow: inset 0 0 0 0 #fff;
  color: #fff;
}

.btn-primary1:hover {
  color: rgba(255, 255, 255, 0.8);
}

.btn-issy {
  background-color: #fff;
  border: 2px solid #FFA633 !important;
  color: #FFA633;
  box-shadow: inset 0 0 0 0 #fff;
}

.btn-issy:hover {
  color: rgba(255, 166, 51, 0.87);
}

.text-gray {
  color: #888888;
}

.modal-content1 {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 30%;
}

.services_extras_container {
  height: 150px;
  overflow-y: hidden;
}

.services_extras {
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 550px) {
  .modal-content1 {
    width: 80%;

  }

  .modal-content {
    width: 100%;
    margin-top: 200px;
  }

  .services_extras_container {
    height: 300px;
    overflow-y: hidden;
  }

  .services_extras {
    height: 30%;
    display: block;
    justify-content: center;
    align-items: center;
  }
}

@media print {
  .modal {
    width: 100%;
    height: auto;
    padding: 10px;
  }

  /* Añade aquí los estilos adicionales para la impresión */
}
</style>
