<template xmlns="http://www.w3.org/1999/html">
  <div class="ps-4 pe-4 pb-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de contratos abiertos
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar contrato" aria-label="Recipient's username"
                       aria-describedby="button-addon2" autocomplete="new-password">
              </div>
            </div>
            <div class="col-9 text-end d-flex justify-content-end">

            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped" style="font-size: 0.875rem !important;">
              <thead>
              <tr class="text-center">
                <th class="text-sm">No. Contrato - Sucursal</th>
                <th class="text-sm">Agente</th>
                <th class="text-sm">Salida - Regreso</th>
                <th class="text-sm">Cliente</th>
                <th class="text-sm">Categoría</th>
                <th class="text-sm">Auto - No. económico</th>
                <th class="text-sm">Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="item in items" :key="item.id"
                  :class="esHoy(item.fechaRegreso)?'es-hoy':''">
                <td class="text-sm ps-2 pe-2" style="word-break: break-word;">{{ item.numContrato }} -
                  {{ item.sucursal.name }}
                </td>
                <td class="text-sm" style="word-break: break-word;">
                  {{ item.agente.firstName }} {{ item.agente.lastName }}
                </td>
                <td class="text-sm" style="word-break: break-word;">{{ formatearFecha(item.fechaSalida) }} -<br>
                  {{ formatearFecha(item.fechaRegreso) }}
                </td>
                <td class="text-sm" style="word-break: break-word;">
                  {{ item.cliente.nombre }} {{ item.cliente.apellidos }}
                </td>
                <td class="text-sm" style="word-break: break-word;">
                  {{ item.vehiculo.categoria.name }}
                </td>
                <td class="text-sm" style="word-break: break-word;">
                  {{ item.vehiculo.version.modelo.marca.name }} {{ item.vehiculo.version.modelo.name }} -<br>
                  {{ item.vehiculo.noEcon }}
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button v-if="tienePermiso('editar_contrato')" @click="Editar(item)"
                            v-show="item.estado!=='Cancelado'"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Editar contrato" data-container="body" data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                    <button @click="openCar(item)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Detalles del contrato" data-container="body" data-animation="true"
                            class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">visibility</i></button>
                    <button @click="openRecogida(item)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Recogida de vehículo" data-container="body" data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">garage</i></button>
                    <button @click="generateReport(item)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Generar contrato" data-container="body" data-animation="true"
                            class="btn btn-secondary p-1 ms-1">
                      <i class="material-icons opacity-10">description</i></button>
                    <button @click="Cancelar_Contrato(item.id)"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Cancelar el contrato" data-container="body"
                            data-animation="true"
                            class="btn btn-danger p-1 ms-1">
                      <i class="material-icons opacity-10">cancel</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-2 pb-2">
      <div class="row">
        <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
          <!--          Mostrando {{ items.length }} de-->
          <!--          {{ cant }}-->
        </div>
        <div class="col-lg-6 d-flex align-items-center justify-content-center text-dark">
          <p class="me-3" style="margin-bottom: 0px">Elementos por página</p>
          <select class="form-control" style="width: 50px; height: 40px; padding: 5px" v-model="cant">
            <option :value=10>10</option>
            <option :value=50>50</option>
            <option :value=100>100</option>
          </select>
        </div>
        <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
          <button class="btn btn-dark me-3" style="height: 40px" @click="prevPage"
                  :disabled="this.hasPreviousPage===false">
            <i class="material-icons opacity-10">skip_previous</i></button>
          <button class="btn btn-dark" style="height: 40px" @click="nextPage" :disabled="this.hasNextPage===false">
            <i class="material-icons opacity-10">skip_next</i></button>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Detalles">
    <div v-if="showCar" :class="['modal', { 'show': showCar }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="h-100">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles del contrato<i @click="closeCar"
                                                                                          class="material-icons-round opacity-10 modal-icon">close</i>
            </h4>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <h6 class="text-bold">Información del cliente</h6>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Nombre y apellidos:</strong>
                <p>{{ item.cliente.nombre }} {{ item.cliente.apellidos }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Correo:</strong>
                <p>{{ item.cliente.email }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Teléfono:</strong>
                <p>{{ item.cliente.telefono }}</p>
              </div>
              <h6 class="text-bold">Condiciones de renta</h6>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>No. Folio:</strong>
                <p>{{ item.numContrato }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Fecha de Contrato:</strong>
                <p>{{ formatearFecha(item.fecha) }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Estado del contrato:</strong>
                <p>{{ item.estado }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Sucursal:</strong>
                <p>{{ item.sucursal.name }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Canal:</strong>
                <p>{{ item.canal === "portal" ? "Portal" : "Administración" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Agente:</strong>
                <p>{{ item.agente.firstName }} {{ item.agente.lastName }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Fecha de salida:</strong>
                <p>{{ formatearFecha(item.fechaSalida) }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Fecha de regreso:</strong>
                <p>{{ formatearFecha(item.fechaRegreso) }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Días reservados:</strong>
                <p>{{ item.dias }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Crédito otorgado:</strong>
                <p>{{ item.credito ? "Si" : "No" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-8">
                <strong>Servicios extras:</strong>
                <p v-for="item in servicios_ex" :key="item.id">{{ item.servicio.name }}</p>
              </div>
              <h6 class="text-bold">Información del auto</h6>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Categoría:</strong>
                <p>{{ item.vehiculo.categoria.name }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Marca:</strong>
                <p>{{ item.vehiculo.version.modelo.marca.name }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Modelo:</strong>
                <p>{{ item.vehiculo.version.modelo.name }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Color:</strong>
                <p>{{ item.vehiculo.color }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Placa:</strong>
                <p>{{ item.vehiculo.placa }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Cantidad del tanque(Litros):</strong>
                <p>{{ item.vehiculo.capacidadTanke }} lts</p>
              </div>
              <h6 class="text-bold">Tarifas</h6>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Tarifa diaria:</strong>
                <p>$ {{ numeroFormateado(item.tarifaDiaria) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Tarifa de horas extras</strong>
                <p>$ {{ numeroFormateado(item.precioHoraExtra) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Servicios extras:</strong>
                <p>$ {{ numeroFormateado(item.tarifaExtra) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Total:</strong>
                <p>$ {{ numeroFormateado(item.total) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Método de pago:</strong>
                <p>{{ item.tipoPago === "tarjeta" ? "Tarjeta" : "Efectivo" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Check in:</strong>
                <p>$ {{ numeroFormateado(item.checkin) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>No. tarjeta:</strong>
                <p>{{ item.tarjeta ? item.tarjeta.numero : "" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>No. autorización:</strong>
                <p>{{ item.tarjeta ? item.tarjeta.autorizacion : "" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Fecha de vencimiento:</strong>
                <p>{{ item.tarjeta ? formatearFecha(item.tarjeta.vencimiento) : "" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong v-if="item.tipoPago==='efectivo'">Pagado en efectivo:</strong>
                <strong v-else>Pagado en tarjeta:</strong>
                <p>$ {{ numeroFormateado(item.depositoEfectivo) }} MXN</p>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button @click="closeCar" class="mt-3 btn btn-dark text-end">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showFechas" :class="['modal', { 'show': showFechas }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="h-100">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-clock me-2"></i>Fechas de Vencimiento<i @click="closeFechas"
                                                                                           class="material-icons-round opacity-10 modal-icon">close</i>
            </h4>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <label>Fecha de vencimiento de tarjeta de circulación</label>
              <input v-model="fechaCirculacion" type="date" class="form-control p-2 border-bottom border-dark mb-3">
              <label>Fecha de vencimiento del crédito automotriz</label>
              <input v-model="fechaCredito" type="date" class="form-control p-2 border-bottom border-dark mb-3">
              <label>Fecha de vencimiento de póliza</label>
              <input v-model="fechaPoliza" type="date" class="form-control p-2 border-bottom border-dark">
              <div v-show="error" class="text-danger mt-3 text-center p-2"
                   style="background-color: rgba(255,19,28,0.08)">
                <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                  <p class="text-dark">No pueden haber fechas vacías</p></div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <button @click="guardarFechas" class="mt-3 btn btn-secondary">Guardar</button>
              </div>
              <div class="col-6 text-end">
                <button @click="closeFechas" class="mt-3 btn btn-dark ">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal NuevaImagen">
    <div v-if="ImageModal" :class="['modal', { 'show': ImageModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nueva Imagen de Vehículo<i
            @click="closeImageModal"
            class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Seleccione una Imagen</label>
            <input type="file" class="form-control mb-3 p-2 border-dark border-bottom" @change="onImagenSeleccionada">
            <img class="img-fluid" :src="preview" style="height: 250px">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe seleccionar una imagen</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeImageModal" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="guardarImagen" class="btn btn-dark" type="submit">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Files">
    <div v-if="fileShow" :class="['modal', { 'show': fileShow }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Subir archivo del Vehículo<i
            @click="closeFiles"
            class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Seleccione un Archivo</label>
            <input multiple type="file" class="form-control mb-3 p-2 border-dark border-bottom" @change="onFile">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe seleccionar un Archivo</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeFiles" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="guardarFiles" class="btn btn-dark" type="submit">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Recogida">
    <div v-if="Recogida_Modal" :class="['modal', { 'show': Recogida_Modal }]" @transitionend="onTransitionEnd">
      <div class="modal-content1">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Recibir vehículo<i
            @click="closeRecogida"
            class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
            <label class="form-label">Cantidad del tanque (Litros)</label>
            <input type="number" class="form-control" v-model="combustible">
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
            <label class="form-label">Kilometraje de llegada</label>
            <input type="number" class="form-control" v-model="kilometraje">
          </div>
          <div class="col-12 mb-2">
            <label class="form-label">Comentarios (Opcional)</label>
            <textarea class="form-control" v-model="comentarios"></textarea>
          </div>
          <div class="col-12 mb-2">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe llenar todos los campos</p></div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex justify-content-start">
              <button @click="closeRecogida" class="btn btn-secondary" type="button">Cancelar</button>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <button @click="guardarRecogida" class="btn btn-dark" type="submit">Cerrar contrato</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Seguridad">
    <div v-if="seguridad" :class="['modal', { 'show': seguridad }]" @transitionend="onTransitionEnd">
      <div class="modal-content1">
        <div class="row mb-3 border-bottom border-dark">
          <h6 class="text-start"><i class="fa fa-lock me-2"></i>Escriba su contraseña<i @click="closeSeguridad"
                                                                                        class="material-icons-round opacity-10 modal-icon">close</i>
          </h6>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <input v-model="password" type="password" autocomplete="new-password"
                   class="form-control p-2 border border-secondary">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe escribir su contraseña</p></div>
            </div>
            <div v-show="error_seguridad" class="text-danger mt-3 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Contraseña incorrecta</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeSeguridad" class="btn btn-secondary" type="button">Cerrar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="Comprobar" class="btn btn-dark" type="button">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="exportar_pdf" :class="['modal', { 'show': exportar_pdf }]" @transitionend="onTransitionEnd">
    <div class="modal-content-contrato">
      <div class="row mb-3 border-bottom border-dark">
        <p class="d-flex justify-content-between">
          <h6 class="text-start">
            <i class="fa fa-download me-2"></i>
            Exportar contrato en pdf
          </h6>
          <div>
            <i @click="generateReportPDF" class="material-icons-round opacity-10 cursor-pointer me-2">download</i>
            <i @click="imprimirContrato" class="material-icons-round opacity-10 cursor-pointer me-2">print</i>
            <i @click="closeExportarPDF" class="material-icons-round opacity-10 cursor-pointer">close</i>
          </div>
        </p>
      </div>
      <div class="container contrato" ref="contrato_plantilla" id="contrato_open_pdf">

        <div class="row mb-3">
          <div class="col-2">
            <img src="@/assets/img/issyrentallogo.png"
                 style="width: 150px; height: 75px">
          </div>
          <div class="col-8 text-center">
            <p style="font-weight: bold; font-size: 14px; line-height: 25px;">Transporte de Calidad y Confort S, de R.L.
              de C.V.</p>
            <p style="font-weight: bold; font-size: 14px; margin-bottom: 30px">R.F.C. TCC 160706-BL7</p>
            <p>Correo electrónico: info@issyrental.com</p>
            <p>Teléfono: (664) 524-2099 Cel.: (664) 361 7721</p>
            <p>Calle 19 #303-A Fracc. Las Californias, Tijuana, B.C., C.P. 22404</p>
            <p>Sucursal: {{ item.sucursal.name }}, {{ item.sucursal.address }}</p>
          </div>
          <div class="col-2 text-center">
            <p>Contrato de Arrendamiento</p>
            <p>(RENTAL AGREMENT)</p>
            <p>APENDICE “A”</p>
            <p>CONTRATO</p>
            <p style="font-weight: bold;">{{ item.numContrato }}</p>
          </div>
        </div>
        <div class="row mb-1 text-contrato">
          <div class="col-4 d-flex align-items-end"><p class="bold">NOMBRE (NAME)</p></div>
          <div class="col-2 d-flex align-items-end"><p class="bold">EDAD (AGE)</p></div>
          <div class="col-2 d-flex align-items-end"><p class="bold">FECHA SALIDA<br> (DATE OUT)</p></div>
          <div class="col-2 d-flex align-items-end"><p class="bold">TIEMPO SALIDA<br> (TIME OUT)/HR</p></div>
        </div>
        <div class="row mb-2 text-contrato">
          <div class="col-4"><p>{{ item.cliente.nombre }} {{ item.cliente.apellidos }}</p></div>
          <div class="col-2"><p>{{ item.cliente.edad }}</p></div>
          <div class="col-2"><p>{{ obtenerFecha(item.fechaSalida) }}</p></div>
          <div class="col-2"><p>{{ obtenerHora(item.fechaSalida) }}</p></div>
        </div>
        <div class="row mb-1 text-contrato">
          <div class="col-6 d-flex align-items-end"><p class="bold">DIRECCIÓN LOCAL (LOCAL ADDRESS)</p></div>
          <div class="col-2 d-flex align-items-end"><p class="bold">FECHA ENTRADA<br> (DATE IN)</p></div>
          <div class="col-2 d-flex align-items-end"><p class="bold">TIEMPO ENTRADA<br> (TIME IN)/HR</p></div>
          <div class="col-2 d-flex align-items-end"><p class="bold">PRECIO DE HORA EXTRA</p></div>
        </div>
        <div class="row mb-2 text-contrato">
          <div class="col-6"><p>{{ item.cliente.direccion }}</p></div>
          <div class="col-2"><p>{{ obtenerFecha(item.fechaRegreso) }}</p></div>
          <div class="col-2"><p>{{ obtenerHora(item.fechaRegreso) }}</p></div>
          <div class="col-2"><p>$ {{ numeroFormateado(item.precioHoraExtra) }} MXN</p></div>
        </div>
        <div class="row mb-1 text-contrato">
          <div class="col-12 d-flex align-items-end">
            <div class="row mb-1"><p class="bold">TARJETA DE CREDITO No. (CREDIT CARD No.)</p></div>
          </div>
        </div>
        <div class="row mb-2 text-contrato">
          <div class="col-6">
            <div class="row mb-1">
              <p>{{ item.tarjeta ? item.tarjeta.numero : "Pago en efectivo" }}</p>
            </div>
            <div class="row mb-1">
              <div class="col-6 d-flex align-items-end"><p class="bold">VENCE (EXPIRE)</p></div>
              <div class="col-6"><p class="bold">AUTORIZACION<br>(AUTHORIZATION)</p></div>
            </div>
            <div class="row mb-2">
              <div class="col-6"><p>{{ item.tarjeta ? item.tarjeta.vencimiento : "Pago en efectivo" }}</p></div>
              <div class="col-6"><p>{{ item.tarjeta ? item.tarjeta.autorizacion : "Pago en efectivo" }}</p></div>
            </div>
            <div class="row mb-1">
              <div class="col-6 d-flex align-items-end"><p class="bold">LICENCIA (LICENCE)</p></div>
              <div class="col-3"><p class="bold">VENCE<br>(EXPIRE)</p></div>
              <div class="col-3"><p class="bold">ESTADO<br>(STATE)</p></div>
            </div>
            <div class="row mb-2">
              <div class="col-6"><p>{{ item.cliente.licencia.numero }}</p></div>
              <div class="col-3"><p>{{ item.cliente.licencia.vencimiento }}</p></div>
              <div class="col-3"><p>{{ item.cliente.licencia.estado }}</p></div>
            </div>
            <div class="row mb-1">
              <div class="col-6"><p class="bold">TELÉFONO DE CONTACTO<br>
                (CONTACT PHONE)</p></div>
              <div class="col-6"><p class="bold">TELÉFONO DE EMERGENCIA<br> (EMERGENCY PHONE)</p></div>
            </div>
            <div class="row mb-2">
              <div class="col-6"><p>{{ item.cliente.telefono }}</p></div>
              <div class="col-6"><p>{{ item.cliente.telefonoEmergencia }}</p></div>
            </div>
            <div v-show="item.conductor" class="row mb-1">
              <div class="col-9"><p class="bold">OTRO CONDUCTOR ADICIONAL (ADITIONAL DRIVER)</p></div>
              <div class="col-3"><p class="bold">EDAD (AGE)</p></div>
            </div>
            <div v-show="item.conductor" class="row mb-2">
              <div class="col-9"><p>{{ item.conductor ? item.conductor.nombre : "" }}
                {{ item.conductor ? item.conductor.apellidos : "" }}</p></div>
              <div class="col-3"><p>{{ item.conductor ? item.conductor.edad : "" }}</p></div>
            </div>
            <div v-show="item.conductor" class="row mb-1">
              <div class="col-6"><p class="bold">LICENCIA (LICENCE)</p></div>
              <div class="col-3"><p class="bold">VENCE<br>(EXPIRE)</p></div>
              <div class="col-3"><p class="bold">ESTADO<br>(STATE)</p></div>
            </div>
            <div v-show="item.conductor" class="row mb-2">
              <div class="col-6"><p>{{ item.conductor ? item.conductor.licencia.numero : "" }}</p></div>
              <div class="col-3"><p>{{ item.conductor ? item.conductor.licencia.vencimiento : "" }}</p></div>
              <div class="col-3"><p>{{ item.conductor ? item.conductor.licencia.estado : "" }}</p></div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <p class="text-start bold mb-2">COBERTURA CONTRATADA:
                  {{ textoEnMayusculas(item.tipoCobertura) }}</p>
                <p
                  v-for="item in item.tipoCobertura==='total'?coberturasPerTarifaTotal:coberturasPerTarifaTradicional"
                  :key="item.id">► {{ item.cobertura }}</p>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-4 d-flex align-items-end"><p class="bold">CARRO No. (CAR No.)</p></div>
              <div class="col-4 d-flex align-items-end"><p class="bold">MARCA (MAKE)</p></div>
              <div class="col-4 d-flex align-items-end"><p class="bold">MODELO (MODEL)</p></div>
            </div>
            <div class="row mb-2">
              <div class="col-4"><p>{{ item.vehiculo.noEcon }}</p></div>
              <div class="col-4"><p>{{ item.vehiculo.version.modelo.marca.name }}</p></div>
              <div class="col-4"><p>{{ item.vehiculo.version.modelo.name }}</p></div>
            </div>
            <div class="row mb-2">
              <div class="col-4 d-flex align-items-end"><p class="bold">PLACAS (PLATES)</p></div>
              <div class="col-4 d-flex align-items-end"><p class="bold">COLOR (COLOR)</p></div>
            </div>
            <div class="row mb-2">
              <div class="col-4"><p>{{ item.vehiculo.placa }}</p></div>
              <div class="col-4"><p>{{ item.vehiculo.color }}</p></div>
            </div>
          </div>
          <div class="col-6">
            <p class="mb-1 text-tarifas"><span class="bold">TARIFA DIARIA:</span>
              $ {{ item.tarifaDiaria }} MXN</p>
            <p class="mb-1 text-tarifas"><span class="bold">EXTRAS:</span>
              $ {{ numeroFormateado(item.tarifaExtra) }} MXN</p>
            <p class="mb-1 text-tarifas"><span class="bold">DIAS CONTRATADOS:</span>
              {{ item.dias }}</p>
            <p class="mb-1 text-tarifas"><span class="bold">SEGURO CONTRATADO:</span>
              {{ textoEnMayusculas(item.tipoCobertura) }}</p>
            <p class="mb-1 text-tarifas"><span class="bold">TARIFA DE SEGURO RC USA:</span>
              $ {{ item.seguroRc ? "200.00" : "0.00" }} MXN </p>
            <p class="mb-1 text-tarifas"><span class="bold">DIAS CONTRATADOS DE RC USA:</span>
              {{ item.seguroRc ? item.dias : 0 }}</p>
            <p class="mb-1 text-tarifas"><span class="bold" v-if="item.cortesia">SUB TOTAL:</span><span class="bold"
                                                                                                        v-else>TOTAL:</span>
              $ {{ numeroFormateado(item.total) }} MXN</p>
            <p v-if="item.cortesia" class="mb-1 text-tarifas"><span class="bold">MONTO DE CORTESÍA:</span>
              $ {{ numeroFormateado(monto_cortesia) }} MXN</p>
            <p v-if="item.cortesia" class="mb-1 text-tarifas"><span class="bold">TOTAL:</span>
              $ {{ numeroFormateado(item.total - monto_cortesia) }} MXN</p>
            <p class="mb-1 text-tarifas">
              <span v-if="item.tipoPago==='tarjeta'" class="bold">PAGADO CON TARJETA:</span>
              <span v-else class="bold">PAGADO EN EFECTIVO:</span>
              $ {{ numeroFormateado(item.depositoEfectivo) }} MXN</p>
            <p class="mb-5 text-tarifas"><span
              class="bold">CHECK IN:</span> $
              {{ numeroFormateado(item.checkin) }} MXN</p>
            <div class="row mb-3">
              <div class="col-3"></div>
              <div class="col-6 border-top border-dark">
                <p>ACEPTO TÉRMINOS Y CONDICIONES</p>
              </div>
              <div class="col-3"></div>
            </div>
            <p class="text-center bold mb-2">
              RECONOCIMIENTO DE RESPONSABILIDAD Y AUTORIZACION EN EL CASO DE PAGO POR TARJETA.</p>
            <p class="text-justify mb-5" style="line-height: 15px">POR MEDIO DE LA
              PRESENTE AUTORIZO A <span
                class="bold">TRANSPORTES DE CALIDAD Y CONFORT S. DEL R.L.</span> DE
              C.V. PARA QUE DENTRO DE LAS CONDICIONES QUE ESTABLECE ESTE CONTRATO CARGUEN A MI TARJETA DE CRÉDITO CON
              No. {{ item.tarjeta? item.tarjeta.numero:"" }} CON FECHA DE
              VENCIMIENTO:
              {{ item.tarjeta ? formatearFechaCorta(item.tarjeta.vencimiento) : "" }} LAS CANTIDADES POR
              CONCEPTO DE RENTA DIARIA Y/O DAÑOS AL VEHÍCULO SEAN
              ORIGINADOS</p>
            <div class="row mb-3">
              <div class="col-3"></div>
              <div class="col-6 border-top border-dark">
                <p>ACEPTO Y AUTORIZO LOS CARGOS</p>
              </div>
              <div class="col-3"></div>
            </div>
          </div>
        </div>
        <div class="row mb-5 text-contrato">
          <div class="col-12 border border-secondary p-2">
            <p class="text-center bold mb-3">PAGARÉ</p>
            <p class="text-justify mb-5" style="line-height: 18px">Por este pagare me(nos) obligo(amos) a pagar
              incondicionalmente a
              __________________________ en cualquier parte que me requiera la cantidad de $_________________,
              (______________________________________) en la ciudad de ___________________________ a
              _________________________ de 20____.</p>
            <div class="row">
              <div class="col-2">
              </div>
              <div class="col-3 border-top border-dark">
                <p class="text-center">ACEPTO TÉRMINOS Y CONDICIONES</p>
              </div>
              <div class="col-2"></div>
              <div class="col-3 border-top border-dark">
                <p class="text-center">ACEPTO TÉRMINOS Y CONDICIONES</p>
              </div>
              <div class="col-2">
              </div>
            </div>
          </div>
        </div>

        <div class="row cuerpo page-contract p-2">
          <div class="col-4">
            <div class="d-flex justify-content-center align-items-center mb-2">
              <img src="@/assets/img/issyrentallogo.png"
                   style="width: 150px; height: 75px">
            </div>
            <p class="text-center bold mb-1">R.F.C. TCC160706BL7</p>
            <p class="text-center bold mb-1">Calle 19 #303 A Fracc. Las Californias Tijuana BC CP 22404 Tel: 664 524
              2099</p>
            <p class="text-center bold mb-3">Expediente PROFECO PFC.B.E 7/007713-2017</p>
            <p class="text-justify">Contrato de arredramiento de vehículos que celebran por una parte el proveedor
              TRANSPORTES DE CALIDAD Y CONFORT S DE RL DE CV representado en este acto por el C. y en contraparte el
              consumidor, mismos que por
              medio del presente manifiestan su voluntad para obligarse de acuerdo al siguiente glosario, así como a
              las declaraciones y cláusulas que a continuación se describen:</p>
            <p>GLOSARIO</p>
            <p class="text-justify">a. Consumidor: Persona física que obtiene en
              arrendamiento el uso y goce temporal del vehículo objeto de este contrato quien para efectos de este
              contrato recibirá el nombre de: arrendatario.
            </p>
            <p class="text-justify">
              b. Proveedor: Persona física o moral que ofrece en
              arrendamiento, el uso y goce temporal de bienes muebles a cambio de una contraprestación cierta y
              determinada, quien para efectos de este contrato recibirá el nombre de: arrendador.

            </p>
            <p class="text-justify">
              c. Vehículo: Aquel bien mueble que es objeto material de este contrato, mismo que se encuentra descrito
              en el anexo de este contrato.
            </p>
            <p>DECLARACIONES</p>
            <p>PRIMERA. DECLARA EL ARRENDADOR:</p>
            <p class="text-justify">► Ser una persona mexicana, constituida conforme la legislación nacional
              aplicable,
              según consta en la escritura pública número 59,242, del cuatro de Julio del año dos mil diez y seis,
              exhibida ante la fe del Lic. Enrique Gallaga Esparza, Notario Publico numero quince, en la ciudad de
              Tijuana en el Estado de Baja California, e inscrita en el registro público del comercio de Tijuana Baja
              California bajo el número 201600046763 de fecha 29/09/2016.</p>
            <p class="text-justify">► Que acredita con su representante legal para todos los efectos de este contrato,
              a
              la persona física determinada en el preludio del presente, quien valida su personalidad jurídica
              mediante el
              testimonio notarial número 59242 de fecha 04 de Julio de 2016, exhibida ante la fe del(a) Lic. Enrique
              Gallaga Esparza, notario público número 15, en el estado de Baja California.
            </p>
            <p class="text-justify">► Que su registro Federal de Contribuyentes corresponde al número:
              TCC160706BL7.</p>
            <p class="text-justify">► Que pone a disposición del consumidor como línea de contacto para cualquier
              asunto
              relacionado con este contrato de número telefónico Tel... (664) 524 2099 en los siguientes horarios de
              atención: 8:00 AM a 20:00 PM y la dirección de correo electrónico siguiente: ventas@issyrental.com</p>
            <p class="text-justify">► Que cuenta con los recursos humanos, financieros y materiales para llevar a cabo
              las
              obligaciones emanadas de este acto jurídico.</p>
            <p class="text-justify">► Que dentro de sus actividades se encuentra la de otorgar en arrendamiento
              vehículos.</p>
            <p class="text-justify">► Que cuenta con las licencias y permisos requeridos por la ley para prestar el
              servicio correspondiente,</p>
            <p class="text-justify">► Que informó al arrendatario los alcances y efectos jurídicos del presente
              contrato.</p>
            <p>SEGUNDA. DECLARA EL ARRENDATARIO:</p>
            <p class="text-justify">a. Llamarse según lo notado en el preludio de este contrato, así mismo como contar
              con la capacidad legal para cumplir con las obligaciones contenidas en este instrumento contractual.</p>
            <p class="text-justify">b. Que cuenta con la capacidad legal, en términos de las leyes aplicables, para
              obligarse bajo los términos y condicionescontenidos en este contrato. </p>
            <p class="text-justify">c. Que en su deseo contratar el arrendamiento objeto de este en los términos y
              condiciones que se establecen en este documento.</p>
            <p class="text-justify">d. Que sus generales corresponden a las anotadas en el anexo del contrato</p>
          </div>
          <div class="col-4">
            <p>CLAUSULAS</p>
            <p class="text-justify">PRIMERA. Consentimiento. Por medio del presente contrato, el arrendador se obliga
              a
              conceder el uso y goce temporal del vehículo, por lo que el arrendatario deberá pagar un precio cierto y
              determinado. </p>
            <p class="text-justify">SEGUNDA. Objeto. El objeto material de este contrato es el vehículo que se
              encuentra
              descrito en el anexo de este contrato. Por lo que las características, condiciones, refacciones y
              documentos generales del vehículo arrendado se encuentran detalladas en el documento mencionado. </p>
            <p class="text-justify">TERCERA. Condiciones del vehículo arrendado. El arrendatario recibe de conformidad
              el vehículo arrendado, el cual se encuentra en óptimas condiciones mecánicas y de carrocería, las cuales
              se mencionan en el inventario respectivo. Acordando las partes que el vehículo se entregue con el
              kilometraje sellado, el uso y goce del vehículo se destinará exclusivamente al fin pactado con los
              contratantes. En caso de que el uso y goce esté destinado únicamente al transporte del arrendatario y
              sus acompañantes deberá señalarse por escrito para la debida información de las partes. Asimismo, el
              arrendatario recibe, exceptuando los vicios ocultos, el vehículo a su entera satisfacción por lo que
              obliga a pagar al arrendador a la terminación del contrato, a precios del mercado, el o los faltantes de
              accesorios y partes del vehículo arrendado al momento de la entrega del mismo. </p>
            <p class="text-justify">CUARTA. Lugar de entrega y recepción del vehículo. El arrendador deberá entregar
              el
              vehículo arrendado en el lugar previamente acordado entre las partes, respetando en la entrega el mismo
              día y hora señalada en el anexo del presente contrato. El arrendatario, al término de la vigencia del
              presente documento, deberá entregar el vehículo, en las mismas condiciones en que lo recibió exceptuando
              el desgaste por el uso, en el día y las horas señaladas para tal efecto obligándose a entregar el
              vehículo al arrendador en el lugar determinado por las partes para tal efecto. </p>
            <p class="text-justify">QUINTA. Plazo del arrendamiento. La vigencia de este contrato será la señalada en
              el
              anexo del presente documento, la cual no podrá ser prorrogada sino con el pleno consentimiento de ambas
              partes expresando en un nuevo contrato de arrendamiento. </p>
            <p class="text-justify">
              SEXTA. Precio del arrendamiento. El arrendamiento por el uso y goce temporal del vehículo arrendado,
              deberá pagar una cantidad cierta y determinada en moneda nacional sin perjuicio que las partes puedan
              acordar el pago en moneda extranjera conforme a las leyes aplicables la cual se encuentra enunciada en
              el anexo de este contrato.
            </p>
            <p class="text-justify">SÉPTIMA. Modalidades de pago. El arrendatario podrá pagar la renta del vehículo al
              contado en el domicilio del arrendador, con la tarjeta bancaria, transferencia electrónica o cualquier
              otra forma de pago que acuerden las partes. El precio total del arrendamiento se calculará tomando en
              cuenta el costo por renta diario o por kilometraje, de acuerdo a lo solicitado por el consumidor. La
              renta empezará a computarse desde el momento en que el consumidor se encuentre en plena disposición del
              vehículo arrendado y hasta la fecha en que lo reciba el arrendador a su entera satisfacción. En caso de
              que la arrendataria hubiere contratado el arrendamiento del vehículo por kilometraje recorridos, estos
              determinarán por la lectura del kilometraje, registrado en el dispositivo instalado de fábrica en el
              vehículo (odómetro), estipulando las partes que sí, durante el término del arrendamiento, sobreviene
              algún desperfecto o la rotura de los protectores de dicho sistema, por culpa o negligencia del
              arrendatario, la renta se calculará tomando en cuenta la tarifa de renta por día que se establece en el
              anexo de este contrato, durante el tiempo en que el vehículo esté en posesión del arrendamiento. </p>
            <p class="text-justify">OCTAVA. Depósito en garantía. El arrendatario se obliga a entregar al arrendador
              la
              cantidad señalada en el anexo de este contrato como el depósito en garantía del cumplimiento de la
              obligación principal del pago. En consecuencia, el arrendador deberá expedir un recibo por dicha
              cantidad en que conste: el nombre o razón social de la misma, fecha e importe del depósito, nombre y
              firma de la persona que lo recibe. Este recibo servirá de comprobante de canje para que al término del
              contrato el arrendador entregue la cantidad depositada dentro de las 24 horas siguientes a la recepción
              del vehículo de conformidad, en caso contrario dicho depósito se aplicará a solventar los saldos su los
              hubiere o a pagar las reposiciones de faltantes y la reparación de desperfectos, cuando hayan sido
              debidamente acreditados por el arrendador, en la inteligencia que la arrendadora podrá exigir, judicial
              o extrajudicialmente el pago de una cantidad adicional si el depósito fuere insuficiente para cubrir la
              reposición y la reparación de saldos. </p>

          </div>
          <div class="col-4">
            <p class="text-justify">NOVENA. Devolución del vehículo. El arrendatario se obliga a devolver al término
              de
              la vigencia del presente contrato el vehículo arrendado en las mismas condiciones en que lo recibió
              exceptuando el desgaste proveniente del uso normal del vehículo durante al arrendamiento. Las partes
              acuerdan en que la entrega del vehículo arrendado se lleve a cabo en fecha, lugar y hora determinados en
              el anexo de este contrato.</p>
            <p class="text-justify">En caso de que el vehículo no sea entregado en los términos señalados, la
              arrendataria podrá entregarlo posteriormente, previo acuerdo de las partes, pagando por este retraso el
              importe de la renta conforme a la tarifa acordada por el tiempo que tarda en entregar el vehículo en la
              fecha y hora determinados; si el retraso en la entrega del vehículo corresponde a la hora, el
              arrendatario sólo estará obligado a pagar la parte proporcional del incumplimiento. </p>
            <p class="text-justify">DÉCIMA. Prohibición al vehículo arrendado de salir de la república. Sin el previo
              consentimiento por escrito del arrendador, el vehículo arrendado no podrá salir de los límites de la
              República Mexicana: en caso de incumplimiento a lo anterior señalado, el arrendador podrá dar por
              rescindido este contrato, recuperando el vehículo en las condiciones y estados en que se localiza,
              siendo responsable la arrendataria del pago de la pena convencional correspondiente, más de los gastos
              de recuperación del vehículo debidamente comprobados por el arrendador. </p>
            <p class="text-justify">DÉCIMA PRIMERA. Derechos y obligaciones de las partes. Los contratantes se
              reconocen
              como derechos exigibles, el cumplimiento de todas las disposiciones del presente contrato, normando su
              consentimiento por la observancia de las siguientes obligaciones: En el cumplimiento del presente
              contrato el arrendador se obliga a: </p>
            <p class="text-justify">a. Entregar el vehículo arrendado en óptimas condiciones de uso, considerando el
              combustible necesario para tal efecto; el día, hora y lugar acordado por las partes. </p>
            <p class="text-justify">b. Recibir el vehículo sin ninguna condicionante de pago por el límite de
              gasolina,
              en la inteligencia que el vehículo por ninguna circunstancia deberá ser entregado por el arrendatario
              con un nivel menor a un cuarto de tanque. </p>
            <p class="text-justify">c. Al recibir el vehículo arrendado, señalando al arrendatario de ser el caso que
              el
              vehículo lo recibe en su entera satisfacción; de lo contrario deberá manifestar en el acto de recepción
              los motivos de su proceder. </p>
            <p class="text-justify">d. Devolver al arrendatario en el tiempo estipulado para tal efecto la cantidad
              otorgada en depósito en garantía. </p>
            <p class="text-justify">Para los efectos de este contrato, son obligaciones del arrendatario: </p>
            <p class="text-justify">a. Pagar al arrendador la renta convenida del vehículo arrendado de manera
              puntual,
              sin requerimiento de pago y en las condiciones establecidas en el presente contrato.</p>
            <p class="text-justify">b. Conducir en todo momento el vehículo arrendado bajo el amparo de la licencia
              respectiva, otorgada por las autoridades competentes; respetando los Reglamentos y Leyes de Tránsito en
              el ámbito Federal, Local o Municipal. </p>
            <p class="text-justify">c. No manejar el vehículo en estado de ebriedad o bajo la influencia de
              drogas. </p>
            <p class="text-justify">d. No hacer uso del vehículo en forma lucrativa, ni subarrendar. </p>
            <p class="text-justify">e. No utilizar el vehículo arrendado para arrastrar remolques y no sobrecargarlo
              debiéndose usar conforme a su resistencia y capacidad normal. </p>
            <p class="text-justify">f. Conservar el vehículo en el estado que lo recibió exceptuando el desgaste
              normal
              de uso. </p>
            <p class="text-justify">g. No conducir en el interior del vehículo materias explosivas o inflamables,
              drogas
              o estupefacientes. </p>
            <p class="text-justify">h. Pagar el importe de las sanciones que le fueran impuestas por violación a los
              Reglamentos de Transito, aun después de concluida la vigencia del contrato, si la infracción se origino
              durante el tiempo en que estuvo el vehículo arrendado a disposición del arrendatario. </p>
            <p class="text-justify">i. No utilizar el vehículo de manera diferente a lo pactado. </p>
            <p class="text-justify">j. No subarrendar a terceros el vehículo objeto del presente contrato sin previo
              consentimiento del arrendador.</p>
          </div>
        </div>

        <div class="row mb-3 page-contract">
          <div class="col-2">
            <img src="@/assets/img/issyrentallogo.png"
                 style="width: 150px; height: 75px">
          </div>
          <div class="col-8 text-center">
            <p style="font-weight: bold; font-size: 14px; line-height: 25px;">Transporte de Calidad y Confort S, de R.L.
              de C.V.</p>
            <p style="font-weight: bold; font-size: 14px; margin-bottom: 30px">R.F.C. TCC 160706-BL7</p>
            <p>Correo electrónico: info@issyrental.com</p>
            <p>Teléfono: (664) 524-2099 Cel.: (664) 361 7721</p>
            <p>Calle 19 #303-A Fracc. Las Californias, Tijuana, B.C., C.P. 22404</p>
            <p>Sucursal: {{ item.sucursal.name }}, {{ item.sucursal.address }}</p>
          </div>
          <div class="col-2 text-center">
            <p>Contrato de Arrendamiento</p>
            <p>(RENTAL AGREMENT)</p>
            <p>APENDICE “A”</p>
            <p>CONTRATO</p>
            <p style="font-weight: bold;">{{ item.numContrato }}</p>
          </div>
        </div>
        <div class="row mb-1 text-contrato">
          <div class="col-4 d-flex align-items-end"><p class="bold">NOMBRE (NAME)</p></div>
          <div class="col-2 d-flex align-items-end"><p class="bold">EDAD (AGE)</p></div>
          <div class="col-2 d-flex align-items-end"><p class="bold">FECHA SALIDA<br> (DATE OUT)</p></div>
          <div class="col-2 d-flex align-items-end"><p class="bold">TIEMPO SALIDA<br> (TIME OUT)/HR</p></div>
        </div>
        <div class="row mb-2 text-contrato">
          <div class="col-4"><p>{{ item.cliente.nombre }} {{ item.cliente.apellidos }}</p></div>
          <div class="col-2"><p>{{ item.cliente.edad }}</p></div>
          <div class="col-2"><p>{{ obtenerFecha(item.fechaSalida) }}</p></div>
          <div class="col-2"><p>{{ obtenerHora(item.fechaSalida) }}</p></div>
        </div>
        <div class="row mb-1 text-contrato">
          <div class="col-6 d-flex align-items-end"><p class="bold">DIRECCIÓN LOCAL (LOCAL ADDRESS)</p></div>
          <div class="col-2 d-flex align-items-end"><p class="bold">FECHA ENTRADA<br> (DATE IN)</p></div>
          <div class="col-2 d-flex align-items-end"><p class="bold">TIEMPO ENTRADA<br> (TIME IN)/HR</p></div>
          <div class="col-2 d-flex align-items-end"><p class="bold">PRECIO DE HORA EXTRA</p></div>
        </div>
        <div class="row mb-2 text-contrato">
          <div class="col-6"><p>{{ item.cliente.direccion }}</p></div>
          <div class="col-2"><p>{{ obtenerFecha(item.fechaRegreso) }}</p></div>
          <div class="col-2"><p>{{ obtenerHora(item.fechaRegreso) }}</p></div>
          <div class="col-2"><p>$ {{ numeroFormateado(item.precioHoraExtra) }} MXN</p></div>
        </div>
        <div class="row mb-1 text-contrato">
          <div class="col-12 d-flex align-items-end">
            <div class="row mb-1"><p class="bold">TARJETA DE CREDITO No. (CREDIT CARD No.)</p></div>
          </div>
        </div>
        <div class="row mb-2 text-contrato">
          <div class="col-6">
            <div class="row mb-1">
              <p>{{ item.tarjeta ? item.tarjeta.numero : "Pago en efectivo" }}</p>
            </div>
            <div class="row mb-1">
              <div class="col-6 d-flex align-items-end"><p class="bold">VENCE (EXPIRE)</p></div>
              <div class="col-6"><p class="bold">AUTORIZACION<br>(AUTHORIZATION)</p></div>
            </div>
            <div class="row mb-2">
              <div class="col-6"><p>{{ item.tarjeta ? item.tarjeta.vencimiento : "Pago en efectivo" }}</p></div>
              <div class="col-6"><p>{{ item.tarjeta ? item.tarjeta.autorizacion : "Pago en efectivo" }}</p></div>
            </div>
            <div class="row mb-1">
              <div class="col-6 d-flex align-items-end"><p class="bold">LICENCIA (LICENCE)</p></div>
              <div class="col-3"><p class="bold">VENCE<br>(EXPIRE)</p></div>
              <div class="col-3"><p class="bold">ESTADO<br>(STATE)</p></div>
            </div>
            <div class="row mb-2">
              <div class="col-6"><p>{{ item.cliente.licencia.numero }}</p></div>
              <div class="col-3"><p>{{ item.cliente.licencia.vencimiento }}</p></div>
              <div class="col-3"><p>{{ item.cliente.licencia.estado }}</p></div>
            </div>
            <div class="row mb-1">
              <div class="col-6"><p class="bold">TELÉFONO DE CONTACTO<br>
                (CONTACT PHONE)</p></div>
              <div class="col-6"><p class="bold">TELÉFONO DE EMERGENCIA<br> (EMERGENCY PHONE)</p></div>
            </div>
            <div class="row mb-2">
              <div class="col-6"><p>{{ item.cliente.telefono }}</p></div>
              <div class="col-6"><p>{{ item.cliente.telefonoEmergencia }}</p></div>
            </div>
            <div class="row mb-1">
              <div class="col-9"><p class="bold">OTRO CONDUCTOR ADICIONAL (ADITIONAL DRIVER)</p></div>
              <div class="col-3"><p class="bold">EDAD (AGE)</p></div>
            </div>
            <div class="row mb-2">
              <div class="col-9"><p>{{ item.conductor ? item.conductor.nombre : "" }}
                {{ item.conductor ? item.conductor.apellidos : "" }}</p></div>
              <div class="col-3"><p>{{ item.conductor ? item.conductor.edad : "" }}</p></div>
            </div>
            <div class="row mb-1">
              <div class="col-6"><p class="bold">LICENCIA (LICENCE)</p></div>
              <div class="col-3"><p class="bold">VENCE<br>(EXPIRE)</p></div>
              <div class="col-3"><p class="bold">ESTADO<br>(STATE)</p></div>
            </div>
            <div class="row mb-2">
              <div class="col-6"><p>{{ item.conductor ? item.conductor.licencia.numero : "" }}</p></div>
              <div class="col-3"><p>{{ item.conductor ? item.conductor.licencia.vencimiento : "" }}</p></div>
              <div class="col-3"><p>{{ item.conductor ? item.conductor.licencia.estado : "" }}</p></div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <p class="text-start bold mb-2">TIPO DE SEGURO CONTRATADO:
                  {{ textoEnMayusculas(item.tipoCobertura) }}</p>
                <p
                  v-for="item in item.tipoCobertura==='total'?coberturasPerTarifaTotal:coberturasPerTarifaTradicional"
                  :key="item.id">► {{ item.cobertura }}</p>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-4 d-flex align-items-end"><p class="bold">CARRO No. (CAR No.)</p></div>
              <div class="col-4 d-flex align-items-end"><p class="bold">MARCA (MAKE)</p></div>
              <div class="col-4 d-flex align-items-end"><p class="bold">MODELO (MODEL)</p></div>
            </div>
            <div class="row mb-2">
              <div class="col-4"><p>{{ item.vehiculo.noEcon }}</p></div>
              <div class="col-4"><p>{{ item.vehiculo.version.modelo.marca.name }}</p></div>
              <div class="col-4"><p>{{ item.vehiculo.version.modelo.name }}</p></div>
            </div>
            <div class="row mb-2">
              <div class="col-4 d-flex align-items-end"><p class="bold">PLACAS (PLATES)</p></div>
              <div class="col-4 d-flex align-items-end"><p class="bold">COLOR (COLOR)</p></div>
            </div>
            <div class="row mb-2">
              <div class="col-4"><p>{{ item.vehiculo.placa }}</p></div>
              <div class="col-4"><p>{{ item.vehiculo.color }}</p></div>
            </div>
          </div>
          <div class="col-6">
            <p class="mb-1 text-tarifas"><span class="bold">TARIFA DIARIA:</span>
              $ {{ item.tarifaDiaria }} MXN</p>
            <p class="mb-1 text-tarifas"><span class="bold">EXTRAS:</span>
              $ {{ numeroFormateado(item.tarifaExtra) }} MXN</p>
            <p class="mb-1 text-tarifas"><span class="bold">DIAS CONTRATADOS:</span>
              {{ item.dias }}</p>
            <p class="mb-1 text-tarifas"><span class="bold">SEGURO CONTRATADO:</span>
              {{ textoEnMayusculas(item.tipoCobertura) }}</p>
            <p class="mb-1 text-tarifas"><span class="bold">TARIFA DE SEGURO RC USA:</span>
              $ {{ item.seguroRc ? "200.00" : "0.00" }} MXN </p>
            <p class="mb-1 text-tarifas"><span class="bold">DIAS CONTRATADOS DE RC USA:</span>
              {{ item.seguroRc ? item.dias : 0 }}</p>
            <p class="mb-1 text-tarifas"><span class="bold" v-if="item.cortesia">SUB TOTAL:</span><span class="bold"
                                                                                                        v-else>TOTAL:</span>
              $ {{ numeroFormateado(item.total) }} MXN</p>
            <p v-if="item.cortesia" class="mb-1 text-tarifas"><span class="bold">MONTO DE CORTESÍA:</span>
              $ {{ numeroFormateado(monto_cortesia) }} MXN</p>
            <p v-if="item.cortesia" class="mb-1 text-tarifas"><span class="bold">TOTAL:</span>
              $ {{ numeroFormateado(item.total - monto_cortesia) }} MXN</p>
            <p v-if="item.tipoPago=== 'tarjeta'" class="mb-1 text-tarifas"><span class="bold">PAGADO CON TARJETA:</span> $
              {{ numeroFormateado(item.depositoEfectivo) }} MXN</p>
            <p v-else class="mb-1 text-tarifas"><span class="bold">DEPÓSITO EN EFECTIVO:</span> $
              {{ numeroFormateado(item.depositoEfectivo) }} MXN</p>
            <p class="mb-5 text-tarifas"><span class="bold">CHECK IN:</span> $
              {{ numeroFormateado(item.checkin) }} MXN</p>
            <div class="row mb-3">
              <div class="col-3"></div>
              <div class="col-6 border-top border-dark">
                <p>ACEPTO TÉRMINOS Y CONDICIONES</p>
              </div>
              <div class="col-3"></div>
            </div>
            <p class="text-center bold mb-2">RECONOCIMIENTO DE RESPONSABILIDAD Y AUTORIZACION EN EL CASO DE PAGO POR
              TARJETA.</p>
            <p class="text-justify mb-5" style="line-height: 15px">POR MEDIO DE LA PRESENTE AUTORIZO A <span
              class="bold">TRANSPORTES DE CALIDAD Y CONFORT S. DEL R.L.</span> DE
              C.V. PARA QUE DENTRO DE LAS CONDICIONES QUE ESTABLECE ESTE CONTRATO CARGUEN A MI TARJETA DE CRÉDITO CON
              No. {{ item.tarjeta ? item.tarjeta.numero : "" }} CON FECHA DE
              VENCIMIENTO:
              {{ item.tarjeta ? formatearFechaCorta(item.tarjeta.vencimiento) : "" }} LAS CANTIDADES POR
              CONCEPTO DE RENTA DIARIA Y/O DAÑOS AL VEHÍCULO SEAN
              ORIGINADOS</p>
            <div class="row mb-3">
              <div class="col-3"></div>
              <div class="col-6 border-top border-dark">
                <p>ACEPTO Y AUTORIZO LOS CARGOS</p>
              </div>
              <div class="col-3"></div>
            </div>
          </div>
        </div>

        <div class="row cuerpo page-contract p-2">


          <div class="col-4">
            <div class="d-flex justify-content-center align-items-center mb-2">
              <img src="@/assets/img/issyrentallogo.png"
                   style="width: 150px; height: 75px">
            </div>
            <p class="text-center bold mb-1">R.F.C. TCC160706BL7</p>
            <p class="text-center bold mb-1">Calle 19 #303 A Fracc. Las Californias Tijuana BC CP 22404 Tel: 664 524
              2099</p>
            <p class="text-center bold mb-3">Expediente PROFECO PFC.B.E 7/007713-2017</p>
            <p class="text-justify">Contrato de arredramiento de vehículos que celebran por una parte el proveedor
              TRANSPORTES DE CALIDAD Y CONFORT S DE RL DE CV representado en este acto por el C. y en contraparte el
              consumidor, mismos que por
              medio del presente manifiestan su voluntad para obligarse de acuerdo al siguiente glosario, así como a
              las declaraciones y cláusulas que a continuación se describen:</p>
            <p>GLOSARIO</p>
            <p class="text-justify">a. Consumidor: Persona física que obtiene en
              arrendamiento el uso y goce temporal del vehículo objeto de este contrato quien para efectos de este
              contrato recibirá el nombre de: arrendatario.
            </p>
            <p class="text-justify">
              b. Proveedor: Persona física o moral que ofrece en
              arrendamiento, el uso y goce temporal de bienes muebles a cambio de una contraprestación cierta y
              determinada, quien para efectos de este contrato recibirá el nombre de: arrendador.

            </p>
            <p class="text-justify">
              c. Vehículo: Aquel bien mueble que es objeto material de este contrato, mismo que se encuentra descrito
              en el anexo de este contrato.
            </p>
            <p>DECLARACIONES</p>
            <p>PRIMERA. DECLARA EL ARRENDADOR:</p>
            <p class="text-justify">► Ser una persona mexicana, constituida conforme la legislación nacional
              aplicable,
              según consta en la escritura pública número 59,242, del cuatro de Julio del año dos mil diez y seis,
              exhibida ante la fe del Lic. Enrique Gallaga Esparza, Notario Publico numero quince, en la ciudad de
              Tijuana en el Estado de Baja California, e inscrita en el registro público del comercio de Tijuana Baja
              California bajo el número 201600046763 de fecha 29/09/2016.</p>
            <p class="text-justify">► Que acredita con su representante legal para todos los efectos de este contrato,
              a
              la persona física determinada en el preludio del presente, quien valida su personalidad jurídica
              mediante el
              testimonio notarial número 59242 de fecha 04 de Julio de 2016, exhibida ante la fe del(a) Lic. Enrique
              Gallaga Esparza, notario público número 15, en el estado de Baja California.
            </p>
            <p class="text-justify">► Que su registro Federal de Contribuyentes corresponde al número:
              TCC160706BL7.</p>
            <p class="text-justify">► Que pone a disposición del consumidor como línea de contacto para cualquier
              asunto
              relacionado con este contrato de número telefónico Tel... (664) 524 2099 en los siguientes horarios de
              atención: 8:00 AM a 20:00 PM y la dirección de correo electrónico siguiente: ventas@issyrental.com</p>
            <p class="text-justify">► Que cuenta con los recursos humanos, financieros y materiales para llevar a cabo
              las
              obligaciones emanadas de este acto jurídico.</p>
            <p class="text-justify">► Que dentro de sus actividades se encuentra la de otorgar en arrendamiento
              vehículos.</p>
            <p class="text-justify">► Que cuenta con las licencias y permisos requeridos por la ley para prestar el
              servicio correspondiente,</p>
            <p class="text-justify">► Que informó al arrendatario los alcances y efectos jurídicos del presente
              contrato.</p>
            <p>SEGUNDA. DECLARA EL ARRENDATARIO:</p>
            <p class="text-justify">a. Llamarse según lo notado en el preludio de este contrato, así mismo como contar
              con la capacidad legal para cumplir con las obligaciones contenidas en este instrumento contractual.</p>
            <p class="text-justify">b. Que cuenta con la capacidad legal, en términos de las leyes aplicables, para
              obligarse bajo los términos y condicionescontenidos en este contrato. </p>
            <p class="text-justify">c. Que en su deseo contratar el arrendamiento objeto de este en los términos y
              condiciones que se establecen en este documento.</p>
            <p class="text-justify">d. Que sus generales corresponden a las anotadas en el anexo del contrato</p>
          </div>
          <div class="col-4">
            <p>CLAUSULAS</p>
            <p class="text-justify">PRIMERA. Consentimiento. Por medio del presente contrato, el arrendador se obliga
              a
              conceder el uso y goce temporal del vehículo, por lo que el arrendatario deberá pagar un precio cierto y
              determinado. </p>
            <p class="text-justify">SEGUNDA. Objeto. El objeto material de este contrato es el vehículo que se
              encuentra
              descrito en el anexo de este contrato. Por lo que las características, condiciones, refacciones y
              documentos generales del vehículo arrendado se encuentran detalladas en el documento mencionado. </p>
            <p class="text-justify">TERCERA. Condiciones del vehículo arrendado. El arrendatario recibe de conformidad
              el vehículo arrendado, el cual se encuentra en óptimas condiciones mecánicas y de carrocería, las cuales
              se mencionan en el inventario respectivo. Acordando las partes que el vehículo se entregue con el
              kilometraje sellado, el uso y goce del vehículo se destinará exclusivamente al fin pactado con los
              contratantes. En caso de que el uso y goce esté destinado únicamente al transporte del arrendatario y
              sus acompañantes deberá señalarse por escrito para la debida información de las partes. Asimismo, el
              arrendatario recibe, exceptuando los vicios ocultos, el vehículo a su entera satisfacción por lo que
              obliga a pagar al arrendador a la terminación del contrato, a precios del mercado, el o los faltantes de
              accesorios y partes del vehículo arrendado al momento de la entrega del mismo. </p>
            <p class="text-justify">CUARTA. Lugar de entrega y recepción del vehículo. El arrendador deberá entregar
              el
              vehículo arrendado en el lugar previamente acordado entre las partes, respetando en la entrega el mismo
              día y hora señalada en el anexo del presente contrato. El arrendatario, al término de la vigencia del
              presente documento, deberá entregar el vehículo, en las mismas condiciones en que lo recibió exceptuando
              el desgaste por el uso, en el día y las horas señaladas para tal efecto obligándose a entregar el
              vehículo al arrendador en el lugar determinado por las partes para tal efecto. </p>
            <p class="text-justify">QUINTA. Plazo del arrendamiento. La vigencia de este contrato será la señalada en
              el
              anexo del presente documento, la cual no podrá ser prorrogada sino con el pleno consentimiento de ambas
              partes expresando en un nuevo contrato de arrendamiento. </p>
            <p class="text-justify">
              SEXTA. Precio del arrendamiento. El arrendamiento por el uso y goce temporal del vehículo arrendado,
              deberá pagar una cantidad cierta y determinada en moneda nacional sin perjuicio que las partes puedan
              acordar el pago en moneda extranjera conforme a las leyes aplicables la cual se encuentra enunciada en
              el anexo de este contrato.
            </p>
            <p class="text-justify">SÉPTIMA. Modalidades de pago. El arrendatario podrá pagar la renta del vehículo al
              contado en el domicilio del arrendador, con la tarjeta bancaria, transferencia electrónica o cualquier
              otra forma de pago que acuerden las partes. El precio total del arrendamiento se calculará tomando en
              cuenta el costo por renta diario o por kilometraje, de acuerdo a lo solicitado por el consumidor. La
              renta empezará a computarse desde el momento en que el consumidor se encuentre en plena disposición del
              vehículo arrendado y hasta la fecha en que lo reciba el arrendador a su entera satisfacción. En caso de
              que la arrendataria hubiere contratado el arrendamiento del vehículo por kilometraje recorridos, estos
              determinarán por la lectura del kilometraje, registrado en el dispositivo instalado de fábrica en el
              vehículo (odómetro), estipulando las partes que sí, durante el término del arrendamiento, sobreviene
              algún desperfecto o la rotura de los protectores de dicho sistema, por culpa o negligencia del
              arrendatario, la renta se calculará tomando en cuenta la tarifa de renta por día que se establece en el
              anexo de este contrato, durante el tiempo en que el vehículo esté en posesión del arrendamiento. </p>
            <p class="text-justify">OCTAVA. Depósito en garantía. El arrendatario se obliga a entregar al arrendador
              la
              cantidad señalada en el anexo de este contrato como el depósito en garantía del cumplimiento de la
              obligación principal del pago. En consecuencia, el arrendador deberá expedir un recibo por dicha
              cantidad en que conste: el nombre o razón social de la misma, fecha e importe del depósito, nombre y
              firma de la persona que lo recibe. Este recibo servirá de comprobante de canje para que al término del
              contrato el arrendador entregue la cantidad depositada dentro de las 24 horas siguientes a la recepción
              del vehículo de conformidad, en caso contrario dicho depósito se aplicará a solventar los saldos su los
              hubiere o a pagar las reposiciones de faltantes y la reparación de desperfectos, cuando hayan sido
              debidamente acreditados por el arrendador, en la inteligencia que la arrendadora podrá exigir, judicial
              o extrajudicialmente el pago de una cantidad adicional si el depósito fuere insuficiente para cubrir la
              reposición y la reparación de saldos. </p>

          </div>
          <div class="col-4">
            <p class="text-justify">NOVENA. Devolución del vehículo. El arrendatario se obliga a devolver al término
              de
              la vigencia del presente contrato el vehículo arrendado en las mismas condiciones en que lo recibió
              exceptuando el desgaste proveniente del uso normal del vehículo durante al arrendamiento. Las partes
              acuerdan en que la entrega del vehículo arrendado se lleve a cabo en fecha, lugar y hora determinados en
              el anexo de este contrato.</p>
            <p class="text-justify">En caso de que el vehículo no sea entregado en los términos señalados, la
              arrendataria podrá entregarlo posteriormente, previo acuerdo de las partes, pagando por este retraso el
              importe de la renta conforme a la tarifa acordada por el tiempo que tarda en entregar el vehículo en la
              fecha y hora determinados; si el retraso en la entrega del vehículo corresponde a la hora, el
              arrendatario sólo estará obligado a pagar la parte proporcional del incumplimiento. </p>
            <p class="text-justify">DÉCIMA. Prohibición al vehículo arrendado de salir de la república. Sin el previo
              consentimiento por escrito del arrendador, el vehículo arrendado no podrá salir de los límites de la
              República Mexicana: en caso de incumplimiento a lo anterior señalado, el arrendador podrá dar por
              rescindido este contrato, recuperando el vehículo en las condiciones y estados en que se localiza,
              siendo responsable la arrendataria del pago de la pena convencional correspondiente, más de los gastos
              de recuperación del vehículo debidamente comprobados por el arrendador. </p>
            <p class="text-justify">DÉCIMA PRIMERA. Derechos y obligaciones de las partes. Los contratantes se
              reconocen
              como derechos exigibles, el cumplimiento de todas las disposiciones del presente contrato, normando su
              consentimiento por la observancia de las siguientes obligaciones: En el cumplimiento del presente
              contrato el arrendador se obliga a: </p>
            <p class="text-justify">a. Entregar el vehículo arrendado en óptimas condiciones de uso, considerando el
              combustible necesario para tal efecto; el día, hora y lugar acordado por las partes. </p>
            <p class="text-justify">b. Recibir el vehículo sin ninguna condicionante de pago por el límite de
              gasolina,
              en la inteligencia que el vehículo por ninguna circunstancia deberá ser entregado por el arrendatario
              con un nivel menor a un cuarto de tanque. </p>
            <p class="text-justify">c. Al recibir el vehículo arrendado, señalando al arrendatario de ser el caso que
              el
              vehículo lo recibe en su entera satisfacción; de lo contrario deberá manifestar en el acto de recepción
              los motivos de su proceder. </p>
            <p class="text-justify">d. Devolver al arrendatario en el tiempo estipulado para tal efecto la cantidad
              otorgada en depósito en garantía. </p>
            <p class="text-justify">Para los efectos de este contrato, son obligaciones del arrendatario: </p>
            <p class="text-justify">a. Pagar al arrendador la renta convenida del vehículo arrendado de manera
              puntual,
              sin requerimiento de pago y en las condiciones establecidas en el presente contrato.</p>
            <p class="text-justify">b. Conducir en todo momento el vehículo arrendado bajo el amparo de la licencia
              respectiva, otorgada por las autoridades competentes; respetando los Reglamentos y Leyes de Tránsito en
              el ámbito Federal, Local o Municipal. </p>
            <p class="text-justify">c. No manejar el vehículo en estado de ebriedad o bajo la influencia de
              drogas. </p>
            <p class="text-justify">d. No hacer uso del vehículo en forma lucrativa, ni subarrendar. </p>
            <p class="text-justify">e. No utilizar el vehículo arrendado para arrastrar remolques y no sobrecargarlo
              debiéndose usar conforme a su resistencia y capacidad normal. </p>
            <p class="text-justify">f. Conservar el vehículo en el estado que lo recibió exceptuando el desgaste
              normal
              de uso. </p>
            <p class="text-justify">g. No conducir en el interior del vehículo materias explosivas o inflamables,
              drogas
              o estupefacientes. </p>
            <p class="text-justify">h. Pagar el importe de las sanciones que le fueran impuestas por violación a los
              Reglamentos de Transito, aun después de concluida la vigencia del contrato, si la infracción se origino
              durante el tiempo en que estuvo el vehículo arrendado a disposición del arrendatario. </p>
            <p class="text-justify">i. No utilizar el vehículo de manera diferente a lo pactado. </p>
            <p class="text-justify">j. No subarrendar a terceros el vehículo objeto del presente contrato sin previo
              consentimiento del arrendador.</p>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button @click="generateReportPDF" class="btn btn-dark"><i class="fa fa-download me-2"></i> Descargar</button>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import "datatables.net";
import moment from "moment";
import html2pdf from "html2pdf.js";
import Contrato from "@/components/Contrato.vue";
import vue3html2pdf from "vue3-html2pdf";
import { mapGetters } from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";

const FECHAS = gql`
mutation Fechasvehiculos($fechaCirculacion:Date!,$fechaCredito:Date!, $fechaPoliza:Date!, $id:ID!) {
    fechasvehiculos(
        fechaCirculacion: $fechaCirculacion
        fechaCredito: $fechaCredito
        fechaPoliza: $fechaPoliza
        id: $id
    ) {
        success
        errors
    }
}
`;
const DESACTIVE = gql`
mutation DesactiveVehiculo($id:ID!) {
    desactiveVehiculo(id: $id) {
        success
    }
}`;
const BUSCAR = gql`
         query ContratosAbiertos($name:String!, $pagina:Int!, $elementos:Int!) {
    contratosAbiertos(name: $name, pagina:$pagina, elementos:$elementos) {
        id
        estado
        tipoCobertura
        seguroRc
        revisado
        tipoPago
        fecha
        precioHoraExtra
        numContrato
        fechaSalida
        fechaRegreso
        tarifaDiaria
        tarifaExtra
        dias
        tanque
        deuda
        depositoEfectivo
        checkin
        credito
        comentarios
        canal
        agente {
            id
            username
            firstName
            lastName
            email
        }
        cliente {
            id
            nombre
            apellidos
            direccion
            telefonoEmergencia
            telefono
            email
            edad
            licencia {
                numero
                vencimiento
                estado
            }
        }
        vehiculo {
            id
            placa
            noEcon
            color
            capacidadTanke
            version{
              name
              modelo{
                name
                marca{
                  name
                }
              }
            }
            categoria {
                id
                name
                tradicionalAlta
                totalAlta
                tradicionalBaja
                totalBaja
            }
        }
        sucursal {
            id
            name
        }
        conductor {
            id
            nombre
            apellidos
            edad
            licencia {
                id
                numero
                vencimiento
                permanente
                estado
            }
            cliente {
                id
                nombre
                apellidos
                telefono
                email
                edad
                isActive
                direccion
                postal
                telefonoEmergencia
            }
        }
        tarjeta {
            id
            numero
            vencimiento
            autorizacion
        }
    }
}
`;
const ACTIVE = gql`
mutation ActiveVehiculo($id:ID!) {
    activeVehiculo(id: $id) {
        success
    }
}
`;
const DELETE = gql`
       mutation DeleteVehiculo($id:ID!) {
          deleteVehiculo(id: $id) {
              success
    }
}
`;
const SEGURIDAD = gql`
mutation Login($email:String!
$password:String!) {
    login(email: $email, password: $password) {
        success
        token
        refreshToken
        error
        error
    }
}`;
const CANCELAR_CONTRATO = gql`
mutation CancelarContrato($id:Int!) {
    cancelarContrato(id: $id) {
        success
        error
    }
}`;
const REVISAR = gql`
mutation ContratoRevisado($id:Int!) {
    contratoRevisado(id: $id) {
        success
        errors
    }
}`;
const COBERTURAS_TOTAL = gql`
query CoberturasPerTarifaTotal {
    coberturasPerTarifaTotal(name: "") {
        id
        cobertura
        isActive
    }
}`;
const COBERTURAS_TRADICIONAL = gql`
query CoberturasPerTarifaTradicional {
    coberturasPerTarifaTradicional(name: "") {
        id
        cobertura
        isActive
    }
}`;
const RECOGER_AUTO = gql`
mutation RecogerAuto(
    $comentarios:String!
    $contrato:ID!
    $kilometraje:Int!
    $tanque:Int!
    $tipo_pago:String!
    $tarjeta:ID!
    $monto_total_dias:Decimal!
    $horas_extras:Int!
    $monto_total_horas:Decimal!
    $dias:Int!
    $servicio_extra:Boolean!,
    $fecha:DateTime!
) {
    recogerAuto(
    comentarios: $comentarios
    contrato: $contrato
    kilometraje:$kilometraje
    tanque:$tanque
    tipoPago:$tipo_pago
    montoTotalDias:$monto_total_dias
    tarjeta:$tarjeta
    horasExtras:$horas_extras
    montoTotalHoras:$monto_total_horas
    servicioExtra:$servicio_extra
    dias:$dias
    fecha:$fecha) {
        success
        error
    }
}`;
const SEARCH_CONTRATOS = gql`
query SearchContratosOpen(
    $name: String!
    $before:String!
    $after:String!
    $first:Int!
    ) {
    searchContractsOpen(
        name:$name
        before: $before
        after: $after
        first: $first
        estado_Icontains: "Abierto"
    ) {
        edges {
            cursor
            node {
               id
               estado
                tipoCobertura
                cortesia {
                    id
                    name
                    descuento
                }
                seguroRc
                revisado
                tipoPago
                fecha
                precioHoraExtra
                numContrato
                fechaSalida
                total
                fechaRegreso
                tarifaDiaria
                tarifaExtra
                dias
                tanque
                deuda
                depositoEfectivo
                checkin
                credito
                comentarios
                canal
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                }
                cliente {
                    id
                    nombre
                    apellidos
                    direccion
                    telefonoEmergencia
                    telefono
                    email
                    edad
                    licencia {
                        numero
                        vencimiento
                        estado
                    }
                }
                vehiculo {
                    id
                    placa
                    noEcon
                    color
                    capacidadTanke
                    version{
                      name
                      modelo{
                        name
                        marca{
                          name
                        }
                      }
                    }
                    categoria {
                        id
                        name
                        tradicionalAlta
                        totalAlta
                        tradicionalBaja
                        totalBaja
                    }
                }
                sucursal {
                    id
                    name
                    phone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                conductor {
                    id
                    nombre
                    apellidos
                    edad
                    licencia {
                        id
                        numero
                        vencimiento
                        permanente
                        estado
                    }
                    cliente {
                        id
                        nombre
                        apellidos
                        telefono
                        email
                        edad
                        isActive
                        direccion
                        postal
                        telefonoEmergencia
                    }
                }
                tarjeta {
                    id
                    numero
                    vencimiento
                    autorizacion
                }
            }
        }
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
    }
}`;
const CONTRATOS_PREV = gql`
query SearchContratosOpen(
    $name: String!
    $before: String!
    $first: Int!
) {
    searchContractsOpen(
        name:$name
        before: $before
        after:""
        first: $first
        estado_Icontains: "Abierto"
    ) {
        edges {
            cursor
            node {
                id
                estado
                tipoCobertura
                seguroRc
                cortesia {
                    id
                    name
                    descuento
                }
                revisado
                tipoPago
                fecha
                precioHoraExtra
                numContrato
                fechaSalida
                total
                fechaRegreso
                tarifaDiaria
                tarifaExtra
                dias
                tanque
                deuda
                depositoEfectivo
                checkin
                credito
                comentarios
                canal
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                }
                cliente {
                    id
                    nombre
                    apellidos
                    direccion
                    telefonoEmergencia
                    telefono
                    email
                    edad
                    licencia {
                        numero
                        vencimiento
                        estado
                    }
                }
                vehiculo {
                    id
                    placa
                    noEcon
                    color
                    capacidadTanke
                    version {
                        name
                        modelo {
                            name
                            marca {
                                name
                            }
                        }
                    }
                    categoria {
                        id
                        name
                        tradicionalAlta
                        totalAlta
                        tradicionalBaja
                        totalBaja
                    }
                }
                sucursal {
                    id
                    name
                }
                conductor {
                    id
                    nombre
                    apellidos
                    edad
                    licencia {
                        id
                        numero
                        vencimiento
                        permanente
                        estado
                    }
                    cliente {
                        id
                        nombre
                        apellidos
                        telefono
                        email
                        edad
                        isActive
                        direccion
                        postal
                        telefonoEmergencia
                    }
                }
                tarjeta {
                    id
                    numero
                    vencimiento
                    autorizacion
                }
            }
        }
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
    }
}`;
const CONTRATOS_NEXT = gql`
query SearchContratos(
    $name: String!
    $after:String!
    $first:Int!
    ) {
    searchContractsOpen(
        name:$name
        after: $after
        before:""
        first: $first
        estado_Icontains: "Abierto"
    ) {
        edges {
            cursor
            node {
               id
               estado
                tipoCobertura
                seguroRc
                revisado
                tipoPago
                fecha
                precioHoraExtra
                numContrato
                cortesia {
                    id
                    name
                    descuento
                }
                fechaSalida
                total
                fechaRegreso
                tarifaDiaria
                tarifaExtra
                dias
                tanque
                deuda
                depositoEfectivo
                checkin
                credito
                comentarios
                canal
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                }
                cliente {
                    id
                    nombre
                    apellidos
                    direccion
                    telefonoEmergencia
                    telefono
                    email
                    edad
                    licencia {
                        numero
                        vencimiento
                        estado
                    }
                }
                vehiculo {
                    id
                    placa
                    noEcon
                    color
                    capacidadTanke
                    version{
                      name
                      modelo{
                        name
                        marca{
                          name
                        }
                      }
                    }
                    categoria {
                        id
                        name
                        tradicionalAlta
                        totalAlta
                        tradicionalBaja
                        totalBaja
                    }
                }
                sucursal {
                    id
                    name
                }
                conductor {
                    id
                    nombre
                    apellidos
                    edad
                    licencia {
                        id
                        numero
                        vencimiento
                        permanente
                        estado
                    }
                    cliente {
                        id
                        nombre
                        apellidos
                        telefono
                        email
                        edad
                        isActive
                        direccion
                        postal
                        telefonoEmergencia
                    }
                }
                tarjeta {
                    id
                    numero
                    vencimiento
                    autorizacion
                }
            }
        }
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
    }
}`;
const SERVICIO_BY_CONTRACT = gql`
query ServicesByContract($id: ID!) {
    servicesByContract(id: $id) {
        id
        servicio {
            name
        }
    }
}`;
export default {
  name: "List_Contratos_Abiertos",
  components: { VueDatePicker, MaterialButton, Contrato, vue3html2pdf },
  data() {
    return {
      misearch: "",
      coberturasPerTarifaTotal: [],
      servicios_ex: [],
      coberturasPerTarifaTradicional: [],
      showModal: false,
      showFechas: false,
      showCar: false,
      error: false,
      fechaCirculacion: null,
      fechaCredito: null,
      fechaPoliza: null,
      estatus: "",
      carid: 0,
      fileShow: false,
      files: null,
      preview: null,
      imagen: null,
      car: null,
      ImageModal: false,
      ImageUpdateModal: false,
      seguridad: false,
      password: "",
      error_seguridad: false,
      item: null,
      exportar_pdf: false,
      estado: "Todos",
      Recogida_Modal: false,
      fecha_recogida: null,
      kilometraje: 0,
      combustible: 0,
      comentarios: "",
      contrato_id: 0,
      horas_extras: 0,
      tarifa_horas: 0,
      monto_total: 0,
      cerrar_contrato: true,
      monto_cortesia: 0,

      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      lastStartCursor: "",
      endCursor: "",
      lastEndCursor: "",
      cant: 10,
      items: []
    };
  },
  apollo: {
    coberturasPerTarifaTotal: {
      query: COBERTURAS_TOTAL,
      fetchPolicy: "cache-and-network"
    },
    coberturasPerTarifaTradicional: {
      query: COBERTURAS_TRADICIONAL,
      fetchPolicy: "cache-and-network"
    }
  },
  watch: {
    items() {
      this.$store.state.Cargando = false;
    },
    cant() {
      this.loadDatas(true);
    },
    misearch() {
      this.loadDatas(true);
    }
  },
  methods: {
    esHoy(item) {
      const fecha = new Date(item);
      const hoy = new Date();
      return fecha.getDate() === hoy.getDate() &&
        fecha.getMonth() === hoy.getMonth() &&
        fecha.getFullYear() === hoy.getFullYear();
    },
    isBase64(str) {
      return /[a-zA-Z]/.test(str);
    },
    DecodeId(id) {
      if (this.isBase64(id)) {
        return decodeURIComponent(escape(window.atob(id))).split(":")[1];
      } else {
        return id;
      }

    },
    Editar(item) {
      this.$store.state.contrato = item;
      this.$store.state.update_contrato = "abiertos";
      this.$router.push("/contratos/editar");
    },
    async openCar(item) {
      this.item = item;
      this.showCar = true;
      this.$apollo.query({
        query: SERVICIO_BY_CONTRACT,
        variables: {
          id: this.DecodeId(item.id)
        }
      }).then(response => {
        this.servicios_ex = response.data.servicesByContract;
      });
      document.body.classList.add("modal-open");
      this.$apollo.mutate({
        mutation: REVISAR,
        variables: {
          id: this.DecodeId(item.id)
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
    },
    closeCar() {
      this.showCar = false;
      document.body.classList.remove("modal-open");
    },
    Difechas(fecha1, fecha2) {
      return moment(fecha2).diff(moment(fecha1), "hours");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    closeSeguridad() {
      this.seguridad = false;
      this.password = "";
    },
    Comprobar() {
      if (this.password === "") {
        this.error = true;
      } else {
        this.$apollo.mutate({
          mutation: SEGURIDAD,
          variables: {
            email: this.$store.state.email,
            password: this.password
          }
        }).then(response => {
          if (response.data.login.success) {
            this.closeSeguridad();
            this.$router.push("/contratos/nuevo");
          } else {
            this.error_seguridad = true;
          }
        });
      }
    },
    openSeguridad() {
      this.seguridad = true;
      document.body.classList.add("modal-open");
    },
    async Cancelar_Contrato(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, cancelar contrato!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: CANCELAR_CONTRATO,
            variables: {
              id: this.DecodeId(id)
            }
          });
          if (data.cancelarContrato.success) {
            this.loadDatas(true);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "warning",
              title: "Contrato cancelado"
            });
          }
        }
      });
    },
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("dddd, D [de] MMMM [de] YYYY h:mm A");
    },
    formatearFechaCorta(fecha) {
      moment.locale("es");
      return moment(fecha).format("DD/MM/YYYY HH:mm");
    },
    numeroFormateado(numero) {
      const num = parseFloat(numero);
      return num.toLocaleString("en-US", { minimumFractionDigits: 2 });
    },
    generateReport(item) {
      this.item = item;
      if (item.cortesia) {
        this.monto_cortesia = parseFloat(item.total) * parseFloat(item.cortesia.descuento) / 100;
      }
      this.$store.state.Cargando = true;
      this.exportar_pdf = true;
      setTimeout(() => {
        const contentToExport = this.$refs.contrato_plantilla;
        const options = {
          margin: [5, 5, 5, 5],
          filename: "Contrato con " + item.cliente.nombre + " " + item.cliente.apellidos + ".pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "letter", orientation: "portrait" },
          pagebreak: { mode: "avoid-all", before: ".page-contract" }
        };
        html2pdf()
          .from(contentToExport)
          .set(options)
          .output("blob", "contrato.pdf")
          .then(dataUri => {
            const blob = new Blob([dataUri], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            window.open(url, "_blank");
          });
        this.closeExportarPDF();
      }, 50);

    },
    closeExportarPDF() {
      this.$store.state.Cargando = false;
      this.exportar_pdf = false;
    },
    generateReportPDF() {
      const contentToExport = this.$refs.contrato_plantilla;
      const options = {
        margin: [17, 5, 20, 5],
        filename: "Contrato con " + this.item.cliente.nombre + " " + this.item.cliente.apellidos + ".pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }
      };
      html2pdf()
        .from(contentToExport)
        .set(options)
        .output("print") // Cambia a "print" para imprimir directamente
        .then(() => {
          // Después de imprimir, puedes cerrar el modal o realizar otras acciones
          this.closeExportarPDF();
        })
        .catch(error => {
          console.error("Error al generar el PDF:", error);
        });
      this.closeExportarPDF();
    },
    obtenerFecha(item) {
      const fecha = new Date(item);

      // Obtener la fecha en formato día/mes/año
      const dia = fecha.getDate();
      const mes = fecha.getMonth() + 1;  // Sumamos 1, ya que en JavaScript los meses van de 0 a 11
      const anio = fecha.getFullYear();
      return `${dia}/${mes}/${anio}`;
    },
    obtenerHora(item) {
      const fecha = new Date(item);

      const hora = (`0${fecha.getHours()}`).slice(-2);  // Agregamos un cero al principio si es necesario
      const minuto = (`0${fecha.getMinutes()}`).slice(-2);  // Agregamos un cero al principio si es necesario
      return `${hora}:${minuto}`;
    },
    textoEnMayusculas(item) {
      if (item) {
        return item.toUpperCase();
      } else {
        return "";
      }
    },
    openRecogida(item) {
      if (item.estado === "Vencido") {
        this.horas_extras = moment(new Date()).diff(item.fechaRegreso, "hours");
        this.tarifa_horas = item.precioHoraExtra;
        this.monto_total = (parseFloat(this.horas_extras) * parseFloat(this.tarifa_horas)).toFixed(2);
      }
      this.contrato_id = item.id;
      this.Recogida_Modal = true;
      document.body.classList.add("modal-open");
    },
    closeRecogida() {
      this.loadDatas();
      this.Recogida_Modal = false;
      this.comentarios = "";
      this.fecha_recogida = null;
      this.kilometraje = 0;
      this.estatus = "";
      this.combustible = 0;
      this.contrato_id = 0;
      this.cerrar_contrato = true;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    guardarRecogida() {
      if (this.kilometraje === 0) {
        this.error = true;
        return false;
      }
      this.error = false;
      this.$apollo.mutate({
        mutation: RECOGER_AUTO,
        variables: {
          comentarios: this.comentarios,
          contrato: this.contrato_id,
          kilometraje: this.kilometraje,
          tanque: this.combustible,
          tipo_pago: "",
          monto_total_dias: 0,
          tarjeta: 0,
          horas_extras: 0,
          monto_total_horas: 0,
          dias: 0,
          servicio_extra: 0,
          fecha: new Date()
        }
      }).then(response => {
        if (response.data.recogerAuto.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Se ha realizado la recogida del vehículo correctamente"
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error en la recogida del vehículo"
          });
        }
        this.closeRecogida();
      });
    },
    imprimirContrato() {
      const contratoDiv = this.$refs.contrato_plantilla;
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(`
        <html>
          <head>
            <style>
              @media print {
                body {
                  margin: 0;
                }
              }
            </style>
          </head>
          <body>${contratoDiv.innerHTML}</body>
        </html>
      `);
      const contratoDivClone = contratoDiv.cloneNode(true);
      iframeDoc.body.appendChild(contratoDivClone);
      iframeDoc.close();
      iframe.focus();
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    },

    loadDatas(init = false) {
      this.$store.state.Cargando = true;

      let next = "";
      let before = "";
      if (init === false) {
        before = this.lastEndCursor;
        next = this.lastStartCursor;
      }
      this.$apollo.query({
        query: SEARCH_CONTRATOS,
        variables: {
          name: this.misearch,
          before: before,
          after: next,
          first: this.cant
        },
        fetchPolicy: "network-only"
      }).then(resp => {
        if (init === true) {
          this.hasNextPage = resp.data.searchContractsOpen.pageInfo.hasNextPage;
          this.hasPreviousPage = resp.data.searchContractsOpen.pageInfo.hasPreviousPage;
        }
        this.startCursor = resp.data.searchContractsOpen.pageInfo.startCursor;
        this.endCursor = resp.data.searchContractsOpen.pageInfo.endCursor;
        this.items = resp.data.searchContractsOpen.edges.map(value => {
          return value.node;
        });
      });
    },
    nextPage() {
      this.$store.state.Cargando = true;
      this.$apollo.query({
        query: CONTRATOS_NEXT,
        variables: {
          name: this.misearch,
          after: this.endCursor,
          first: this.cant
        }
      }).then(resp => {
        this.lastEndCursor = "";
        this.lastStartCursor = this.endCursor;
        this.hasNextPage = resp.data.searchContractsOpen.pageInfo.hasNextPage;
        this.hasPreviousPage = true;
        this.startCursor = resp.data.searchContractsOpen.pageInfo.startCursor;
        this.endCursor = resp.data.searchContractsOpen.pageInfo.endCursor;
        this.items = resp.data.searchContractsOpen.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        alert(err);
      }).finally(() => {
        this.$store.state.Cargando = false;
      });

    },
    prevPage() {
      this.$store.state.Cargando = true;

      this.$apollo.query({
        query: CONTRATOS_PREV,
        variables: {
          name: this.misearch,
          before: this.startCursor,
          first: this.cant
        }
      }).then(resp => {
        this.lastEndCursor = this.startCursor;
        this.lastStartCursor = "";
        this.hasNextPage = true;
        this.hasPreviousPage = resp.data.searchContractsOpen.pageInfo.hasPreviousPage;
        this.startCursor = resp.data.searchContractsOpen.pageInfo.startCursor;
        this.endCursor = resp.data.searchContractsOpen.pageInfo.endCursor;
        this.items = resp.data.searchContractsOpen.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.$store.state.Cargando = false;
      });

    }
  },
  created() {
    this.$store.state.Cargando = true;
    this.loadDatas(true);
  },
  computed: {
    ...mapGetters(["tienePermiso"])
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('@/assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}
.bold{
  font-size: 14px;
}
.es-hoy {
  font-weight: bold !important;
  background-color: #f3d540;
}

.input-icon:focus {
  padding-left: 38px;
}

.input-icon:hover {
  padding-left: 38px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  margin-top: 200px;
  border-radius: 15px;
  width: 80%;
}

.modal-content-contrato {
  background-color: white;
  padding: 20px;
  margin-top: 999999px;
  border-radius: 15px;
  width: 90%;
}

.modal-content1 {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 30%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.text-contrato p {
  font-size: 14px;
}

@media (max-width: 990px) {
  .modal-content {
    margin-top: 500px;
    width: 90%;
  }

  .modal-content1 {
    width: 90%;
  }
}

@media (max-width: 766px) {
  .modal-content {
    margin-top: 1200px;
    width: 90%;
  }

  .modal-content1 {
    width: 90%;
  }
}

@media (max-width: 550px) {
  .modal-content {
    background-color: white;
    padding: 20px;
    margin-top: 1250px;
    border-radius: 15px;
    width: 90%;
  }

  .modal-content1 {
    width: 90%;
  }
}

.text-orange {
  color: #fdbe1e;
}
</style>
